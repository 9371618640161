import { Drawer, Heading as PomodoroHeading, TextLink } from '@whitelabel-webapp/shared/design-system';
import styled from 'styled-components';

export const Heading = styled(PomodoroHeading)`
  margin-top: ${({ theme }) => theme.space.regular};
  margin-bottom: ${({ theme }) => theme.space.larger};

  font-size: ${({ theme }) => theme.fontSizes.m};
`;

export const Body = styled(Drawer.Body)`
  padding: 0 ${({ theme }) => theme.space.regular};

  font-size: ${({ theme }) => theme.fontSizes.tsm};
`;

export const Close = styled(TextLink)`
  position: absolute;
  top: ${({ theme }) => theme.space.large};
  right: ${({ theme }) => theme.space.regular};

  font-size: ${({ theme }) => theme.fontSizes.xs};
`;
