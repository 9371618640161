import { Flex, Heading, Text } from '@whitelabel-webapp/shared/design-system';
import styled from 'styled-components';

export const Wrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Title = styled(Heading)`
  font-size: ${({ theme }) => theme.fontSizes.m};
  margin: 0;
`;

export const Description = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes.tsm};
  line-height: normal;
`;
