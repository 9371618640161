/* eslint-disable max-lines */

import { useAddress } from "@whitelabel-webapp/address/shared/address-store";
import { CustomerAddress } from "@whitelabel-webapp/address/shared/models";
import { useAuthentication } from "@whitelabel-webapp/authentication/shared/authentication-store";
import { useCatalog } from "@whitelabel-webapp/catalog/shared/catalog-store";
import { Item as CatalogItem } from "@whitelabel-webapp/catalog/shared/models";
import { checkoutAboyeur } from "@whitelabel-webapp/checkout/shared/config";
import {
  CardTokenResponse,
  Choice,
  Item,
  Order,
  PaymentMethod,
} from "@whitelabel-webapp/checkout/shared/models";
import { useMerchantAvailability } from "@whitelabel-webapp/merchant/shared/hooks";
import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";
import { DeliveryMethod } from "@whitelabel-webapp/merchant/shared/models";
import { snackbar } from "@whitelabel-webapp/shared/design-system";
import { ecommerceEvents } from "@whitelabel-webapp/shared/ecommerce-events";
import { useAsyncState } from "@whitelabel-webapp/shared/hooks";
import { useNavigationWithQuery } from "@whitelabel-webapp/shared/navigation-utils";
import { EmptyProps } from "@whitelabel-webapp/shared/types";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { CachedItem, OnlinePaymentsEnabled } from "../types";
import {
  usePersistedCampaignCode,
  usePersistedDeliveryAdditionalInfo,
  usePersistedItems,
} from "./hooks";

const CheckoutDetails = dynamic<EmptyProps>(() =>
  import("../views").then(({ CheckoutDetails }) => CheckoutDetails),
);
const MerchantClosed = dynamic<EmptyProps>(() =>
  import("../views").then(({ MerchantClosed }) => MerchantClosed),
);
const NoAddress = dynamic<EmptyProps>(() =>
  import("../views").then(({ NoAddress }) => NoAddress),
);
const NoDeliveryMethod = dynamic<EmptyProps>(() =>
  import("../views").then(({ NoDeliveryMethod }) => NoDeliveryMethod),
);
const TakeoutAddress = dynamic<EmptyProps>(() =>
  import("../views").then(({ TakeoutAddress }) => TakeoutAddress),
);

type CheckoutDetailsStatus = "IDLE" | "OPEN" | "OPEN_WITH_ONLINE_PAYMENT_CARDS";
type SetOrder = Order | ((prevOrder: Order) => Order);

export type CheckoutContextValue = {
  order: Order;
  checkoutDetailsStatus: CheckoutDetailsStatus;
  onlinePaymentsEnabled: OnlinePaymentsEnabled;
  addItem: (
    catalogItem: CatalogItem,
    quantity: number,
    choices: Choice[],
    observation: string,
  ) => Promise<boolean>;
  removeItem: (catalogItem: CatalogItem) => boolean;
  setDocument: (document?: string) => void;
  setCampaignCode: (code: string) => void;
  setPaymentMethod: (paymentMethod: PaymentMethod) => void;
  setCardToken: (cardToken: CardTokenResponse, payment: PaymentMethod) => void;
  setDeliveryAdditionalInfo: (deliveryAdditionalInfo: string) => void;
  updateOrder: (setOrder: SetOrder) => void;
  removeAllItems: () => void;
  updateOrderOnItemChangePrice: (catalogItems: CatalogItem[]) => void;
  setCheckoutDetailsStatus: (status: CheckoutDetailsStatus) => void;
};

export const CheckoutContext = createContext<CheckoutContextValue | undefined>(
  undefined,
);

export type CheckoutProviderProps = {
  initialCustomerAddress?: CustomerAddress;
  initialDeliveryMethod?: DeliveryMethod;
  initialCheckoutDetailsStatus?: CheckoutDetailsStatus;
  onlinePaymentsEnabled?: OnlinePaymentsEnabled;
};

const initialOnlinePaymentsEnabled: OnlinePaymentsEnabled = {
  pix: false,
  cards: false,
};

export const CheckoutProvider: React.FC<CheckoutProviderProps> = ({
  children,
  initialCustomerAddress,
  initialDeliveryMethod,
  initialCheckoutDetailsStatus = "IDLE",
  onlinePaymentsEnabled = initialOnlinePaymentsEnabled,
}) => {
  const { merchant } = useMerchant();
  const { customer } = useAuthentication();

  const {
    deliveryMethod: persistedDeliveryMethod,
    setDeliveryMethod: setPersistedDeliveryMethod,
    getAddress,
    setAddress: setPersistedAddress,
  } = useAddress();
  const navigate = useNavigationWithQuery();
  const router = useRouter();

  const persistedAddress = useMemo(() => getAddress("MERCHANT"), [getAddress]);

  const [persistedItems, setPersistedItems] = usePersistedItems();
  const [persistedCampaignCode, setPersistedCampaignCode] =
    usePersistedCampaignCode();
  const [persistedDeliveryAdditionalInfo, setPersistedDeliveryAdditionalInfo] =
    usePersistedDeliveryAdditionalInfo();

  const [getCachedItem, setCachedItem] = useAsyncState<CachedItem>(undefined);

  const [checkout, setCheckout] = useState<CheckoutContextValue>(() => {
    return {
      order: new Order(
        merchant,
        persistedItems,
        initialCustomerAddress ?? persistedAddress,
        initialDeliveryMethod ?? persistedDeliveryMethod,
        persistedCampaignCode,
        customer,
        persistedDeliveryAdditionalInfo,
      ),
      checkoutDetailsStatus: initialCheckoutDetailsStatus,
      addItem: handleAddItem,
      removeItem: handleRemoveItem,
      setDocument: handleSetDocument,
      setPaymentMethod: handleSetPaymentMethod,
      setCardToken: handleSetCardToken,
      updateOrder: onUpdateOrder,
      removeAllItems: handleRemoveAllItems,
      setCampaignCode: handleSetCampaignCode,
      onlinePaymentsEnabled,
      updateOrderOnItemChangePrice: updateOrderOnItemChangePrice,
      setCheckoutDetailsStatus: handleCheckoutDetailsStatus,
      setDeliveryAdditionalInfo: handleSetDeliveryAdditionalInfo,
    };
  });

  const [isChooseReceivingMethodOpen, setIsChooseReceivingMethodOpen] =
    useState(false);
  const [isMerchantClosedOpen, setIsMerchantClosedOpen] = useState(false);
  const [isNoAddressOpen, setIsNoAddressOpen] = useState(false);
  const [isTakeoutAddressOpen, setIsTakeoutAddressOpen] = useState(false);

  const { checkAvailability } = useMerchantAvailability();

  const onClose = useCallback(() => {
    setCheckout({ ...checkout, checkoutDetailsStatus: "IDLE" });
    setTimeout(() => navigate(), 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkout]);

  useEffect(() => {
    const { checkoutStatus } = router.query;

    if (checkoutStatus) {
      setCheckout({
        ...checkout,
        checkoutDetailsStatus: checkoutStatus as CheckoutDetailsStatus,
      });
    }
  }, []);

  useEffect(() => {
    if (!persistedDeliveryMethod) return;

    async function onUpdateDeliveryMethod() {
      const newOrder = checkout.order.withDeliveryMethod(
        persistedDeliveryMethod,
      );

      onUpdateOrder(newOrder);
    }

    onUpdateDeliveryMethod();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persistedDeliveryMethod]);

  useEffect(() => {
    if (!persistedAddress) return;

    async function onUpdateAddressAndDeliveryMethod() {
      const newOrder = checkout.order.withAddress(persistedAddress);

      if (!checkout.order.customer) return onUpdateOrder(newOrder);

      await newOrder.updateOrCreateCustomerAddress();
      onUpdateOrder(newOrder);
    }

    onUpdateAddressAndDeliveryMethod();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persistedAddress]);

  useEffect(() => {
    if (!customer) {
      return;
    }

    async function onUpdateCustomerOrAddress() {
      const newOrder = await checkout.order
        .withCustomer(customer)
        .updateOrCreateCustomerAddress();

      onUpdateOrder(newOrder);
    }

    onUpdateCustomerOrAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  useEffect(() => {
    if (merchant.hasIndoorStoreType()) {
      return;
    }

    if (!(persistedAddress && !persistedDeliveryMethod)) {
      return;
    }

    async function onInsertAutomaticallyDeliveryMethod() {
      const deliveryMethodResponse = await merchant.getDeliveryMethod(
        persistedAddress.coordinates.latitude,
        persistedAddress.coordinates.longitude,
      );

      if (!deliveryMethodResponse) return;

      const deliveryMethod = DeliveryMethod.fromApi(deliveryMethodResponse);
      setPersistedDeliveryMethod(deliveryMethod);

      const newOrder = checkout.order
        .withAddress(persistedAddress)
        .withDeliveryMethod(deliveryMethod);

      onUpdateOrder(newOrder);
    }

    onInsertAutomaticallyDeliveryMethod();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchant, persistedAddress, persistedDeliveryMethod]);

  function handleCheckoutDetailsStatus(status: CheckoutDetailsStatus) {
    setCheckout({ ...checkout, checkoutDetailsStatus: status });
    setTimeout(
      () =>
        navigate({
          checkoutStatus: status,
        }),
      300,
    );
  }

  useEffect(() => {
    if (!merchant.hasIndoorStoreType()) {
      return;
    }

    if (persistedAddress && persistedDeliveryMethod) {
      return;
    }

    async function onInsertAutomaticallyDeliveryMethod() {
      const address = CustomerAddress.fromMerchant(merchant);
      setPersistedAddress("MERCHANT", address);

      const newOrder = checkout.order.withAddress(address);

      const deliveryMethodResponse = await merchant.getDeliveryMethod(
        address.coordinates.latitude,
        address.coordinates.longitude,
      );

      if (!deliveryMethodResponse) return;

      const deliveryMethod = DeliveryMethod.fromApi(deliveryMethodResponse);
      setPersistedDeliveryMethod(deliveryMethod);

      newOrder.withDeliveryMethod(deliveryMethod);

      if (!checkout.order.customer) return onUpdateOrder(newOrder);

      await newOrder.updateOrCreateCustomerAddress();
      onUpdateOrder(newOrder);
    }

    onInsertAutomaticallyDeliveryMethod();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchant]);

  function onUpdateOrder(setOrder: SetOrder) {
    setCheckout(({ order: prevOrder, ...prevCheckout }) => {
      const newOrder =
        typeof setOrder === "function" ? setOrder(prevOrder) : setOrder;

      setPersistedItems(newOrder.itemsList);
      setPersistedCampaignCode(newOrder.campaignCode);

      return {
        ...prevCheckout,
        order: newOrder,
      };
    });
  }

  async function handleAddItem(
    catalogItem: CatalogItem,
    quantity: number,
    choices: Choice[],
    observation: string,
  ) {
    setCachedItem({ catalogItem, quantity, choices, observation });

    if (!merchant.hasSchedulingFeature()) {
      const available = await checkAvailability();

      if (available === undefined) {
        checkoutAboyeur.events.details.noNetwork();
        return false;
      }

      if (!available) {
        checkoutAboyeur.events.details.storeUnavailable();
        setIsMerchantClosedOpen(true);
        return false;
      }
    }

    if (
      merchant.hasTakeoutFeature() &&
      merchant.hasDeliveryFeature() &&
      !checkout.order.deliveryMethod
    ) {
      checkoutAboyeur.events.details.noDeliveryMethod();
      setIsChooseReceivingMethodOpen(true);
      return false;
    }

    if (merchant.hasDeliveryFeature() && !checkout.order.deliveryMethod) {
      checkoutAboyeur.events.details.noAddress();
      setIsNoAddressOpen(true);
      return false;
    }

    if (
      merchant.hasTakeoutFeature() &&
      !merchant.hasDeliveryFeature() &&
      !checkout.order.deliveryMethod
    ) {
      await handleTakeout();
      setIsTakeoutAddressOpen(true);
    }

    addItemToOrder(catalogItem, quantity, choices, observation);

    return true;
  }

  function addItemToOrder(catalogItem, quantity, choices, observation) {
    const isAlreadyInOrder = checkout.order.itemsList.some((element) => {
      return (
        element.catalogItem.productInfo == catalogItem.productInfo &&
        element.catalogItem.id === catalogItem.id &&
        element.quantity === quantity &&
        element.observation == observation &&
        element.choices.length === 0
      );
    });

    if (isAlreadyInOrder) return;

    const item = Item.fromCatalogItem(
      catalogItem,
      quantity,
      choices,
      observation,
    );

    checkoutAboyeur.events.details.addItem(
      catalogItem.description,
      catalogItem.id,
    );
    ecommerceEvents.addToCart(item.catalogItem);

    onUpdateOrder((prevOrder) => prevOrder.withItem(item));
    setCachedItem(undefined);
  }

  function handleRemoveItem(catalogItem: CatalogItem) {
    const item = Item.fromCatalogItem(catalogItem, 0, []);

    checkoutAboyeur.events.details.removeItem(
      catalogItem.description,
      catalogItem.id,
    );
    ecommerceEvents.removeFromCart(item.catalogItem);

    onUpdateOrder((prevOrder) => {
      const newOrder = prevOrder.withItem(item);

      if (!newOrder.hasItems()) {
        onClose();
      }

      return newOrder;
    });

    return true;
  }

  function handleSetDocument(document?: string) {
    onUpdateOrder((prevOrder) => prevOrder.withDocument(document));
    snackbar({
      variant: "success",
      message: "CPF/CNPJ aplicado",
    });
  }

  function handleSetCampaignCode(code: string) {
    setPersistedCampaignCode(code);

    onUpdateOrder((prevOrder) => prevOrder.withCampaignCode(code));
  }

  function handleRemoveAllItems() {
    onUpdateOrder((prevOrder) => {
      const newOrder = prevOrder.removeAllItems();

      onClose();

      snackbar({
        variant: "success",
        message: "Sua sacola está vazia",
      });

      checkoutAboyeur.events.details.clear();

      return newOrder;
    });
  }

  function handleSetPaymentMethod(paymentMethod?: PaymentMethod) {
    if (!paymentMethod) {
      onUpdateOrder((prevOrder) => prevOrder.withPaymentMethod());
      return;
    }

    checkoutAboyeur.events.payment.selectMethod(
      paymentMethod.name,
      paymentMethod.type.name,
    );
    ecommerceEvents.checkoutStep(1, paymentMethod.name);

    snackbar({
      variant: "success",
      message: "Forma de pagamento escolhida",
    });

    onUpdateOrder((prevOrder) =>
      prevOrder.withCardToken().withPaymentMethod(paymentMethod),
    );
  }

  function handleSetDeliveryAdditionalInfo(deliveryAdditionalInfo: string) {
    checkoutAboyeur.events.deliveryAdditionalInfo.add();

    setPersistedDeliveryAdditionalInfo(deliveryAdditionalInfo);

    onUpdateOrder((prevOrder) =>
      prevOrder.withDeliveryAdditionalInfo(deliveryAdditionalInfo),
    );
  }

  function handleSetCardToken(
    cardToken: CardTokenResponse,
    payment: PaymentMethod,
  ) {
    checkoutAboyeur.events.payment.selectMethod(
      payment.name,
      payment.type.name,
    );

    onUpdateOrder((prevOrder) =>
      prevOrder.withCardToken(cardToken).withPaymentMethod(payment),
    );
  }

  function updateOrderOnItemChangePrice(catalogItems: CatalogItem[]) {
    catalogItems.forEach((catalogItem) => {
      const itemInOrder = checkout.order.itemsList.find(
        (item) => item.catalogItem.code === catalogItem.code
      );

      if (!itemInOrder) return;

      const { quantity, choices, observation } = itemInOrder;
      const item = Item.fromCatalogItem(catalogItem, quantity, choices, observation);

      onUpdateOrder((prevOrder) => {
        const orderWithoutItem = prevOrder.withoutItem(itemInOrder);
        const updatedOrder = orderWithoutItem.withItem(item);
        return updatedOrder;
      });
    });
  }

  async function addCachedItemToOrder() {
    const item = await getCachedItem();

    if (!item) return;
    const { catalogItem, quantity, choices, observation } = item;

    addItemToOrder(catalogItem, quantity, choices, observation);
  }

  async function handleTakeout() {
    try {
      const takeoutMethodResponse = await merchant.getTakeoutMethod();

      const takeoutMethod = DeliveryMethod.fromApi(takeoutMethodResponse);

      setPersistedDeliveryMethod(takeoutMethod);
    } catch (error: any) {
      checkoutAboyeur.events.catch.onError(error as Error);
    }
  }

  useEffect(() => {
    if (checkout.order.deliveryMethod) {
      addCachedItemToOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkout.order.deliveryMethod]);

  return (
    <CheckoutContext.Provider value={checkout}>
      {children}
      <CheckoutDetails
        open={checkout.checkoutDetailsStatus !== "IDLE"}
        onClose={onClose}
      />
      <NoDeliveryMethod
        open={isChooseReceivingMethodOpen}
        onClose={() => setIsChooseReceivingMethodOpen(false)}
      />
      <MerchantClosed
        open={isMerchantClosedOpen}
        onClose={() => setIsMerchantClosedOpen(false)}
      />
      <NoAddress
        open={isNoAddressOpen}
        onClose={() => setIsNoAddressOpen(false)}
      />
      <TakeoutAddress
        open={isTakeoutAddressOpen}
        onClose={() => setIsTakeoutAddressOpen(false)}
      />
    </CheckoutContext.Provider>
  );
};

CheckoutProvider.displayName = "CheckoutProvider";
