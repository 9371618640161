import { Flex } from "@whitelabel-webapp/shared/design-system";
import styled from "styled-components";

export const Wrapper = styled(Flex)`
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;

  margin: ${({ theme }) => theme.space.small} 0;
  padding: ${({ theme }) => theme.space.large};

  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grayLight};
  border-radius: 12px;
`;

export const StarWrapper = styled(Flex)`
  width: 100%;
`;
