import { format } from 'date-fns';

export function formatDateToHourMinutesString(date: Date): string {
  return format(date, 'HH:mm');
}

export function formatDateToString(date: Date): string {
  return format(date, 'dd/MM/yyyy');
}

export function formatDateToDayHourMinute(date: Date): string {
  return format(date, "HH:mm - dd/MM/yyyy");
}
