import { Flex, LinkText as PomodoroLinkText, Text as PomodoroText } from '@whitelabel-webapp/shared/design-system';
import styled, { DefaultTheme } from "styled-components";

const bellowDesktop = ({ theme }: { theme: DefaultTheme }) =>
  `max-width: ${theme.screens.l.min}`;

export const Wrapper = styled(Flex)`
  flex-direction: "row";

  align-items: center;
  justify-content: center;

  width: 100%;
  background-color: ${({ theme }) => theme.colors.positive};
  gap: ${({ theme }) => theme.space.smaller};
  padding: ${({ theme }) => theme.space.smallest};
  box-sizing: border-box;
  cursor: pointer;

  @media (${bellowDesktop}) {
    display: none;
  }
`;

export const Text = styled(PomodoroText)`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.tsm};
  font-weight: 500;
`;

export const Circle = styled.div`
  width: ${({ theme }) => theme.space.smallest};
  height: ${({ theme }) => theme.space.smallest};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
`;

export const LinkText = styled(PomodoroLinkText)`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.tsm};
`;

export const Container = styled(Flex)`
  align-items: center;
  gap: ${({ theme }) => theme.space.smallest};
  font-size: ${({ theme }) => theme.fontSizes.tsm};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.tsm};
`;
