import { Drawer } from "@whitelabel-webapp/shared/design-system";
import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

export type DrawerProps = {
  expand?: boolean;
};

export const CustomDrawer = styled(Drawer)<DrawerProps>`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  position: fixed;
  width: 100%;
  transition: max-width 300ms;
  max-width: ${({ expand }) => expand && "100dvw !important"};
  background-color: ${({ expand, theme }) =>
    expand ? theme.colors.grayLight : theme.colors.white} !important;
  opacity: 1 !important;
`;

export const CustomDrawerMobile = styled(CustomDrawer)`
  top: 50%;
  right: -50%;
  transform: translate(-50%, -50%) !important;
`;

export const DrawerBody = styled(Drawer.Body)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
