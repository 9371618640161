import { Flex } from '@whitelabel-webapp/shared/design-system';
import { Text } from '@whitelabel-webapp/shared/design-system';
import React, { useEffect, useState } from 'react';

import * as S from './styles';

interface Props {
  onChange: (value: string[]) => void;
  title: string;
  options: {
    label: string;
    value: string;
  }[];
}

const Options: React.VFC<Props> = ({ onChange, options, title }) => {
  const [selected, setSelected] = useState<string[]>([]);

  const handleChange = (value: string) => {
    if (selected.includes(value)) {
      setSelected((previousSelected) => previousSelected.filter((item) => item !== value));
      return;
    }

    setSelected((previousSelected) => [...previousSelected, value]);
  };

  useEffect(() => {
    onChange(selected);
  }, [selected, onChange]);

  return (
    <Flex mt="regular" width="90%" flexDirection="column">
      <Text variant="medium" color="primary" textAlign="left">
        {title}
      </Text>
      <Flex mt="regular" flexWrap="wrap">
        {options.map((option) => (
          <S.Tag key={option.value} onClick={() => handleChange(option.value)} active={selected.includes(option.value)}>
            {option.label}
          </S.Tag>
        ))}
      </Flex>
    </Flex>
  );
};

export default Options;
