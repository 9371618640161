/* eslint-disable max-lines */
import { Item } from "@whitelabel-webapp/catalog/shared/models";
import { GarnishItem } from "@whitelabel-webapp/checkout/shared/garnish-item";
import {
  Item as CheckoutItem,
  Choice,
} from "@whitelabel-webapp/checkout/shared/models";
import { Flex, QuickAdd } from "@whitelabel-webapp/shared/design-system";
import { useListOfRefs } from "@whitelabel-webapp/shared/hooks";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as yup from "yup";

import { useCheckout } from "@app/domains/checkout";
import * as S from "./styles";

import { catalogAboyeur } from "@app/domains/catalog/aboyeur";
import { ItemChoice } from "./Choice";

const DEFAULT_QUANTITY = 0;

type ItemChoicesProps = {
  item: Item;
};

export const ItemChoices: React.VFC<ItemChoicesProps> = ({ item }) => {
  const { order, addItem, removeItem } = useCheckout();

  useEffect(() => {
    if (!item) return;
    return function unmount() {
      setUnsuccessfullyAttemptAddItem(false);
      setHasRequiredChoiceErrorShown(false);
    };
  }, [item]);

  const [quantity, setQuantity] = useState(
    () => getCurrentItem(order.items, item)?.quantity ?? DEFAULT_QUANTITY,
  );
  const [observation, setObservation] = useState(
    () => getCurrentItem(order.items, item)?.observation ?? "",
  );
  const [initialValues, setInitialValues] = useState(() =>
    createFormikInitialValues(item, getCurrentItem(order.items, item)),
  );
  const [validationSchema, setValidationSchema] = useState(() =>
    createYupValidationSchema(item),
  );
  const [hasUnsuccessfullyAttemptAddItem, setUnsuccessfullyAttemptAddItem] =
    useState(false);
  const [hasRequiredChoiceErrorShown, setHasRequiredChoiceErrorShown] =
    useState(false);

  const [choiceRefs, addToChoiceRefs] = useListOfRefs();

  useEffect(() => {
    item.instanceId = getIntanceId(order.itemsList, item.id);
    const currentItem = getCurrentItem(order.items, item);
    setQuantity(currentItem?.quantity ?? DEFAULT_QUANTITY);
    setObservation(currentItem?.observation ?? "");
    setInitialValues(createFormikInitialValues(item, currentItem));
    setValidationSchema(createYupValidationSchema(item));
  }, [order.items, item]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: () => {
      return;
    },
  });

  async function handleAddItem(newQuantity?: number) {
    if (isCartButtonDisabled) {
      handleDisabledAddButton();
      return;
    }

    if (newQuantity == 0) {
      removeItem(item);
      return;
    } else {
      setQuantity(newQuantity);
    }

    await addItem(
      item,
      newQuantity,
      item.choices.map((choice) =>
        Choice.fromCatalog(choice, formik.values[choice.code]),
      ),
      observation,
    );
  }

  function handleOnCompleteGarnishItem(index: number) {
    setHasRequiredChoiceErrorShown(false);
  }

  const isCartButtonDisabled = !formik.isValid;

  function handleDisabledAddButton() {
    if (!isCartButtonDisabled) return;

    catalogAboyeur.events.item.disabledAddItemButtonClick();
    setUnsuccessfullyAttemptAddItem(true);
    catalogAboyeur.events.item.viewEmptyGarnishItemAlert();

    if (hasRequiredChoiceErrorShown) return;

    setHasRequiredChoiceErrorShown(true);
  }

  return (
    <Flex flexDirection="column" gap="regular">
      <Flex flexDirection="column">
        {item.hasGarnishItems() &&
          item.choices
            .filter((choice) => choice.isVisible())
            .map((choice, index) => (
              <ItemChoice
                key={choice.code}
                sendRef={addToChoiceRefs(index)}
                choice={choice}
                fieldValue={formik.values[choice.code] ?? []}
                setFieldValue={formik.setFieldValue}
                onComplete={() => handleOnCompleteGarnishItem(index)}
                hasUnsuccessfullyAttemptAddItem={
                  hasUnsuccessfullyAttemptAddItem
                }
              />
            ))}
      </Flex>
      <S.AddItemContainer>
        <QuickAdd
          description={item.description}
          productInfo={item.productInfo}
          onChange={handleAddItem}
          disabled={isCartButtonDisabled}
          startValue={quantity}
        ></QuickAdd>
      </S.AddItemContainer>
    </Flex>
  );
};

function getCurrentItem(
  itemsRecord: Record<string, CheckoutItem>,
  item?: Item,
) {
  return item && item.instanceId ? itemsRecord[item.instanceId] : undefined;
}

function getIntanceId(itemsList: CheckoutItem[], itemId: string) {
  return itemsList.find((item) => item.catalogItem.id == itemId)?.instanceId;
}

function createFormikInitialValues(item?: Item, checkoutItem?: CheckoutItem) {
  if (!item || !item.hasGarnishItems()) {
    return {};
  }

  if (checkoutItem !== undefined) {
    return checkoutItem.choices.reduce<Record<string, Array<GarnishItem>>>(
      (obj, choice) => {
        obj[choice.code] = choice.items;
        return obj;
      },
      {},
    );
  }

  return item.choices.reduce<Record<string, Array<GarnishItem>>>(
    (obj, choice) => {
      obj[choice.code] = [];
      return obj;
    },
    {},
  );
}

function createYupValidationSchema(item?: Item) {
  if (!item || !item.hasGarnishItems()) {
    return yup.object().shape({});
  }

  const validationShape = item.choices.reduce<Record<string, any>>(
    (obj, choice) => {
      obj[choice.code] = choice.isMandatory()
        ? yup
            .array()
            .required()
            .test("mandatory_choice", (value) => {
              if (
                value === undefined ||
                value.reduce(
                  (acc, garnishItem) => acc + garnishItem.quantity,
                  0,
                ) < choice.min
              ) {
                return false;
              }
              return true;
            })
        : yup.array();
      return obj;
    },
    {},
  );

  return yup.object().shape(validationShape);
}
