import { checkoutAboyeur } from "@whitelabel-webapp/checkout/shared/config";
import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";
import { Button, Text } from "@whitelabel-webapp/shared/design-system";
import { useEffect } from "react";

import { DialogContent } from "../../Content";
import { DialogTitle } from "../../DialogTitle";
import * as S from "./styles";

type EmptyProps = {
  onTryAgain: () => void;
};

export const ErrorPaymentMethods: React.VFC<EmptyProps> = ({ onTryAgain }) => {
  const {
    merchant: { id },
  } = useMerchant();

  useEffect(() => {
    checkoutAboyeur.events.payment.errorPaymentMethodsView(id);
  }, [id]);

  return (
    <>
      <DialogTitle>Formas de pagamento indisponíveis</DialogTitle>
      <DialogContent>
        <S.Container>
          <Text textAlign="center" mt="large" mb="regular">
            Não foi possível exibir as formas de pagamento disponíveis. Por
            favor, tente novamente.
          </Text>
          <Button variant="tertiary" mt="smaller" onClick={() => onTryAgain()}>
            Tentar novamente
          </Button>
        </S.Container>
      </DialogContent>
    </>
  );
};
