import { ChevronRight } from '@ifood/pomodoro-icons';

import * as S from './styles';

type FAQListItemProps = {
  onClick: () => void;
  text: string;
};

export function FAQListItem({ onClick, text }: FAQListItemProps) {
  return (
    <S.ListItem onClick={() => onClick()}>
      {text} <ChevronRight />
    </S.ListItem>
  );
}
