import { useMerchant } from '@whitelabel-webapp/merchant/shared/merchant-store';
import { Copy, Drawer, Flex, Text, TextLink, snackbar } from '@whitelabel-webapp/shared/design-system';
import { useCopyToClipboard, useCountdown, useIsMobile } from '@whitelabel-webapp/shared/hooks';
import NextImage from 'next/image';
import QRCode from 'qrcode.react';
import React, { useEffect, useMemo, useState } from 'react';

import { Order } from '@app/domains/order';
import { orderAboyeur } from '@app/domains/order/aboyeur';

import { PixPaymentHelp } from '../PixPaymentHelp';
import * as S from './styles';

interface PixPaymentDrawerProps {
  order: Order | undefined;
  onClose: () => void;
  openFAQDrawer: () => void;
}

const calcRemainingTimeInSeconds = (createdAt: Date) => {
  const expirationDate = new Date(createdAt.setMinutes(createdAt.getMinutes() + 5));

  const endDate = expirationDate.getTime();
  const now = new Date().getTime();

  const remainingTime = Math.floor((endDate - now) / 1000);

  if (remainingTime < 0) {
    return 0;
  }

  return remainingTime;
};

const DEFAULT_TTL_SECONDS = 300;

export const PixPaymentDrawer: React.FC<PixPaymentDrawerProps> = ({ order, onClose, openFAQDrawer }) => {
  const [isOpenHelpDrawer, setIsOpenHelpDrawer] = useState(false);
  const { pixQrCode = '' } = order?.paymentMethod || {};
  const { merchant } = useMerchant();

  const logo = merchant.getLogoUrl();

  const isMobile = useIsMobile();

  const remainingInSeconds = useMemo(
    () => calcRemainingTimeInSeconds(order.createdAt),
    [order.createdAt],
  );
  const [isOpen, setIsOpen] = useState(true);

  const { countdownSeconds } = useCountdown(remainingInSeconds);
  const { browserSupports, status, copy } = useCopyToClipboard(pixQrCode);

  const handleCopy = () => {
    orderAboyeur.events.pix.copy();
    copy();
  };

  const remainingTime = {
    minutes: Math.floor(countdownSeconds / 60),
    seconds: (countdownSeconds % 60).toString().padStart(2, "0"),
    percentage: (countdownSeconds / DEFAULT_TTL_SECONDS) * 100,
    isExpired: countdownSeconds === 0,
  };

  function openHelpDrawer() {
    orderAboyeur.events.pix.openHelpModal();
    setIsOpenHelpDrawer(true);
  }

  function closeHelpDrawer() {
    orderAboyeur.events.pix.closeHelpModal();
    setIsOpenHelpDrawer(false);
  }

  function handleClose() {
    setIsOpen(false);
    onClose();
  }

  useEffect(() => {
    if (!remainingTime.isExpired) return;

    snackbar({
      variant: "warning",
      message: "Tempo de pagamento acabou. Faça o pedido novamente.",
    });
  }, [remainingTime.isExpired]);

  useEffect(() => {
    if (status !== "copied") return;

    snackbar({ variant: "success", message: "Código copiado." });
  }, [status]);

  useEffect(() => {
    if (browserSupports) return;

    snackbar({
      variant: "error",
      message:
        "Não foi possível copiar o código. Por favor, copie o código manualmente.",
    });
  }, [browserSupports]);

  if (!pixQrCode || !order?.isWaitingPayment()) return null;

  return (
    <>
      {isOpenHelpDrawer && <PixPaymentHelp onClose={closeHelpDrawer} />}
      <Drawer
        open={isOpen && !remainingTime.isExpired}
        onClose={handleClose}
        title="Pagamento"
      >
        <S.DrawerBody>
          <S.HelpButton onClick={openFAQDrawer} data-testid="pixFAQButton">
            Ajuda
          </S.HelpButton>

          <S.Heading as="h3">Pedido aguardando pagamento</S.Heading>
          <S.HeadingDescription>
            Copie o código a seguir e use a opção de Pix Copia e Cola no
            aplicativo que você vai pagar:
          </S.HeadingDescription>

          {!isMobile && (
            <S.ButtonWithCode onClick={handleCopy}>
              <QRCode value={pixQrCode} size={120} />
            </S.ButtonWithCode>
          )}

          <S.CopyField>
            <S.CopyFieldPix noWrap={browserSupports}>
              {pixQrCode}
            </S.CopyFieldPix>

            {browserSupports && (
              <S.CopyFieldIcon
                component={Copy}
                size="xs"
                onClick={handleCopy}
              />
            )}
          </S.CopyField>
          <S.Timer>
            <S.TimerText>O tempo para você pagar acaba em:</S.TimerText>
            <S.TimerCountdown data-testid="paymentTimer">
              {remainingTime.minutes}:{remainingTime.seconds}
            </S.TimerCountdown>
            <S.CountdownBar>
              <S.CountdownBarFill
                width={`${remainingTime.percentage}%`}
              ></S.CountdownBarFill>
            </S.CountdownBar>
          </S.Timer>
          <TextLink onClick={openHelpDrawer}>Como pagar com Pix</TextLink>
          <S.ValueCard>
            <S.ValueCardHeader>
              <S.Logo>
                {logo && (
                  <NextImage
                    src={logo}
                    alt={merchant.name}
                    objectFit="contain"
                    layout="fixed"
                    width={40}
                    height={40}
                  />
                )}
              </S.Logo>
              <S.ValueCardTitle>
                <Text variant="regular">{merchant.name}</Text>
                <Text variant="small" color="grayDarker" mt="smaller">
                  Pedido Nº {order.orderNumber}
                </Text>
              </S.ValueCardTitle>
            </S.ValueCardHeader>
            <S.ValueCardDivider />
            <Flex justifyContent="space-between">
              <Text variant="small">Total</Text>
              <Text fontWeight="500" variant="regular">
                {order.bag.total.toString()}
              </Text>
            </Flex>
          </S.ValueCard>
        </S.DrawerBody>

        {browserSupports && (
          <S.DrawerFooter>
            <S.CopyButton onClick={handleCopy}>Copiar código</S.CopyButton>
          </S.DrawerFooter>
        )}
      </Drawer>
    </>
  );
};
