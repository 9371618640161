import { Dialog } from '@whitelabel-webapp/shared/design-system';

import * as S from './styles';
import { Title } from './Title';

export const Desktop = ({ open, onClose, children }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <S.DialogHeader>
        <Title />
      </S.DialogHeader>
      <Dialog.Body mb="large">{children}</Dialog.Body>
    </Dialog>
  );
};
