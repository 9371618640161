import { mountTransition } from '@whitelabel-webapp/shared/design-system';
import { Transition, motion } from 'framer-motion';
import styled from 'styled-components';

export const Slider = styled(motion.div)<Transition>`
  height: 2px;
  margin-left: 2px;
  margin-right: 2px;
  bottom: 0;
  position: absolute;
  transition: ${({ theme: { durations, timingFunctions } }): string =>
    mountTransition([
      ['left', durations.slowly, timingFunctions.easeInOut],
      ['width', durations.slowly, timingFunctions.easeInOut],
    ])};

  background: ${(props): string => props.theme.colors.primary};
`;
