export const ERRORS = {
  AUTHENTICATION_REQUIRED: 'É necessário se autenticar antes de finalizar o pedido',
  CHECKOUT_DISABLED: 'Pedidos indisponíveis no momento, tente novamente mais tarde.',
  COUPON_NOT_ADDED: 'Nenhum cupom foi adicionado',
  EMPTY_CART: 'Nenhum item adicionado no carrinho',
  EXPIRED_TOKEN: 'Seu acesso expirou! Faça o login novamente.',
  NO_ADDRESS_SELECTED: 'Nenhum endereço foi selecionado',
  NO_DELIVERY_METHOD_SELECTED: 'Nenhuma forma de entrega foi selecionada',
  NO_PAYMENT_METHOD_SELECTED: 'Nenhuma forma de pagamento foi selecionada',
  NO_USER_SELECTED: 'Nenhum usuário foi selecionado',
  QUOTATION_API:
    'O método de entrega padrão não está disponível, escolha um dia e horário para receber seu pedido Seu pedido será agendado.',
  UNEXPECTED: 'Algo deu errado! Tente novamente em alguns segundos.',
};

export const ERROR_CODES = {
  CHECKOUT_DISABLED: 'MKTC-2135',
};
