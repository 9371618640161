import { Flex, Icon as PomodoroIcon } from '@whitelabel-webapp/shared/design-system';
import styled from 'styled-components';

export const Container = styled(Flex)`
  gap: 10px;
  align-items: center;
  margin-top: ${({ theme }) => theme.space.regular};
`;

export const Icon = styled(PomodoroIcon)`
  color: ${({ theme }) => theme.colors.grayDarkest};
`;
