import { useRouter } from "next/router";
import * as S from "./styles";
import { Carousel } from "@whitelabel-webapp/shared/design-system";
import { MerchantBanner } from "@whitelabel-webapp/merchant/shared/models";
import { MARCHANT_BANNER_URL } from "@whitelabel-webapp/shared/constants";

type BannerListProps = {
  qtd?: number;
  size?: "SMALL" | "BIG";
  items: MerchantBanner[];
};

export const BannerList: React.VFC<BannerListProps> = ({
  qtd = 1,
  size = "BIG",
  items,
}) => {
  const router = useRouter();

  const handleOnClick = (item: MerchantBanner) => {
    if (item.promotionLink) {
      if (item.openInNewPage) {
        window.open(item.promotionLink, "_blank");
      } else {
        router.push(item.promotionLink);
      }
    }
  };

  return (
    <Carousel
      gap={size === "SMALL" ? "clamp(15px, 2.5dvw, 48px)" : "0px"}
      mobileWidth={size === "SMALL" ? "110px" : "128px"}
      simultaneousItems={qtd}
      autoRotate={size === "SMALL" ? false : true}
    >
      {items.map((item) => {
        return (
          <S.Banner
            key={item.id}
            size={size}
            role="option"
            src={`${MARCHANT_BANNER_URL}/${item.imageUrl}`}
            aria-label={item.title}
            onClick={() => handleOnClick(item)}
          ></S.Banner>
        );
      })}
    </Carousel>
  );
};
