import {
  Button,
  Flex,
  Icon as PomodoroIcon,
  Text,
} from "@whitelabel-webapp/shared/design-system";
import styled from "styled-components";

export const Wrapper = styled(Flex)`
  flex-direction: column;
  width: -webkit-fill-available;
  height: 100%;
  padding: ${({ theme }) => theme.space.large};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grayLight};
  border-radius: 16px;
`;

export const Container = styled.div`
  display: block;
`;

export const Title = styled(Text)`
  color: ${({ theme }) => theme.colors.black};
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes.xs};
`;

export const Description = styled(Text)`
  color: ${({ theme }) => theme.colors.grayDarkest};
  font-size: ${({ theme }) => theme.fontSizes.xs};
`;

export const Time = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes.xs};
`;

export const ProgressBarContainer = styled(Flex)`
  flex-direction: column;
`;

export const ToggleButton = styled.button`
  top: ${({ theme }) => theme.space.small};

  flex-direction: column;
  justify-content: flex-end;

  background: none;
  border: none;
  border-radius: ${({ theme }) => theme.radii.l};
  cursor: pointer;
`;

export const Ellipses = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Line = styled(Flex)`
  width: 2px;

  height: 10px;
  margin-right: ${({ theme }) => theme.space.small};
  margin-left: ${({ theme }) => theme.space.smallest};

  background-color: ${({ theme }) => theme.colors.gray};
  border-radius: ${({ theme }) => theme.radii.m};
`;

export const StatusCircle = styled.div`
  justify-self: center;
  width: 4px;
  height: 4px;
  margin-top: ${({ theme }) => theme.space.smaller};
  margin-right: ${({ theme }) => theme.space.small};
  margin-bottom: ${({ theme }) => theme.space.smaller};
  margin-left: ${({ theme }) => theme.space.smallest};

  background-color: ${({ theme }) => theme.colors.positive};
  border-radius: ${({ theme }) => theme.radii.l};
`;

export const CurrentStatus = styled(Flex)`
  margin-top: ${({ theme }) => theme.space.small};
`;

export const CurrentStatusText = styled(Text)`
  flex-grow: 1;
  margin-left: ${({ theme }) => theme.space.smaller};

  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes.xs};
`;

export const StatusContainer = styled(Flex)`
  flex-grow: 1;
  align-items: center;
`;

export const PreviousStatusList = styled(Flex)`
  flex-direction: column;
`;

export const FollowOrderButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space.smaller};

  font-size: ${({ theme }) => theme.fontSizes.tsm};

  margin-top: ${({ theme }) => theme.space.regular};

  @media (max-width: ${({ theme }) => theme.breakpoints.s}) {
    width: 100%;

    justify-content: space-between;
  }
`;

export const Icon = styled(PomodoroIcon)`
  color: ${({ theme }) => theme.colors.white};
`;

export const Label = styled.span`
  flex-grow: 1;
`;

export const Circle = styled.div`
  width: 10px;
  height: 10px;
  margin: ${({ theme }) =>
    `${theme.space.none} ${theme.space.regular} ${theme.space.regular} ${theme.space.regular}`};

  border-radius: ${({ theme }) => theme.radii.l};
`;

export const Status = styled(Flex)`
  flex-direction: column;
  flex-grow: 1;

  color: ${({ theme }) => theme.colors.grayDarker};

  font-weight: "normal";
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-family: ${({ theme }) => theme.fonts.primary};
  line-height: ${({ theme }) => theme.fontSizes.xs};
  &:last-child {
    ${Label} {
      line-height: 18px;
    }

    ${Circle} {
      width: 10px;
      height: 10px;
      margin: 18px 12px 0px 3px;
    }
  }

  & ${Circle} {
    background-color: "#6fdaa6";
  }
`;

export const Header = styled(Flex)`
  display: flex;
  justify-content: space-between;
`;

export const UpdatedAt = styled.div`
  margin-left: auto;
`;

export const Cancelled = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
