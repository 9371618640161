import { ReactNode } from 'react';
import styled from 'styled-components';

export type PaneProps = {
  label: ReactNode;
};

export const Pane = styled.div<PaneProps>`
  flex-grow: 1;
`;
