import {
  Catalog,
  CatalogResponse,
  Item,
} from "@whitelabel-webapp/catalog/shared/models";

import { FC, createContext, useContext, useMemo } from "react";

export type CatalogContextValue = {
  catalog: Catalog;
  selectedItem?: Item;
};

const CatalogContext = createContext<CatalogContextValue | undefined>(
  undefined,
);

export type CatalogProviderProps = {
  catalogResponse: CatalogResponse;
};

export const CatalogProvider: FC<CatalogProviderProps> = ({
  catalogResponse,
  children,
}) => {
  const catalog = useMemo(
    () => Catalog.fromApi(catalogResponse),
    [catalogResponse],
  );

  const contextValue: CatalogContextValue = useMemo(
    () => ({
      catalog,
    }),
    [catalog],
  );

  return (
    <CatalogContext.Provider value={contextValue}>
      {children}
    </CatalogContext.Provider>
  );
};

CatalogProvider.displayName = "CatalogProvider";

export function useCatalog(): CatalogContextValue {
  const context = useContext(CatalogContext);
  if (typeof context === "undefined") {
    throw new Error(
      `'useCatalog()' must be used within a '${CatalogProvider.displayName}'`,
    );
  }

  return context;
}
