import { useAddress } from '@whitelabel-webapp/address/shared/address-store';
import { addressAboyeur } from '@whitelabel-webapp/address/shared/config';
import { useMerchant } from '@whitelabel-webapp/merchant/shared/merchant-store';
import { DeliveryMethod } from '@whitelabel-webapp/merchant/shared/models';
import { Heading, Text, snackbar } from '@whitelabel-webapp/shared/design-system';
import NextImage from 'next/image';

import { orderAboyeur } from '@app/domains/order/aboyeur';

import * as S from './styles';

type DeliveryUnavailableProps = {
  onClose: () => void;
  onCloseWithTakeout: () => void;
};

export const DeliveryUnavailable: React.FC<DeliveryUnavailableProps> = ({ onCloseWithTakeout, onClose }) => {
  const { merchant } = useMerchant();
  const { setDeliveryMethod } = useAddress();

  function handleOkButtonClick() {
    orderAboyeur.events.delivery_unavailable.clickOkIGotIt(merchant.id);

    onClose();
  }

  async function handleTakeout() {
    addressAboyeur.events.details.takeout();

    try {
      const takeoutMethodResponse = await merchant.getTakeoutMethod();

      if (!takeoutMethodResponse) {
        return;
      }

      const takeoutMethod = DeliveryMethod.fromApi(takeoutMethodResponse);

      setDeliveryMethod(takeoutMethod);
      onCloseWithTakeout();
    } catch (error) {
      snackbar({
        variant: 'error',
        message: 'Tente novamente',
      });

      orderAboyeur.events.catch.onError(error as Error);
    }
  }

  return (
    <>
      <S.Body>
        <NextImage src="/icons/missing-location.svg" width={156} height={156} />
        <Heading variant="medium" textAlign="center">
          Entrega indisponível <br /> no momento
        </Heading>

        {merchant.hasTakeoutFeature() ? (
          <Text variant="regular" textAlign="center" color="grayDarkest">
            Infelizmente, estamos sem entrega <br /> no momento. Você pode optar por retirar <br /> na loja
          </Text>
        ) : (
          <Text variant="regular" textAlign="center" color="grayDarkest">
            Infelizmente, estamos com poucas <br /> pessoas entregadoras no momento. <br />
            Tente novamente em alguns minutos.
          </Text>
        )}
      </S.Body>

      {merchant.hasTakeoutFeature() ? (
        <S.Button onClick={handleTakeout} tabIndex={0}>
          Retirar na loja
        </S.Button>
      ) : (
        <S.Button onClick={handleOkButtonClick} tabIndex={0}>
          Ok, entendi
        </S.Button>
      )}
    </>
  );
};
