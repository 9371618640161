import { GarnishItem as CatalogGarnishItem } from '@whitelabel-webapp/catalog/shared/garnish-item';
import { Choice } from '@whitelabel-webapp/catalog/shared/models';
import { GarnishItem } from '@whitelabel-webapp/checkout/shared/garnish-item';
import { CheckedOutlined, Heading, Counter as PomodoroCounter, Radio } from '@whitelabel-webapp/shared/design-system';
import { FormikErrors } from 'formik';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ChoiceItem } from './ChoiceItem';
import * as S from './styles';

type ItemChoiceProps = {
  hasUnsuccessfullyAttemptAddItem: boolean;
  choice: Choice;
  fieldValue: GarnishItem[];
  onComplete: () => void;
  sendRef: (ref: HTMLDivElement) => void;
  setFieldValue: (field: string, value: any) => Promise<FormikErrors<any>> | Promise<void>;
};

export const ItemChoice: React.VFC<ItemChoiceProps> = ({
  hasUnsuccessfullyAttemptAddItem,
  choice,
  fieldValue,
  setFieldValue,
  onComplete,
  sendRef,
}) => {
  const [selectedItems, setSelectedItems] = useState(new Map<string, GarnishItem>());
  const [hasCompleted, setHasCompleted] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    sendRef(ref?.current);
  }, [sendRef, ref]);

  useEffect(() => {
    if (!fieldValue.length || Array.from(selectedItems.values()).length > 0) {
      return;
    }

    fieldValue.forEach((value) => selectedItems.set(value.id, value));
    setSelectedItems(new Map(selectedItems));
  }, [fieldValue, selectedItems]);

  const totalQuantity = useMemo(() => {
    return Array.from(selectedItems.values())
      .map((item) => item.quantity)
      .reduce((acc, quantity) => acc + quantity, 0);
  }, [selectedItems]);

  const isChecked = useCallback(
    (item: CatalogGarnishItem) => {
      return selectedItems.has(item.id.toString());
    },
    [selectedItems]
  );

  const getItemQuantity = useCallback(
    (item: CatalogGarnishItem) => {
      const selectedItem = selectedItems.get(item.id.toString());

      if (!selectedItem) {
        return 0;
      }

      return selectedItem.quantity;
    },
    [selectedItems]
  );

  const getItemMaxQuantity = useCallback(
    (item: CatalogGarnishItem) => {
      return choice.max - (totalQuantity - getItemQuantity(item));
    },
    [choice, getItemQuantity, totalQuantity]
  );

  const isItemCounterDisabled = useCallback(
    (item: CatalogGarnishItem) => {
      if (!getItemQuantity(item) && totalQuantity === choice.max) {
        return true;
      }

      return false;
    },
    [choice, getItemQuantity, totalQuantity]
  );

  const handleItemRadio = useCallback(
    (item: CatalogGarnishItem) => {
      const selectedItem = selectedItems.get(item.id.toString());

      if (!selectedItem) {
        selectedItems.clear();
        selectedItems.set(item.id.toString(), GarnishItem.fromCatalogGarnishItem(item, 1));
        setFieldValue(choice.code, Array.from(selectedItems.values()));
        setSelectedItems(new Map(selectedItems));
        return;
      }

      selectedItems.clear();
      setFieldValue(choice.code, []);
      setSelectedItems(new Map(selectedItems));
    },
    [choice, selectedItems, setSelectedItems, setFieldValue]
  );

  const handleItemCounter = useCallback(
    (evt: ChangeEvent<HTMLInputElement>, item: CatalogGarnishItem) => {
      const { value } = evt.target;
      const newQuantity = Number(value);

      const selectedItem = selectedItems.get(item.id.toString());

      if (!selectedItem || newQuantity !== 0) {
        selectedItems.set(item.id.toString(), GarnishItem.fromCatalogGarnishItem(item, newQuantity));
        setSelectedItems(new Map(selectedItems));
        setFieldValue(choice.code, Array.from(selectedItems.values()));
        return;
      }

      selectedItems.delete(item.id.toString());
      setSelectedItems(new Map(selectedItems));
      setFieldValue(
        choice.code,
        Array.from(selectedItems.values()).map((garnishItem) => garnishItem)
      );
    },
    [choice, selectedItems, setSelectedItems, setFieldValue]
  );

  useEffect(() => {
    if (!hasCompleted && totalQuantity && choice.min === totalQuantity) {
      onComplete();
      setHasCompleted(true);
    }
  }, [choice.min, hasCompleted, onComplete, totalQuantity]);

  return (
    <S.Wrapper flexDirection="column" ref={ref}>
      <S.Header justifyContent="space-between" alignItems="flex-end">
        <S.Quantity>
          <Heading as="h3" variant="smallest">
            {choice.name}
          </Heading>
          <S.QuantityDescription>
            {totalQuantity} de {choice.max}
          </S.QuantityDescription>
        </S.Quantity>
        {choice.isMandatory() &&
          (totalQuantity < choice.min ? (
            <S.Tag hasUnsuccessfullyAttemptAddItem={hasUnsuccessfullyAttemptAddItem}>
              <S.TagDescription>Obrigatório</S.TagDescription>
            </S.Tag>
          ) : (
            <S.Icon component={CheckedOutlined} />
          ))}
      </S.Header>
      {choice.isUniqueChoice() && (
        <Radio.Group role="group" mb={10}>
          {choice.items.map((item) => (
            <ChoiceItem key={item.id} item={item}>
              <S.ItemRadio
                id={item.id}
                checked={isChecked(item)}
                onClick={() => handleItemRadio(item)}
                onChange={() => {
                  return;
                }}
              />
            </ChoiceItem>
          ))}
        </Radio.Group>
      )}
      {choice.isMultiChoice() &&
        choice.items.map((item) => (
          <ChoiceItem key={item.id} item={item}>
            <S.ItemCounter>
              <PomodoroCounter
                name={item.description}
                min={0}
                max={getItemMaxQuantity(item)}
                value={getItemQuantity(item)}
                onChange={(evt) => handleItemCounter(evt, item)}
                increaseAriaLabel={`Aumentar ${item.description}`}
                decreaseAriaLabel={`Diminuir ${item.description}`}
                disabled={isItemCounterDisabled(item)}
                readOnly
              />
            </S.ItemCounter>
          </ChoiceItem>
        ))}
    </S.Wrapper>
  );
};
