import { Flex, Label } from "@whitelabel-webapp/shared/design-system";
import { Text } from "@whitelabel-webapp/shared/design-system";
import {
  Folders,
  Sizes,
  getImageURL,
} from "@whitelabel-webapp/shared/image-utils";
import styled from "styled-components";
import { DefaultTheme } from "styled-components";

type Themed = { theme: DefaultTheme };
const belowDesktop = ({ theme }: Themed) => `max-width: ${theme.screens.m.max}`;

const imageLoader = ({ src }: { src: string }) =>
  getImageURL(Folders.DISH, src, Sizes.LOW);

export const Wrapper = styled(Flex)`
  padding: ${({ theme }) => `${theme.space.regular} ${theme.space.largest}`};

  @media (${belowDesktop}) {
    padding: ${({ theme }) => theme.space.regular};
  }
`;

export const Container = styled(Flex)`
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
`;

export const Name = styled(Label)`
  color: ${({ theme }) => theme.colors.black};
`;

export const Details = styled(Text)`
  margin-top: 8px;

  color: ${({ theme }) => theme.colors.grayDarkest};
  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: 16px;
`;

export const Price = styled(Details)`
  color: ${({ theme }) => theme.colors.black};
  font-weight: bold;
`;

export const Action = styled(Flex)`
  align-items: center;
  gap: ${({ theme }) => theme.space.small};
`;

type ImageProps = {
  logoUrl?: string;
};

export const Image = styled.div<ImageProps>`
  width: 60px;
  height: 50px;

  background-color: ${({ theme }) => theme.colors.gray};
  background-image: url(${({ logoUrl }) =>
    logoUrl ? imageLoader({ src: logoUrl }) : "/images/no_image.png"});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-color: ${({ theme }) => theme.colors.gray};
  border-radius: ${({ theme }) => theme.radii.s};
`;
