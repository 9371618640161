import { Dialog, Heading, Icon as PomodoroIcon } from '@whitelabel-webapp/shared/design-system';
import styled from 'styled-components';

export const Header = styled(Dialog.Header)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space.regular};

  padding: ${({ theme }) => theme.space.regular};
`;

export const Title = styled(Heading)`
  font-size: ${({ theme }) => theme.fontSizes.s};

  margin: ${({ theme }) => theme.space.none};
`;

export const Icon = styled(PomodoroIcon)`
  cursor: pointer;
`;

export const Body = styled.div`
  padding: ${({ theme }) => theme.space.regular};
`;
