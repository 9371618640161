import { Star as StarIcon } from '@ifood/pomodoro-icons';
import { Icon } from '@whitelabel-webapp/shared/design-system';
import React from 'react';

interface Props {
  filled?: boolean;
  onClick?: () => void;
}

export const Star: React.VFC<Props> = ({ filled, ...props }) => {
  return (
    <Icon
      size="m"
      component={StarIcon}
      svgProps={{
        cursor: props.onClick ? 'pointer' : 'default',
        color: filled ? '#FAB125' : '#DCDCDC',
      }}
      {...props}
    />
  );
};
