import { accountAboyeur } from '@whitelabel-webapp/account/shared/config';
import { useAddress } from '@whitelabel-webapp/address/shared/address-store';
import { useAuthentication } from '@whitelabel-webapp/authentication/shared/authentication-store';
import { useMerchant } from '@whitelabel-webapp/merchant/shared/merchant-store';
import { Exit, Order, PinOutlined } from '@whitelabel-webapp/shared/design-system';
import { getAllCampaignPathParameter } from '@whitelabel-webapp/shared/url-utils';
import { useRouter } from 'next/router';

import * as S from './styles';

export const SuspendedMenu: React.VFC = () => {
  const router = useRouter();

  const { merchant } = useMerchant();
  const { customer, logout } = useAuthentication();
  const { openAddress } = useAddress();

  function handleRedirectToOrder() {
    accountAboyeur.events.headerSuspendedMenu.orders();
    router.push(`/${merchant.query}/order` + getAllCampaignPathParameter());
  }

  function handleAddresses() {
    accountAboyeur.events.headerSuspendedMenu.addresses();
    openAddress('MERCHANT');
  }

  function handleLogout() {
    accountAboyeur.events.headerSuspendedMenu.logout();
    logout();
  }

  if (!customer) return null;

  return (
    <S.Wrapper>
      <S.Title>Olá, {customer.user.name}</S.Title>
      {customer && (
        <>
          <S.Option onClick={handleRedirectToOrder}>
            <S.Icon component={Order} size="s" />
            <S.TextLink>Pedidos</S.TextLink>
          </S.Option>
          <S.Option onClick={handleAddresses}>
            <S.Icon component={PinOutlined} size="s" />
            <S.TextLink>Endereços</S.TextLink>
          </S.Option>
          <S.Option onClick={handleLogout}>
            <S.Icon component={Exit} size="s" />
            <S.TextLink>Sair</S.TextLink>
          </S.Option>
        </>
      )}
    </S.Wrapper>
  );
};
