import { useMerchant } from '@whitelabel-webapp/merchant/shared/merchant-store';
import { createPersistedState } from '@whitelabel-webapp/shared/use-persisted-state';
import { useMemo } from 'react';

const CURRENT_CAMPAIGN_CODE_KEY = 'current_campaignCode';

export function usePersistedCampaignCode() {
  const { merchant } = useMerchant();

  const usePersistedState = useMemo(
    () => createPersistedState<string>(`${merchant.id}.${CURRENT_CAMPAIGN_CODE_KEY}`),
    [merchant]
  );

  return usePersistedState();
}
