import { ChevronLeft, Heading, Icon } from '@whitelabel-webapp/shared/design-system';
import { useIsMobile } from '@whitelabel-webapp/shared/hooks';

import * as S from './styles';

type DialogTitleProps = {
  className?: string;
  onBack?: () => void;
};

export const DialogTitle: React.FC<DialogTitleProps> = ({ className, children, onBack }) => {
  const isMobile = useIsMobile();
  const Header = isMobile ? S.MobileHeader : S.DesktopHeader;
  const textAlign = isMobile ? undefined : 'center';

  return (
    <Header className={className}>
      {onBack && (
        <S.BackButton onClick={onBack} aria-label="Fechar" variant="tertiary" p={0} mr="smaller">
          <Icon component={ChevronLeft} size="s" />
        </S.BackButton>
      )}
      <Heading m={0} as="h4" variant="small" textAlign={textAlign}>
        {children}
      </Heading>
    </Header>
  );
};
