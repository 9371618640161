import { Money } from '@whitelabel-webapp/shared/models';
import { PriceResponse } from "./order";

export type SubItemResponse = {
  id: string;
  quantity: number;
  name: string;
};

export type ItemResponse = {
  catalogItemId: string;
  description: string;
  name: string;
  prices: {
    grossValue: PriceResponse;
    netValue: PriceResponse;
    unitValue: PriceResponse;
    unitValueMinusDiscounts: PriceResponse;
  };
  quantity: number;
  subItems: SubItemResponse[];
  note: string;
};

export class Item {
  static fromApi(response: ItemResponse) {
    return new Item(
      response.catalogItemId,
      response.description,
      response.name,
      new Money(response.prices.grossValue.value / 100),
      new Money(response.prices.grossValue.value / 100),
      new Money(response.prices.netValue.value / 100),
      new Money(response.prices.unitValue.value / 100),
      new Money(response.prices.unitValueMinusDiscounts.value / 100),
      response.quantity,
      response.subItems,
      response.note,
    );
  }

  constructor(
    public id: string,
    public description: string,
    public name: string,
    public price: Money,
    public totalPrice: Money,
    public totalPriceWithDiscount: Money,
    public unitPrice: Money,
    public unitPriceWithDiscount: Money,
    public quantity: number,
    public subItems: SubItemResponse[],
    public notes: string,
  ) {}
}
