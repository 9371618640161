import styled from "styled-components";

type BannerProps = {
  src: string;
  size: "SMALL" | "BIG";
};

export const Banner = styled.div<BannerProps>`
  width: 100%;
  cursor: pointer;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  aspect-ratio: ${({ size }) => (size === "BIG" ? "1300 / 400" : "1")};
  background-image: ${({ src }) => `url(${src})`};
`;
