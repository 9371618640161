import { useCatalog } from "@whitelabel-webapp/catalog/shared/catalog-store";
import * as S from "./styles";
import Link from "next/link";
import { ChevronDown } from "@ifood/pomodoro-icons";
import { Icon } from "@ifood/pomodoro-components";
import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";

export const NavbarCategory: React.VFC = () => {
  const { catalog } = useCatalog();
  const { merchant } = useMerchant();
  const catalogLinks = catalog.links;

  return (
    <>
      <S.Container>
        <S.Nav>
          <S.AllCategories>
            Todas as categorias
            <Icon component={ChevronDown} width="20px" height="20px"></Icon>
            <S.Dropdown>
              {catalogLinks?.map((link) => (
                <Link
                  key={link.name}
                  href={`/${merchant.query}/categoria/${link.uuid}`}
                >
                  {link.name}
                </Link>
              ))}
            </S.Dropdown>
          </S.AllCategories>
          {catalogLinks?.slice(0, 9).map((link) => (
            <S.NavItem key={link.name}>
              <Link href={`/${merchant.query}/categoria/${link.uuid}`}>
                {link.name}
              </Link>
            </S.NavItem>
          ))}
        </S.Nav>
      </S.Container>
    </>
  );
};
