import {
  Button,
  ButtonClean,
  Card,
  Divider,
  Drawer,
  Flex,
  Icon,
  Heading as PomodoroHeading,
  Text,
  TextLink,
} from '@whitelabel-webapp/shared/design-system';
import styled from 'styled-components';

export const DrawerBody = styled(Drawer.Body)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  padding: ${(props) => props.theme.space.regular};
  overflow-y: scroll;

  text-align: center;
`;

export const DrawerFooter = styled(Drawer.Footer)`
  border-top: 1px solid ${({ theme }) => theme.colors.gray};
`;

export const CopyButton = styled(Button)`
  width: 100%;
  font-size: ${({ theme }) => theme.fontSizes.s};
`;

export const HelpButton = styled(Button).attrs({ variant: 'tertiary' })`
  position: absolute;
  top: ${({ theme }) => theme.space.regular};
  right: ${({ theme }) => theme.space.small};

  font-size: ${({ theme }) => theme.fontSizes.xs};
  text-decoration: underline;

  cursor: pointer;
`;

export const Heading = styled(PomodoroHeading).attrs({ variant: 'small' })`
  margin-top: ${({ theme }) => theme.space.large};
  margin-bottom: ${({ theme }) => theme.space.small};
  font-weight: 500;
`;

export const HeadingDescription = styled(Text).attrs({ variant: 'regular' })`
  margin-left: ${({ theme }) => theme.space.large};
  margin-right: ${({ theme }) => theme.space.large};
`;

export const ButtonWithCode = styled(ButtonClean)`
  margin-top: ${(props) => props.theme.space.large};
`;

export const CopyField = styled(Card).attrs({ display: 'flex', variant: 'secondary' })`
  margin-top: ${(props) => props.theme.space.large};
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
`;

export const CopyFieldPix = styled(Text).attrs({ variant: 'medium' })`
  font-size: ${(props) => props.theme.fontSizes.tsm};
  overflow-x: auto;
  white-space: nowrap;
  white-space: ${(props: { noWrap?: boolean }) => (props.noWrap ? 'nowrap' : 'normal')};
  word-break: break-all;
`;

export const CopyFieldIcon = styled(Icon).attrs({ variant: 'medium' })`
  margin-left: ${(props) => props.theme.space.regular};
  cursor: pointer;
`;

export const Timer = styled.div`
  width: 100%;
  margin-top: ${(props) => props.theme.space.larger};
  margin-bottom: ${(props) => props.theme.space.large};

  text-align: left;
`;

export const TimerText = styled(Text).attrs({ variant: 'small' })`
  font-size: ${(props) => props.theme.fontSizes.tsm};
  color: ${(props) => props.theme.colors.grayDarker};
`;

export const TimerCountdown = styled(PomodoroHeading).attrs({ variant: 'medium' })`
  font-weight: 500;
  margin-top: ${(props) => props.theme.space.small};
  margin-bottom: ${(props) => props.theme.space.smallest};
`;

export const CountdownBar = styled.div`
  width: 100%;
  height: 4px;
  margin-top: ${(props) => props.theme.space.smallest};

  background-color: #c4c4c4;
  border-radius: 2px;
`;

export const CountdownBarFill = styled(Flex)`
  height: 100%;

  background-color: #32bcad;
  border-radius: 2px;
`;

export const PixHelpLink = styled(TextLink).attrs({ variant: 'medium' })`
  color: ${(props) => props.theme.colors.black};
`;

export const ValueCard = styled.div`
  box-sizing: border-box;
  width: 100%;
  margin-top: ${(props) => props.theme.space.large};
  padding: ${(props) => props.theme.space.regular};

  border: 1px solid #f2f2f2;
  border-radius: 4px;
`;

export const ValueCardHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const ValueCardTitle = styled.div`
  margin-left: ${(props) => props.theme.space.small};

  text-align: left;
`;

export const ValueCardDivider = styled(Divider)`
  border-bottom-color: #f2f2f2;
  margin-top: ${(props) => props.theme.space.regular};
  margin-bottom: ${(props) => props.theme.space.regular};
`;

export const Logo = styled.div`
  display: inline-block;
  padding: ${(props) => props.theme.space.small};

  background-color: #f7f7f7;
  border-radius: ${(props) => props.theme.radii.l};
`;
