import SVG from 'react-inlinesvg';

import * as S from './styles';

export const NoItemsAdded: React.VFC = () => {
  return (
    <S.Wrapper>
      <SVG src="/images/checkout/sleeping_bag.svg" width={150} height={147} />
      <S.Title>Sua sacola está vazia</S.Title>
      <S.Description>Adicione itens</S.Description>
    </S.Wrapper>
  );
};
