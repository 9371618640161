import { useMerchant } from '@whitelabel-webapp/merchant/shared/merchant-store';
import { Heading } from '@whitelabel-webapp/shared/design-system';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import SVG from 'react-inlinesvg';

import * as S from './styles';

export const PaymentPending = () => {
  const { merchant } = useMerchant();
  const router = useRouter();

  useEffect(() => {
    setTimeout(() => {
      router.push(`/${merchant.query}/order`);
    }, 1500);
  }, []);

  return (
    <S.Container>
      <SVG src="/images/checkout/order_noted.svg" />
      <Heading fontWeight={400} variant="small" textAlign="center" as="h1">
        Vamos redirecionar você para a página de pagamento.
      </Heading>
    </S.Container>
  );
};
