import { Button, Dialog, Flex } from '@whitelabel-webapp/shared/design-system';
import styled from 'styled-components';

export const BackButton = styled(Button)`
  position: absolute;
  left: ${({ theme }) => theme.space.large};

  width: 24px;
  height: 24px;
`;

export const DesktopHeader = styled(Dialog.Header)`
  align-items: center;
  justify-content: center;
`;

export const MobileHeader = styled(Flex)`
  align-items: center;
  justify-content: center;
`;
