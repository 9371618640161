import tokens from "@ifood/pomodoro-tokens";

import { tokenToNumber } from "./utils";

const TITLE_SIZE = tokenToNumber(tokens.space.large); // Title: pt=24
export const TABS_SIZE = 80; // Tabs: h=38
export const HEADER_SIZE = 3 * tokenToNumber(tokens.space.large); // Header: pt=24, h=24, pb=24
export const ORDER_TRACKING_ALERT_SIZE = tokenToNumber(tokens.space.larger); // Alert: h=32
export const NEW_HEADER_SIZE = 250;
export const SCROLL_OFFSET = HEADER_SIZE + TABS_SIZE + TITLE_SIZE;
