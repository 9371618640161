export type Storage<Key extends string, Value> = {
  get(key: Key, defaultValue?: Value): Value;
  set(key: Key, value: Value): void;
};

export const createStorage = <Key extends string, Value>(
  provider: WindowLocalStorage['localStorage']
): Storage<Key, Value> => ({
  get(key: Key, defaultValue: Value): Value {
    const json = provider.getItem(key);
    try {
      if (json == null) throw new Error('Invalid local storage value');
      return JSON.parse(json);
    } catch (e) {
      return typeof defaultValue === 'function' ? defaultValue() : defaultValue;
    }
  },
  set(key: Key, value: Value) {
    provider.setItem(key, JSON.stringify(value));
  },
});
