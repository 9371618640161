import { DrawerWithPush } from '@whitelabel-webapp/shared/design-system';

import { WrapperToRenderProps } from '../types';
import * as S from './styles';

export const Mobile: React.FC<WrapperToRenderProps> = ({ children, isOpen, onClose, ...props }) => {
  return (
    <DrawerWithPush open={isOpen} onClose={onClose} {...props}>
      {children}
      <S.Button mt={10} variant="tertiary" onClick={onClose}>
        Ok, entendi
      </S.Button>
    </DrawerWithPush>
  );
};
