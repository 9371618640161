import { useMerchant } from '@whitelabel-webapp/merchant/shared/merchant-store';
import { Z_INDEXES } from '@whitelabel-webapp/shared/constants';
import { Text } from '@whitelabel-webapp/shared/design-system';
import { useIsMobile } from '@whitelabel-webapp/shared/hooks';

import { Desktop } from './Desktop';
import { Mobile } from './Mobile';

type TakeoutAddressProps = {
  open: boolean;
  onClose: () => void;
};

export const TakeoutAddress: React.VFC<TakeoutAddressProps> = ({ open, onClose }) => {
  const { merchant } = useMerchant();
  const isMobile = useIsMobile();

  const WrapperToRender = isMobile ? Mobile : Desktop;

  if (!open) return null;

  return (
    <WrapperToRender
      isOpen={true}
      onClose={onClose}
      zIndex={Z_INDEXES.TAKEOUT_ADDRESS_DRAWER}
      title="Endereço para retirada"
      data-testid="takeout-address"
    >
      <Text variant="medium" textAlign="center">
        {merchant.getStreetAddress()}
      </Text>
      <Text variant="medium" textAlign="center">
        {merchant.getNeighborhoodAddress()}
      </Text>
    </WrapperToRender>
  );
};
