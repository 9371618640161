import { useCallback } from "react";
import NextImage from "next/image";
import { useRouter } from "next/router";

import { Item } from "@whitelabel-webapp/catalog/shared/models";
import { Flex } from "@whitelabel-webapp/shared/design-system";
import { catalogAboyeur } from "@app/domains/catalog/aboyeur";

import { ecommerceEvents } from "@whitelabel-webapp/shared/ecommerce-events";
import { Sizes, withoutLoader } from "@whitelabel-webapp/shared/image-utils";

import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";
import { useIsMobile } from "@whitelabel-webapp/shared/hooks";

import { QuickAddItem } from "../QuickAddItem";

import * as S from "./styles";

type ItemProps = {
  item: Item;
};

export const SmallItem: React.VFC<ItemProps> = ({ item }) => {
  const { merchant } = useMerchant();
  const router = useRouter();
  const isMobile = useIsMobile();

  const { id, description, details } = item;

  const handleItemClick = useCallback(() => {
    router.push(`/${merchant.query}/item/${item.code}`);
    ecommerceEvents.click(item);
    catalogAboyeur.events.item.click(description, "default", id);
  }, [description, item, id]);

  const price = item.getBasePrice().format();
  const isPromotionalItem = item.isPromotionalItem();
  const originalPrice = item.unitOriginalPrice?.format();
  const discountPercentage = item.getDiscountPercentage();

  return (
    <S.Container
      m="small"
      role="option"
      key={`${id}`}
      onClick={handleItemClick}
      aria-label={item.description}
    >
      <S.ImageArea>
        <S.ImageWrapper>
          <NextImage
            loader={withoutLoader}
            src={item.getLogoUrl(Sizes.LOW)}
            alt={description}
            layout="fill"
            objectFit="cover"
            unoptimized={true}
          />
        </S.ImageWrapper>
        {isMobile && (
          <S.PlusIcon>
            <QuickAddItem item={item} variant="small"></QuickAddItem>
          </S.PlusIcon>
        )}
      </S.ImageArea>
      <S.ItemInfo>
        <S.LowerSection>
          <S.InvertOnMobile>
            <S.Description>{description}</S.Description>
            <S.PriceSection>
              <S.Price color="positive">{price}</S.Price>
              {isPromotionalItem && (
                <S.PromotionArea>
                  <S.StrikethroughedText
                    variant="small"
                    color="grayDarker"
                    fontWeight={400}
                  >
                    {originalPrice}
                  </S.StrikethroughedText>
                  <S.Discount>-{discountPercentage}%</S.Discount>
                </S.PromotionArea>
              )}
            </S.PriceSection>
          </S.InvertOnMobile>
          {details && (
            <S.Details color="grayDarkest">{item.getDetails()}</S.Details>
          )}
        </S.LowerSection>
      </S.ItemInfo>
      {!isMobile && <QuickAddItem item={item}></QuickAddItem>}
    </S.Container>
  );
};
