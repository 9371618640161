import { groceriesApiBffURL } from "@whitelabel-webapp/shared/config";
import axios, { AxiosInstance } from "axios";

import { PaymentMethod, PaymentMethodResponse } from "./paymentMethod";

export class PaymentMethods {
  static client: AxiosInstance;
  static nextClient: AxiosInstance;

  static initClient(): void {
    PaymentMethods.client = axios.create({
      baseURL: groceriesApiBffURL,
      headers: {
        "accept-language": "pt-BR,pt;q=1",
      },
    });
  }

  static initNextClient(): void {
    PaymentMethods.nextClient = axios.create();
  }

  static async getPaymentMethods(uuid: string): Promise<PaymentMethodResponse> {
    if (!PaymentMethods.client) {
      PaymentMethods.initClient();
    }

    const { data } = await PaymentMethods.client.get<PaymentMethodResponse>(
      `/payment-methods/${uuid}`,
      {},
    );

    return data;
  }

  static fromApi(rawPaymentMethods: PaymentMethodResponse) {
    return new PaymentMethods(
      rawPaymentMethods.map((paymentMethod) =>
        PaymentMethod.fromApi(paymentMethod),
      ),
    );
  }

  static generatePaymentMethodsGroupByName(paymentMethods: PaymentMethod[]) {
    const paymentMethodsByName = paymentMethods.reduce<
      Record<string, PaymentMethod[]>
    >((acc, paymentMethod) => {
      const paymentMethodsList = acc[paymentMethod.method.description];

      return {
        ...acc,
        [paymentMethod.method.description]: paymentMethodsList
          ? [...paymentMethodsList, paymentMethod]
          : [paymentMethod],
      };
    }, {});

    return paymentMethodsByName;
  }

  constructor(public list: PaymentMethod[]) {}

  getOfflinePayments() {
    return this.list.filter(
      (paymentMethod) =>
        paymentMethod.isOffline() && !paymentMethod.isBankDraft(),
    );
  }

  getOfflinePaymentsMethodName() {
    return Object.keys(
      PaymentMethods.generatePaymentMethodsGroupByName(
        this.getOfflinePayments(),
      ),
    );
  }

  getOnlineCardsPayments() {
    return this.list.filter(
      (paymentMethod) => paymentMethod.isOnline() && !paymentMethod.isPix(),
    );
  }

  getOnlineMealVoucherPayments() {
    return this.list.filter(
      (paymentMethod) =>
        paymentMethod.isMealVoucher() && paymentMethod.isOnline(),
    );
  }

  getBankDraftPaymentMethod() {
    return this.list.find(
      (paymentMethod) =>
        paymentMethod.isOffline() && paymentMethod.isBankDraft(),
    );
  }

  hasOfflinePaymentMethod() {
    return this.list.some((paymentMethod) => paymentMethod.isOffline());
  }

  hasBankDraftPaymentMethod() {
    return this.list.some(
      (paymentMethod) =>
        paymentMethod.isOffline() && paymentMethod.isBankDraft(),
    );
  }

  hasPixPaymentMethod() {
    return this.list.some((paymentMethod) => paymentMethod.isPix());
  }

  hasOnlineCardsPaymentMethod() {
    return this.list.some(
      (paymentMethod) => paymentMethod.isOnline() && !paymentMethod.isPix(),
    );
  }
}
