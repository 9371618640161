import styled, { DefaultTheme } from "styled-components";
import { Dialog as PomodoroDialog } from "@whitelabel-webapp/shared/design-system";
import { Heading, Text } from "@ifood/pomodoro-components";

const desktop = ({ theme }: { theme: DefaultTheme }) =>
  `min-width: ${theme.screens.l.min}`;
const belowDesktop = ({ theme }: { theme: DefaultTheme }) =>
  `max-width: ${theme.screens.m.max}`;

export const Dialog = styled(PomodoroDialog)`
  width: 864px;
  max-width: 864px;
  position: relative;
`;

type Props = {
  active: boolean;
};

export const DateCard = styled.div<Props>`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
  padding: ${({ theme }) => theme.space.smaller};
  border-radius: ${({ theme }) => theme.space.small};

  --gray: ${({ theme }) => theme.colors.gray};
  --black: ${({ theme }) => theme.colors.black};
  --borderColor: ${({ active }) => (active ? "var(--black)" : "var(--gray)")};

  border: solid 1px var(--borderColor);
  background-color: white;

  &:hover {
    filter: ${({ active }) => (active ? "brightness(1)" : "brightness(0.97)")};
  }

  @media (${belowDesktop}) {
    span {
      font-size: 12px;
      line-height: 20px;

      &:first-of-type {
        font-size: 14px;
      }
    }
  }
`;

export const SlotsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.regular};
  overflow-y: auto;
  height: 446px;
  max-height: 33dvh;

  @media (${desktop}) {
    &::-webkit-scrollbar {
      background-color: transparent;
      width: 16px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.gray};
      border-radius: 8px;
      border: 5px solid white;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }
  }

  @media (${belowDesktop}) {
    max-height: 43dvh;
  }
`;

export const SlotCard = styled.div<Props>`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  padding: ${({ theme }) => theme.space.small};
  border-radius: ${({ theme }) => theme.space.small};

  --gray: ${({ theme }) => theme.colors.gray};
  --black: ${({ theme }) => theme.colors.black};
  --borderColor: ${({ active }) => (active ? "var(--black)" : "var(--gray)")};

  border: solid 1px var(--borderColor);
  background-color: white;

  &:hover {
    filter: ${({ active }) => (active ? "brightness(1)" : "brightness(0.97)")};
  }

  @media (${belowDesktop}) {
    span {
      font-size: 14px;
    }
  }
`;

export const CircleCheck = styled.div<Props>`
  width: 12px;
  aspect-ratio: 1;
  border-radius: 50%;

  --primary: ${({ theme }) => theme.colors.primary};
  --borderColor: ${({ active }) => (active ? "var(--primary)" : "transparent")};

  background-color: ${({ active }) => (active ? "white" : "#E0E0E0")};
  border: solid 6px var(--borderColor);
`;

export const CloseButton = styled.button`
  position: absolute;
  top: ${({ theme }) => theme.space.largest};
  right: ${({ theme }) => theme.space.looser};
  background: transparent;
  cursor: pointer;
  border: none;

  svg {
    color: ${({ theme }) => theme.colors.primary};
  }

  @media (${belowDesktop}) {
    display: none;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.space.largest};
  gap: ${({ theme }) => theme.space.larger};

  @media (${belowDesktop}) {
    padding: ${({ theme }) => theme.space.none};
    gap: ${({ theme }) => theme.space.large};

    [role="listbox"] {
      margin: 0 -${({ theme }) => theme.space.regular};
    }
  }
`;

export const Title = styled(Heading)`
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  margin: 0;

  @media (${belowDesktop}) {
    font-size: 18px;
    line-height: 24px;
    margin-top: -16px;
  }
`;

export const Subtitle = styled(Text)`
  margin: 0;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.colors.grayDarkest};

  @media (${belowDesktop}) {
    font-size: 14px;
    line-height: 16px;
  }
`;
