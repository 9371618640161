import { Flex, Heading, Text } from '@whitelabel-webapp/shared/design-system';
import styled from 'styled-components';

export const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
`;

export const Title = styled(Heading).attrs({ as: "h2" })`
  font-size: 20px;
  margin-top: 0;
`;

export const Description = styled(Text)`
  font-size: 16px;
  color: #717171;
  text-align: center;
  margin-bottom: 24px;
`;
