import { maxWidth } from "@app/utils/viewport";
import styled from "styled-components";
import SVG from 'react-inlinesvg';
import { DefaultTheme } from "styled-components";

const bellowDesktop = ({ theme }: { theme: DefaultTheme }) =>
  `max-width: ${theme.screens.l.min}`;

export const Footer = styled.footer`
  width: -webkit-fill-available;
  @media (${bellowDesktop}) {
    display: none;
  }
`;

export const TopContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #f7f7f7;
  padding: ${({ theme }) => `${theme.space.largest} ${theme.space.loose}`};

  @media (${bellowDesktop}) {
    padding: ${({ theme }) => theme.space.larger};
    text-align: center;
  }
`;

export const BottomContent = styled(TopContent)`
  border-top: solid 1px #dcdcdc;
  text-align: left;
`;

export const Content = styled.div`
  max-width: 1100px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${({ theme }) => theme.space.regular};

  @media (${bellowDesktop}) {
    flex-direction: column;
    gap: ${({ theme }) => theme.space.large};
  }
`;

export const SocialMediaIcon = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export const Column = styled.div`
  flex: auto;
  display: flex;
  gap: ${({ theme }) => theme.space.larger};
  width: min-content;
  flex-direction: column;

  @media (${bellowDesktop}) {
    width: 100%;
    gap: ${({ theme }) => theme.space.regular};
  }
`;

export const SocialMediaGroupColumn = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space.larger};
  flex-direction: row;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space.regular};
`;

export const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: ${({ theme }) => theme.fontSizes.s};
  color: ${({ theme }) => theme.colors.black};
  font-weight: 500;
`;

export const Info = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: ${({ theme }) => theme.fontSizes.s};
  color: ${({ theme }) => theme.colors.grayDarkest};
  font-weight: 400;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const SmallInfo = styled(Info)`
  font-size: ${({ theme }) => theme.fontSizes.tsm};
  text-align: center;
`;

export const SmallerInfo = styled(Info)`
  max-width: 256px;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  &:hover {
    cursor: inherit;
    text-decoration: none;
  }
`;

export const Payments = styled.div`
  gap: ${({ theme }) => theme.space.smaller};
  display: flex;
  width: 100%;

  @media (${bellowDesktop}) {
    justify-content: center;
  }
`;

export const Image = styled(SVG)`
  margin: 0px 3px 0px 3px;
`;
