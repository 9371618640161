import { Button, Flex, Tag as PomodoroTag, Text, TextArea } from '@whitelabel-webapp/shared/design-system';
import styled from 'styled-components';
type TagProps = {
  active?: boolean;
};

export const Wrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  margin: ${({ theme }) => theme.space.small} 0;
  padding: ${({ theme }) => theme.space.smaller};
  overflow-y: scroll;

  text-align: center;
`;

export const StarWrapper = styled(Flex)`
  flex-direction: column;
`;

export const StarsContainer = styled(Flex)`
  justify-content: center;

  width: 100%;
`;

export const RatingText = styled(Text)`
  margin-top: ${({ theme }) => theme.space.smaller};

  color: ${({ theme }) => theme.colors.grayDarkest};
`;

export const Tag = styled(PomodoroTag)<TagProps>`
  margin-right: ${({ theme }) => theme.space.smaller};
  margin-bottom: ${({ theme }) => theme.space.smaller};

  padding-top: ${({ theme }) => theme.space.smallest};
  padding-bottom: ${({ theme }) => theme.space.smallest};

  color: ${({ theme, active }) => (active ? theme.colors.white : theme.colors.grayDarkest)};

  font-weight: 400;

  font-size: ${({ theme }) => theme.fontSizes.tsm};

  background-color: ${({ theme, active }) => (active ? theme.colors.grayDarkest : 'none')};
  border-color: ${({ theme }) => theme.colors.grayDarkest};

  cursor: pointer;

  &:hover {
    background-color: ${({ theme, active }) => (active ? theme.colors.grayDarker : theme.colors.gray)};
  }
`;

export const Logo = styled.div`
  margin-bottom: ${({ theme }) => theme.space.regular};

  background: ${({ theme }) => theme.colors.grayDarkest};
  border-radius: 50%;
  img {
    width: 80px;
    height: 80px;

    border-radius: 50%;
  }
  @media only screen and (max-height: 760px) {
    display: none;
  }
`;
export const CommentWrapper = styled.div`
  width: 90%;
  margin-top: ${({ theme }) => theme.space.small};
`;

export const TextAreaInput = styled(TextArea)`
  width: 100%;

  font-size: ${({ theme }) => theme.fontSizes.tsm};
`;

export const ConfirmButton = styled(Button)`
  width: 100%;
`;
