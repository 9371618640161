import { useOrder } from '../context';
import { useOrdersListQuery } from './useOrdersListQuery';

export const useHasOnGoingOrder = (): boolean => {
  const { orders } = useOrder();

  useOrdersListQuery();

  return orders && orders.hasOnGoingOrders();
};
