import {
  Heading,
  ProgressBar,
  Text,
} from "@whitelabel-webapp/shared/design-system";
import { useCountdown } from "@whitelabel-webapp/shared/hooks";
import { useEffect } from "react";
import Lottie from "react-lottie";

import * as animationData from "./assets/lf20_mpveorjr.json";
import * as S from "./styles";

export type ProcessingOrderProps = {
  onProceed: () => void;
};

export const ProcessingOrder: React.VFC<ProcessingOrderProps> = ({
  onProceed,
}) => {
  const seconds = 5;
  const { countdownSeconds } = useCountdown(seconds);

  const finished = countdownSeconds === 0;

  useEffect(() => {
    if (!finished) return;

    onProceed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finished]);

  return (
    <S.Container>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: animationData,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        height={200}
        width={200}
      />
      <Heading fontWeight={400} variant="small" textAlign="center" as="h1">
        Enviando seu pedido
      </Heading>
      <Text textAlign="center" color="grayDarkest">
        Em alguns segundos, você poderá acompanhar o status do seu pedido.
      </Text>
    </S.Container>
  );
};
