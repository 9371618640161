import { Flex, Button as PomodoroButton, Text } from '@whitelabel-webapp/shared/design-system';
import { Dialog } from '@whitelabel-webapp/shared/design-system';
import styled from 'styled-components';

export const TitleWrapper = styled(Flex)`
  flex-direction: column;
`;

export const Wrapper = styled(Flex)`
  flex-direction: column;
  gap: ${({ theme }) => theme.space.larger};
  align-items: center;
`;

export const Button = styled(PomodoroButton)`
  font-size: ${({ theme }) => theme.fontSizes.tsm};

  @media screen and (max-width: 320px) {
    padding: ${({ theme }) => theme.space.regular};
  }
`;

export const Footer = styled(Flex)`
  gap: ${({ theme }) => theme.space.regular};
  justify-content: space-between;
`;

export const OpinionDescription = styled(Text)`
  text-align: center;
`;

export const DialogHeader = styled(Dialog.Header)`
  align-items: center;
  justify-content: center;
`;
