import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${({ theme }) => theme.space.regular};

  & button {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
  }
`;
