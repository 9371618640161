import { accountAboyeur } from "@whitelabel-webapp/account/shared/config";
import { ecommerceEvents } from "@whitelabel-webapp/shared/ecommerce-events";

import * as S from "./styles";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";
import { groceriesApiBffURL } from "@whitelabel-webapp/shared/config";
import axios from "axios";
import { catalogAboyeur } from "@app/domains/catalog/aboyeur";

export const CatalogSearch = () => {
  const router = useRouter();
  const { merchant } = useMerchant();
  const term = router.query["term"] as string;

  const [query, setQuery] = useState(term);
  const [options, setOptions] = useState([] as string[]);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  function handleSearch(searchString: string) {
    if (searchString === query) return;
    setQuery(searchString);
  }

  function handleSelectOption(searchString: string) {
    if (searchString === query) return;
    setQuery(searchString);
    setShouldRedirect(true);
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") setShouldRedirect(true);
  };

  const searchRedirect = (searchString: string) => {
    if (!searchString) return;
    setOptions([]);

    router
      .push(`/${merchant.query}/busca/${searchString.replaceAll(" ", "+")}`)
      .then(() => {
        catalogAboyeur.events.search.searchCatalogItem(searchString);
        ecommerceEvents.search(searchString);
        setOptions([]);
      });
  };

  useEffect(() => {
    const timeout = setTimeout(async () => {
      if (shouldRedirect && query !== term && query.length > 1) {
        searchRedirect(query);
      }
      setShouldRedirect(false);
    }, 100);
    return () => clearTimeout(timeout);
  }, [shouldRedirect, query]);

  useEffect(() => {
    const timeout = setTimeout(async () => {
      if (query === term || !query) return;
      const options = await getAutoCompleteOptions(query);
      catalogAboyeur.events.search.viewSearchSuggestions();
      setOptions(options);
    }, 500);
    return () => clearTimeout(timeout);
  }, [query]);

  return (
    <S.Wrapper aria-controls="search">
      <S.Search
        id="search"
        name="search"
        aria-label="Pesquisar"
        placeholder={term ? term.replaceAll("+", " ") : "Buscar item ou marca"}
        onSelectOption={handleSelectOption}
        onKeyDown={handleKeyDown}
        onSearch={handleSearch}
        options={options}
        allowClear
      />
    </S.Wrapper>
  );
};

type AutocompleteOption = {
  displayLabel: string;
  predictionLabel: string;
};

const getAutoCompleteOptions = async (term: string) => {
  const bffClient = axios.create({
    baseURL: groceriesApiBffURL,
    headers: {
      experiment_variant: "default_groceries",
      experiment_details: JSON.stringify({
        default_groceries: {
          autocomplete_model_id: "search-autocomplete-groceries-endpoint",
        },
      }),
    },
  });

  const autocompletePromise = bffClient.get<AutocompleteOption[]>(
    `/search/suggestions`,
    {
      params: {
        latitude: "-9.818888",
        longitude: "-67.946676",
        channel: "IFOOD",
        size: 6,
        term,
      },
    },
  );

  try {
    const autocompleteOptions = await autocompletePromise;
    return autocompleteOptions.data.map((option) => option.displayLabel);
  } catch (e) {
    return [];
  }
};
