import { Item, ItemResponse } from "./item";

export type TemplateType = "pizza" | "combo";

export type CategoryResponse = {
  code: string;
  name: string;
  order: number;
  itens: ItemResponse[];
  logoUrl?: string;
  template?: TemplateType;
};

export class Category {
  static fromApi(rawCategory: CategoryResponse) {
    const items = rawCategory.itens.map((item) =>
      Item.fromApi(item, rawCategory.name, rawCategory.code),
    );
    return new Category(
      rawCategory.code,
      rawCategory.name,
      rawCategory.order,
      items,
      rawCategory.logoUrl,
      rawCategory.template,
    );
  }

  constructor(
    public code: string,
    public name: string,
    public order: number,
    public items: Item[],
    public logoUrl?: string,
    public template?: TemplateType,
  ) {}

  hasPizzaTemplate() {
    return this.template === "pizza";
  }

  isVisible() {
    if (!this.items.length) {
      return false;
    }

    return true;
  }
}
