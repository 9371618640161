import { Flex, PinOutlined } from "@whitelabel-webapp/shared/design-system";
import { EmptyProps } from "@whitelabel-webapp/shared/types";
import dynamic from "next/dynamic";
import { useState } from "react";

import { useCheckout } from "@app/domains/checkout";

import * as S from "./styles";

const IndoorAddressComplement = dynamic<EmptyProps>(
  import("./IndoorAddressComplement").then(
    ({ IndoorAddressComplement }) => IndoorAddressComplement,
  ),
);

export const IndoorLocation: React.VFC = () => {
  const { order } = useCheckout();

  const [isIndoorAddressComplementOpen, setIsIndoorAddressComplementOpen] =
    useState(false);

  function handleChooseIndoorAddressComplement() {
    setIsIndoorAddressComplementOpen(true);
  }

  const hasIndoorAddressComplement = Boolean(
    order.address && order.address.complement,
  );

  return (
    <>
      <S.Wrapper>
        <S.Container>
          <Flex centered>
            <S.Icon
              component={PinOutlined}
              size="m"
              height="28"
              width="20"
              mr={10}
            />
            {!hasIndoorAddressComplement && <S.Text>Local de entrega</S.Text>}
            {hasIndoorAddressComplement && (
              <Flex flexDirection="column">
                <S.Text>{order.address.complement}</S.Text>
                <S.Description>Em {order.deliveryTime} min</S.Description>
              </Flex>
            )}
          </Flex>
          <Flex>
            <S.ChooseAddress onClick={handleChooseIndoorAddressComplement}>
              {!hasIndoorAddressComplement && "Selecionar"}
              {hasIndoorAddressComplement && "Trocar"}
            </S.ChooseAddress>
          </Flex>
        </S.Container>
      </S.Wrapper>
      <IndoorAddressComplement
        isOpen={isIndoorAddressComplementOpen}
        onClose={() => setIsIndoorAddressComplementOpen(false)}
      />
    </>
  );
};
