import { Money } from "@whitelabel-webapp/shared/models";

import { Item, ItemResponse } from "./item";
import { PriceResponse, PricesResponse } from "./order";

export type BagResponse = {
  items: ItemResponse[];
  prices: PricesResponse;
};

export class Bag {
  static fromApi(response: BagResponse, deliveryFee: PriceResponse): Bag {
    const deliveryFeeMoney = new Money(deliveryFee.value / 100);
    return new Bag(
      response.items.map((item) => Item.fromApi(item)),
      new Money(response.prices.netValue.value / 100).add(deliveryFeeMoney),
      new Money(response.prices.discount.value / 100),
      new Money(response.prices.grossValue.value / 100),
      deliveryFeeMoney,
    );
  }

  constructor(
    public items: Item[],
    public total: Money,
    public discount: Money,
    public subTotal: Money,
    public deliveryFee: Money,
  ) {}

  getItems(): Item[] {
    return this.items;
  }
}
