import { OrderStatus, OrderStatuses } from '.';

export const STATUS_IN_PROGRESS = {
  PLACED: 'PLACED',
  GOING_TO_ORIGIN: 'GOING_TO_ORIGIN',
  GOING_TO_DESTINATION: 'GOING_TO_DESTINATION',
  COLLECTED: 'COLLECTED',
  DISPATCHED: 'DISPATCHED',
  ARRIVED: 'ARRIVED',
  READY_FOR_PICKUP: 'READY_FOR_PICKUP',
  CONFIRMED: 'CONFIRMED',
  HANDSHAKE_CODE_VALUE: 'HANDSHAKE_CODE_VALUE',
} as const;

export const STATUS_IN_PROGRESS_OR_CONCLUDED = {
  ...STATUS_IN_PROGRESS,
  CONCLUDED: 'CONCLUDED',
} as const;

const STATUS_ONLINE_PAYMENT = {
  CREATED: 'CREATED',
  DECLINED: 'DECLINED',
  PAYMENT_DECLINED: 'PAYMENT_DECLINED',
} as const;

export const STATUS = {
  ...STATUS_ONLINE_PAYMENT,
  ...STATUS_IN_PROGRESS_OR_CONCLUDED,
  CANCELLED: 'CANCELLED',
} as const;

export const ORDER_STATUS_LABELS: Record<OrderStatus, string> = {
  CREATED: 'Aguardando pagamento',
  DECLINED: 'Pagamento não aprovado',
  PAYMENT_DECLINED: 'Pagamento não aprovado',
  PLACED: 'Pedido recebido',
  CONFIRMED: 'Preparo iniciado',
  CANCELLED: 'Cancelado',
  ARRIVED: 'Chegou no seu endereço',
  READY_FOR_PICKUP: '',
  DISPATCHED: '',
  COLLECTED: '',
  CONCLUDED: '',
  GOING_TO_ORIGIN: '',
  GOING_TO_DESTINATION: '',
  HANDSHAKE_CODE_VALUE: '',
};

export const DELIVERY_ORDER_STATUS_LABELS: Record<OrderStatus, string> = {
  ...ORDER_STATUS_LABELS,
  DISPATCHED: 'Saiu para entrega',
  COLLECTED: 'Pedido coletado',
  GOING_TO_ORIGIN: 'Entregador está a caminho do restaurante',
  GOING_TO_DESTINATION: 'Saiu para entrega',
  CONCLUDED: 'Pedido entregue',
};

export const ONLINE_DELIVERY_ORDER_STATUS_LABELS: Record<OrderStatus, string> = {
  ...DELIVERY_ORDER_STATUS_LABELS,
  PLACED: 'Pagamento aprovado',
};

export const TAKEOUT_ORDER_STATUS_LABELS: Record<OrderStatus, string> = {
  ...ORDER_STATUS_LABELS,
  READY_FOR_PICKUP: 'Pedido pronto para retirada',
};

export const ONLINE_TAKEOUT_ORDER_STATUS_LABELS: Record<OrderStatus, string> = {
  ...TAKEOUT_ORDER_STATUS_LABELS,
  PLACED: 'Pagamento aprovado',
};

export const orderInProgressSorter = {
  [STATUS.CREATED]: 1,
  [STATUS.DECLINED]: 2,
  [STATUS_IN_PROGRESS_OR_CONCLUDED.PLACED]: 3,
  [STATUS_IN_PROGRESS_OR_CONCLUDED.CONFIRMED]: 4,
  [STATUS_IN_PROGRESS_OR_CONCLUDED.GOING_TO_ORIGIN]: 5,
  [STATUS_IN_PROGRESS_OR_CONCLUDED.GOING_TO_DESTINATION]: 6,
  [STATUS_IN_PROGRESS_OR_CONCLUDED.DISPATCHED]: 7,
  [STATUS_IN_PROGRESS_OR_CONCLUDED.ARRIVED]: 8,
  [STATUS.CONCLUDED]: 9,
  [STATUS.READY_FOR_PICKUP]: 10,
};

export const initialDeliveryOrderStatuses: OrderStatuses = {
  [STATUS.CREATED]: {
    value: STATUS.CREATED,
    label: DELIVERY_ORDER_STATUS_LABELS.CREATED,
    updatedAt: '',
    isCurrentStatus: false,
  },
  [STATUS.DECLINED]: {
    value: STATUS.DECLINED,
    label: DELIVERY_ORDER_STATUS_LABELS.DECLINED,
    updatedAt: '',
    isCurrentStatus: false,
  },
  [STATUS.PLACED]: {
    value: STATUS.PLACED,
    label: DELIVERY_ORDER_STATUS_LABELS.PLACED,
    updatedAt: '',
    isCurrentStatus: false,
  },
  [STATUS.CONFIRMED]: {
    value: STATUS.CONFIRMED,
    label: DELIVERY_ORDER_STATUS_LABELS.CONFIRMED,
    updatedAt: '',
    isCurrentStatus: false,
  },
  [STATUS.COLLECTED]: {
    value: STATUS.COLLECTED,
    label: DELIVERY_ORDER_STATUS_LABELS.COLLECTED,
    updatedAt: '',
    isCurrentStatus: false,
  },
  [STATUS.DISPATCHED]: {
    value: STATUS.DISPATCHED,
    label: DELIVERY_ORDER_STATUS_LABELS.DISPATCHED,
    updatedAt: '',
    isCurrentStatus: false,
  },
  [STATUS.GOING_TO_ORIGIN]: {
    value: STATUS.GOING_TO_ORIGIN,
    label: DELIVERY_ORDER_STATUS_LABELS.GOING_TO_ORIGIN,
    updatedAt: '',
    isCurrentStatus: false,
  },
  [STATUS.GOING_TO_DESTINATION]: {
    value: STATUS.GOING_TO_DESTINATION,
    label: DELIVERY_ORDER_STATUS_LABELS.GOING_TO_DESTINATION,
    updatedAt: '',
    isCurrentStatus: false,
  },
  [STATUS.ARRIVED]: {
    value: STATUS.ARRIVED,
    label: DELIVERY_ORDER_STATUS_LABELS.DISPATCHED,
    updatedAt: '',
    isCurrentStatus: false,
  },
  [STATUS.CONCLUDED]: {
    value: STATUS.CONCLUDED,
    label: DELIVERY_ORDER_STATUS_LABELS.CONCLUDED,
    updatedAt: '',
    isCurrentStatus: false,
  },
  [STATUS.CANCELLED]: {
    value: STATUS.CANCELLED,
    label: DELIVERY_ORDER_STATUS_LABELS.CANCELLED,
    updatedAt: '',
    isCurrentStatus: false,
  },
  [STATUS.PAYMENT_DECLINED]: {
    value: STATUS.PAYMENT_DECLINED,
    label: DELIVERY_ORDER_STATUS_LABELS.PAYMENT_DECLINED,
    updatedAt: '',
    isCurrentStatus: false,
  },
  [STATUS.READY_FOR_PICKUP]: {
    value: '',
    label: '',
    updatedAt: '',
    isCurrentStatus: false,
  },
  HANDSHAKE_CODE_VALUE: {
    value: '',
    label: '',
    updatedAt: '',
    metadata: undefined,
    isCurrentStatus: false,
  },
};

export const initialTakeoutOrderStatuses: OrderStatuses = {
  [STATUS.CREATED]: {
    value: STATUS.CREATED,
    label: TAKEOUT_ORDER_STATUS_LABELS.CREATED,
    updatedAt: '',
    isCurrentStatus: false,
  },
  [STATUS.DECLINED]: {
    value: STATUS.DECLINED,
    label: TAKEOUT_ORDER_STATUS_LABELS.DECLINED,
    updatedAt: '',
    isCurrentStatus: false,
  },
  [STATUS.PAYMENT_DECLINED]: {
    value: STATUS.PAYMENT_DECLINED,
    label: DELIVERY_ORDER_STATUS_LABELS.PAYMENT_DECLINED,
    updatedAt: '',
    isCurrentStatus: false,
  },
  [STATUS.PLACED]: {
    value: STATUS.PLACED,
    label: TAKEOUT_ORDER_STATUS_LABELS.PLACED,
    updatedAt: '',
    isCurrentStatus: false,
  },
  [STATUS.CONFIRMED]: {
    value: STATUS.CONFIRMED,
    label: TAKEOUT_ORDER_STATUS_LABELS.CONFIRMED,
    updatedAt: '',
    isCurrentStatus: false,
  },
  [STATUS.COLLECTED]: {
    value: STATUS.COLLECTED,
    label: DELIVERY_ORDER_STATUS_LABELS.COLLECTED,
    updatedAt: '',
    isCurrentStatus: false,
  },
  [STATUS.DISPATCHED]: {
    value: '',
    label: '',
    updatedAt: '',
    isCurrentStatus: false,
  },
  [STATUS.GOING_TO_ORIGIN]: {
    value: STATUS.GOING_TO_ORIGIN,
    label: '',
    updatedAt: '',
    isCurrentStatus: false,
  },
  [STATUS.GOING_TO_DESTINATION]: {
    value: STATUS.GOING_TO_DESTINATION,
    label: '',
    updatedAt: '',
    isCurrentStatus: false,
  },
  [STATUS.ARRIVED]: {
    value: '',
    label: '',
    updatedAt: '',
    isCurrentStatus: false,
  },
  [STATUS.CONCLUDED]: {
    value: STATUS.CONCLUDED,
    label: TAKEOUT_ORDER_STATUS_LABELS.CONCLUDED,
    updatedAt: '',
    isCurrentStatus: false,
  },
  [STATUS.READY_FOR_PICKUP]: {
    value: STATUS.READY_FOR_PICKUP,
    label: TAKEOUT_ORDER_STATUS_LABELS.READY_FOR_PICKUP,
    updatedAt: '',
    isCurrentStatus: false,
  },
  [STATUS.CANCELLED]: {
    value: STATUS.CANCELLED,
    label: TAKEOUT_ORDER_STATUS_LABELS.CANCELLED,
    updatedAt: '',
    isCurrentStatus: false,
  },
  HANDSHAKE_CODE_VALUE: {
    value: '',
    label: '',
    updatedAt: '',
    metadata: undefined,
    isCurrentStatus: false,
  },
};
