import { Flex } from "@whitelabel-webapp/shared/design-system";
import { Text } from "@whitelabel-webapp/shared/design-system";
import styled from "styled-components";

export const OrderInfoContainer = styled(Flex)`
  flex-direction: column;
`;

export const Wrapper = styled.div`
  padding: 0;
`;

export const PaymentContainer = styled(Flex)`
  flex-direction: column;
`;

type OrderTitleProps = {
  isCancelled: boolean;
};

export const OrderTitle = styled(Text)<OrderTitleProps>`
  color: ${({ isCancelled, theme }) =>
    isCancelled ? theme.colors.grayDarker : theme.colors.black};
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes.xs};
`;

export const PriceContainer = styled(Flex)`
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.space.smaller};
`;

type TotalPriceTextProps = {
  isCancelled: boolean;
};

export const TotalPriceText = styled(Text)<TotalPriceTextProps>`
  color: ${({ isCancelled, theme }) =>
    isCancelled ? theme.colors.grayDarker : theme.colors.black};
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes.s};
`;

export const PaymentType = styled(Flex)`
  justify-content: space-between;
`;

export const PaymentCardText = styled(Text)`
  color: ${({ theme }) => theme.colors.grayDarker};
  font-size: ${({ theme }) => theme.fontSizes.xs};
`;

export const OrderItemsContainer = styled(Flex)`
  justify-content: space-between;
`;
