/* eslint-disable max-lines */
import { useCatalog } from "@whitelabel-webapp/catalog/shared/catalog-store";
import { ItemDetails } from "../itemDetails";
import { byOrder } from "@app/domains/catalog/utils";
import * as S from "./styles";
import Link from "next/link";
import { useRouter } from "next/router";
import { Flex, Icon } from "@ifood/pomodoro-components";
import { Bag, ChevronLeft } from "@ifood/pomodoro-icons";
import { useCheckout } from "@app/domains/checkout";
import { Header } from "@app/domains/catalog";
import { Carousel } from "@whitelabel-webapp/shared/design-system";
import { SmallItem } from "../SmallItem";
import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";

export const ItemPage: React.VFC = () => {
  const { setCheckoutDetailsStatus } = useCheckout();
  const { catalog } = useCatalog();
  const { merchant } = useMerchant();
  const router = useRouter();

  const category = catalog.categories.at(0);

  const item = category?.items.find(
    (item) => item.code == router.query.itemCode,
  );

  const relatedItems = category?.items.filter(
    (categoryItem) => categoryItem.id !== item.id,
  );

  function handleOpenBag() {
    setCheckoutDetailsStatus("OPEN");
  }

  if (!item) return null;

  return (
    <S.Container>
      <S.TopGrid>
        <S.BreadcrumbDesktop>
          <Link href={`/${merchant.query}`}>Home</Link>
          <Flex>{">"}</Flex>
          <Link href={`/${merchant.query}/categoria/${category.code}`}>
            {category.name}
          </Link>
          <Flex>{">"}</Flex>
          <Link href={router.asPath}>{item.description}</Link>
        </S.BreadcrumbDesktop>

        <S.BreadcrumbMobile>
          <Icon
            size="s"
            component={ChevronLeft}
            onClick={() => router.back()}
          />
          <S.BagButton onClick={handleOpenBag}>
            <Icon width="16px" height="16px" component={Bag} />
          </S.BagButton>
        </S.BreadcrumbMobile>
      </S.TopGrid>

      <ItemDetails item={item}></ItemDetails>

      {Boolean(relatedItems.length) && (
        <S.Carousel>
          <Header
            mb={"48px"}
            name={"Peça também"}
            subtitle={"Itens relacionados"}
            route={`/${merchant.query}/categoria/${category.code}`}
          />
          <Carousel gap="clamp(15px, 2.5dvw, 48px)" simultaneousItems={5}>
            {relatedItems.sort(byOrder).map((item) => {
              return <SmallItem key={item.id} item={item} />;
            })}
          </Carousel>
        </S.Carousel>
      )}
    </S.Container>
  );
};
