import { Heading, Text } from '@whitelabel-webapp/shared/design-system';
import NextImage from 'next/image';

import * as S from './styles';

export const NoOrdersFound: React.VFC = () => {
  return (
    <S.Container>
      <NextImage src="/images/order/empty_state.png" alt="" width={180} height={160} layout="fixed" />
      <Heading as="h2" textAlign="center" variant="small" mt={40}>
        Bora pedir?
      </Heading>
      <Text textAlign="center">Acompanhe os pedidos que fizer em nossa loja por aqui.</Text>
    </S.Container>
  );
};
