import { useMerchant } from '@whitelabel-webapp/merchant/shared/merchant-store';
import { useMemo } from 'react';
import createPersistedState from 'use-persisted-state';

const HAS_RATED_ORDER = 'has_rated_order';

export function useHasRatedOrder(orderId?: string): [boolean, (ratedOrder: boolean) => void] {
  const { merchant } = useMerchant();
  const STATE_KEY = useMemo(
    () => (orderId ? `${merchant.id}.${HAS_RATED_ORDER}.${orderId}` : `${merchant.id}.${HAS_RATED_ORDER}`),
    [merchant.id, orderId]
  );

  const useLocalHasRatedOrder = useMemo(() => createPersistedState<boolean>(STATE_KEY), [STATE_KEY]);

  const [hasRatedOrder, setHasRatedOrder] = useLocalHasRatedOrder();

  return [hasRatedOrder, setHasRatedOrder];
}
