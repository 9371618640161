import { useAuthentication } from '@whitelabel-webapp/authentication/shared/authentication-store';
import { Heading, Text } from '@whitelabel-webapp/shared/design-system';
import NextImage from 'next/image';

import * as S from './styles';
import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";
import { useRouter } from "next/router";

export const NoUserLoggedFound: React.VFC = () => {
  const { openIfoodAuthentication } = useAuthentication();
  const { merchant } = useMerchant();
  const router = useRouter();

  const redirectToHomeMerchant = () => {
    router.push(`/${merchant.query}`);
  };

  const handleAuthentication = () => {
    return openIfoodAuthentication("EMAIL", "order", {
      callback: redirectToHomeMerchant,
    });
  };

  return (
    <S.Container>
      <NextImage
        src="/images/order/empty_state.png"
        alt=""
        width={180}
        height={160}
        layout="fixed"
      />
      <Heading as="h2" textAlign="center" variant="small" mt={40}>
        Bora pedir?
      </Heading>
      <Text textAlign="center">
        Entre ou cadastre-se para aproveitar ao máximo nosso cardápio
      </Text>
      <S.Button variant="primary" onClick={handleAuthentication}>
        Entrar ou cadastrar-se
      </S.Button>
    </S.Container>
  );
};
