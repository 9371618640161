import * as S from "./styles";
import { List } from "../shared/List";
import { PriceSummary } from "../shared/PriceSummary";
import { MerchantInfo } from "./components/MerchantInfo";
import { useIsMobile } from "@whitelabel-webapp/shared/hooks";
import { Order as ChecekoutOrder } from "@whitelabel-webapp/checkout/shared/models";
import { NoItemsAdded } from "./components/NoItemsAdded";
import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";
import { useCheckout } from "@app/domains/checkout";

type BagProps = {
  handleFinalizeOrderClick: () => void;
};

export const Bag = ({ handleFinalizeOrderClick }: BagProps) => {
  const isMobile = useIsMobile();
  const { merchant } = useMerchant();
  const { order, removeAllItems } = useCheckout();

  const isMissingValue =
    order.totalItems.getValue() < merchant.minimumOrderValue.getValue();

  function handleClickClearBag() {
    removeAllItems();
  }

  if (order.itemsList.length === 0) {
    return <NoItemsAdded />;
  }

  return (
    <S.Container>
      <MerchantInfo />
      <S.Divider />
      <List />
      <S.DrawerFooter>
        <PriceSummary />
        <S.CheckoutButton
          onClick={handleFinalizeOrderClick}
          disabled={isMissingValue}
        >
          Finalizar pedido
        </S.CheckoutButton>
        <S.ButtonClearCheckout
          onClick={handleClickClearBag}
          aria-label="Limpar"
        >
          {isMobile ? "Limpar" : "Esvaziar Sacola"}
        </S.ButtonClearCheckout>
      </S.DrawerFooter>
    </S.Container>
  );
};
