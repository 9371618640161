import {
  Flex,
  Button as PomodoroButton,
} from "@whitelabel-webapp/shared/design-system";
import styled from "styled-components";

export const Wrapper = styled(Flex)`
  height: 100%;
  align-items: center;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.regular};

  & button {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
  }
`;

export const BottomButtons = styled(Flex)`
  gap: ${({ theme }) => theme.space.regular};
  justify-content: space-between;
  margin-top: auto;
  width: 100%;

  button {
    font-weight: 500;
  }
`;
