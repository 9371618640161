import { Heading, Text } from "@whitelabel-webapp/shared/design-system";
import styled from "styled-components";
import { DefaultTheme } from "styled-components";
import { SpaceProps, space } from "styled-system";

const belowDesktop = ({ theme }: { theme: DefaultTheme }) =>
  `max-width: ${theme.screens.m.max}`;

export const Container = styled.div<SpaceProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.space.large};

  @media (${belowDesktop}) {
    margin-left: ${({ theme }) => theme.space.regular};
    margin-right: ${({ theme }) => theme.space.regular};
    margin-bottom: ${({ theme }) => theme.space.regular};
  }
`;

export const Title = styled(Heading).attrs({
  forwardedAs: "h2",
})`
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
  margin-bottom: ${({ theme }) => theme.space.smallest};
  margin-top: ${({ theme }) => theme.space.none};
  font-size: ${({ theme }) => theme.fontSizes.l};
  line-height: ${({ theme }) => theme.fontSizes.l};

  @media (${belowDesktop}) {
    font-size: ${({ theme }) => theme.fontSizes.s};
    line-height: ${({ theme }) => theme.fontSizes.m};
    margin-bottom: ${({ theme }) => theme.space.none};
    font-weight: 500;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const Subtitle = styled(Text)`
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSizes.m};
  line-height: ${({ theme }) => theme.fontSizes.m};
  color: ${({ theme }) => theme.colors.grayDarker};

  @media (${belowDesktop}) {
    font-size: ${({ theme }) => theme.fontSizes.s};
    line-height: ${({ theme }) => theme.fontSizes.s};
  }
`;

export const ViewMore = styled(Text).attrs({
  forwardedAs: "button",
})`
  padding: 0;
  border: none;
  cursor: pointer;
  background: none;
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSizes.m};
  line-height: ${({ theme }) => theme.fontSizes.m};
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: ${({ theme }) => theme.space.none};
  font-size: ${({ theme }) => theme.fontSizes.s};

  @media (${belowDesktop}) {
    font-size: ${({ theme }) => theme.fontSizes.tsm};
    line-height: ${({ theme }) => theme.fontSizes.s};
  }
`;
