import { ChatFilled } from "@ifood/pomodoro-icons";
import { TextArea } from "@whitelabel-webapp/shared/design-system";
import { Text } from "@whitelabel-webapp/shared/design-system";
import { ChangeEvent, useEffect, useState } from "react";

import * as S from "./styles";

export const MAX_OBSERVATION_LENGTH = 140;

type ObservationProps = {
  value?: string;
  onChange: (evt: ChangeEvent<HTMLTextAreaElement>) => void;
};

export const Observation: React.VFC<ObservationProps> = ({
  value,
  onChange,
}) => {
  const [input, setInput] = useState(() => value || "");

  useEffect(() => {
    setInput(value || "");
  }, [value]);

  function handleChange(e: ChangeEvent<HTMLTextAreaElement>) {
    if (e.target.value.length <= MAX_OBSERVATION_LENGTH) {
      setInput(e.target.value);
      onChange?.(e);
    }
  }

  const length = input.length;

  return (
    <S.Container>
      <S.LabelContainer>
        <Text id="notes-label" fontWeight="500">
          Alguma observação?
        </Text>
        <S.LabelCounter>
          {length}/{MAX_OBSERVATION_LENGTH}
        </S.LabelCounter>
      </S.LabelContainer>
      <TextArea
        aria-labelledby="notes-label"
        autoComplete="off"
        id="notes"
        rows={1}
        name="notes"
        onChange={handleChange}
        value={input}
      />
    </S.Container>
  );
};
