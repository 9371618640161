import { Loading } from "@whitelabel-webapp/shared/design-system";
import React from "react";

import { DialogContent } from "../../Content";
import * as S from "./styles";

export const LoadingPaymentMethods = () => {
  return (
    <>
      <S.Title>Carregando</S.Title>

      <DialogContent>
        <S.Container>
          <Loading variant="small" color="primary">
            Carregando
          </Loading>
        </S.Container>
      </DialogContent>
    </>
  );
};
