import { CardOutlined, DeliveryPersonFilled } from "@ifood/pomodoro-icons";
import { checkoutAboyeur } from "@whitelabel-webapp/checkout/shared/config";
import { Icon } from "@whitelabel-webapp/shared/design-system";
import NextImage from "next/image";

import { getBrandIconPath, useCheckout } from "@app/domains/checkout";
import PaymentTypeButton from "./PaymentTypeButton";
import Pix from "./Pix";
import * as S from "./styles";
import { Flex } from "@ifood/pomodoro-components";
import { useMemo } from "react";
import { useIsMobile } from "@whitelabel-webapp/shared/hooks";

type SelectPaymentTypeProps = {
  onBack: () => void;
  onOfflinePayment: () => void;
  onOnlineCardsPayment: () => void;
  onPixPayment: () => void;
  hasOfflinePayment: boolean;
  hasPixPayment: boolean;
  hasOnlineCardsPayment: boolean;
};

export const SelectPaymentType: React.VFC<SelectPaymentTypeProps> = ({
  onBack,
  onOfflinePayment,
  onOnlineCardsPayment,
  onPixPayment,
  hasOfflinePayment,
  hasPixPayment,
  hasOnlineCardsPayment,
}) => {
  const {
    order,
    order: { paymentMethod, cardToken },
    setPaymentMethod,
  } = useCheckout();
  const isMobile = useIsMobile();

  function handleOfflinePayment() {
    if (isOfflineSelected) return setPaymentMethod(undefined);
    checkoutAboyeur.events.payment.chooseDeliveryPayment();
    onOfflinePayment();
  }
  function handlePixPayment() {
    if (isPixSelected) return setPaymentMethod(undefined);
    checkoutAboyeur.events.payment.choosePixPayment();
    onPixPayment();
  }
  function handleOnlinePayment() {
    if (isOnlineSelected) return setPaymentMethod(undefined);
    checkoutAboyeur.events.payment.chooseCatalogPayment();
    onOnlineCardsPayment();
  }

  const isOfflineSelected = paymentMethod?.isOffline();
  const isPixSelected = paymentMethod?.isPix();
  const isOnlineSelected = paymentMethod?.isOnline() && !isPixSelected;

  const brandImageURL = useMemo(() => {
    if (paymentMethod) {
      return getBrandIconPath(
        paymentMethod.brand.name,
        paymentMethod.method.description,
      );
    } else {
      return "";
    }
  }, [paymentMethod]);

  const paymentName = useMemo(() => {
    if (paymentMethod) {
      return paymentMethod.method.description;
    } else {
      return "Pagamento não selecionado";
    }
  }, [paymentMethod]);

  const PaymentImage = () => {
    return (
      <NextImage
        height={18}
        width={22}
        src={brandImageURL}
        alt={paymentMethod.method.description}
      />
    );
  };

  return (
    <>
      <Flex flexDirection="column" height="100%">
        <S.Wrapper>
          {hasPixPayment && (
            <PaymentTypeButton
              onClick={handlePixPayment}
              aria-label="Pix"
              isActive={isPixSelected}
            >
              <Icon component={Pix} height="18" width="22" />
              <S.Container>
                PIX
                <S.PaymentTypeDescripton>
                  O código gerado vale por 7 minutos
                </S.PaymentTypeDescripton>
              </S.Container>
            </PaymentTypeButton>
          )}
          {hasOnlineCardsPayment && (
            <PaymentTypeButton
              onClick={handleOnlinePayment}
              aria-label="Pagar pelo site"
              isActive={isOnlineSelected}
            >
              {isOnlineSelected ? (
                <PaymentImage />
              ) : (
                <Icon component={CardOutlined} height="22" width="22" />
              )}
              <S.Container>
                {isOnlineSelected ? paymentName : "Cartão de crédito ou débito"}
                <S.PaymentTypeDescripton>
                  {isOnlineSelected && cardToken
                    ? `${paymentMethod.brand.description} •••• ${cardToken.last_four_digits}`
                    : "Pague pelo site"}
                </S.PaymentTypeDescripton>
              </S.Container>
            </PaymentTypeButton>
          )}
          {!order.isTakeoutDeliveryMethod() && hasOfflinePayment && (
            <PaymentTypeButton
              onClick={handleOfflinePayment}
              aria-label="Pagar na entrega"
              isActive={isOfflineSelected}
            >
              {isOfflineSelected ? (
                <PaymentImage />
              ) : (
                <Icon component={DeliveryPersonFilled} height="22" width="22" />
              )}
              <S.Container>
                {isOfflineSelected ? paymentName : "Pagar na entrega"}
                <S.PaymentTypeDescripton>
                  Pague na entrega
                </S.PaymentTypeDescripton>
              </S.Container>
            </PaymentTypeButton>
          )}
          {isMobile && <S.BackButton onClick={onBack}>Voltar</S.BackButton>}
        </S.Wrapper>
      </Flex>
    </>
  );
};
