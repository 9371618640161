import { FAQ } from '@whitelabel-webapp/merchant/shared/components';
import { Merchant } from '@whitelabel-webapp/merchant/shared/models';
import { Z_INDEXES } from '@whitelabel-webapp/shared/constants';
import { Phone } from '@whitelabel-webapp/shared/design-system';
import { useIsMobile } from '@whitelabel-webapp/shared/hooks';
import SVG from 'react-inlinesvg';

import { orderAboyeur } from '@app/domains/order/aboyeur';

import { Desktop } from './Desktop';
import { Mobile } from './Mobile';
import * as S from './styles';

type HelpProps = {
  open: boolean;
  onClose: () => void;
  merchant: Merchant;
};

export const Help: React.FC<HelpProps> = ({ open, onClose, merchant }) => {
  const isMobile = useIsMobile();

  function handleWhatsapp() {
    orderAboyeur.events.help.whatsapp(merchant.id);
  }

  function handlePhone() {
    orderAboyeur.events.help.call(merchant.id);
  }

  const DialogToRender = isMobile ? Mobile : Desktop;

  const hasWhatsappSupport = Boolean(merchant.flags?.['hasWhatsappSupport']);

  return (
    <DialogToRender
      open={open}
      onClose={onClose}
      handleClose={onClose}
      title={
        <S.Title>
          <b>Tem alguma dúvida ou problema?</b>
          <S.Text fontSize={18}>Entre em contato conosco.</S.Text>
        </S.Title>
      }
      data-testid="order-tracking-help-drawer"
      zIndex={Z_INDEXES.HELP_DIALOG}
    >
      <S.Wrapper>
        <S.SupportedContacts>
          {hasWhatsappSupport ? (
            <S.Contact>
              <SVG width={24} height={24} src="/icons/whatsapp.svg" />
              <S.ContactLink
                onClick={handleWhatsapp}
                href={`https://wa.me/55${merchant.phone}`}
              >
                Whatsapp
              </S.ContactLink>
            </S.Contact>
          ) : (
            <S.Contact>
              <S.ContactIcon
                width="24px"
                height="24px"
                component={Phone}
                size="s"
              />
              <S.ContactLink
                onClick={handlePhone}
                href={`tel:${merchant.phone}`}
              >
                Ligar
              </S.ContactLink>
            </S.Contact>
          )}
        </S.SupportedContacts>
        <FAQ merchant={merchant} />
      </S.Wrapper>
    </DialogToRender>
  );
};
