import { isToday } from "date-fns";

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

export function dateDiffInDays(a: Date, b: Date): number {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export function dateStringToDayName(dateString: string) {
  if (!dateString) return dateString;
  const daysOfWeek = [
    "Domingo",
    "Segunda",
    "Terça",
    "Quarta",
    "Quinta",
    "Sexta",
    "Sábado",
  ];
  const today = new Date();
  const date = new Date(dateString.replace(/-/g, "/"));
  const dayIndex = date.getDay();

  if (isToday(date)) {
    return "Hoje";
  } else if (
    date.toDateString() ===
    new Date(today.setDate(today.getDate() + 1)).toDateString()
  ) {
    return "Amanhã";
  }

  return daysOfWeek[dayIndex];
}
