import { Icon as PomodoroIcon, Text as PomodoroText, TextLink } from '@whitelabel-webapp/shared/design-system';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space.regular};

  margin-bottom: ${({ theme }) => theme.space.regular};
  margin-left: ${({ theme }) => theme.space.regular};
  margin-right: ${({ theme }) => theme.space.regular};

  padding-top: ${({ theme }) => theme.space.small};
  padding-right: ${({ theme }) => theme.space.regular};
  padding-bottom: ${({ theme }) => theme.space.small};
  padding-left: ${({ theme }) => theme.space.regular};

  border: solid 1px ${({ theme }) => theme.colors.grayLight};
  border-radius: ${({ theme }) => theme.radii.m};

  background-color: ${({ theme }) => theme.colors.white};
`;

export const Icon = styled(PomodoroIcon)`
  color: ${({ theme }) => theme.colors.grayDarkest};
`;

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  gap: ${({ theme }) => theme.space.regular};
  align-items: center;
  justify-content: space-between;
`;

export const ChooseAddress = styled(TextLink)`
  font-size: ${({ theme }) => theme.fontSizes.tsm};

  cursor: pointer;
`;

export const Text = styled(PomodoroText)`
  margin: ${({ theme }) => theme.space.none};

  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fontSizes.tsm};
`;

export const Description = styled(PomodoroText)`
  color: ${({ theme }) => theme.colors.grayDarkest};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: ${({ theme }) => theme.lineHeights.s};
`;
