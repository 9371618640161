import { imageServiceURL } from '@whitelabel-webapp/shared/config';
import { ImageLoaderProps } from 'next/dist/client/image';

export enum Folders {
  MERCHANT_COVER = 'capa',
  MERCHANT_LOGO = 'logosgde',
  DISH = 'pratos',
  RESTAURANT_LOGO = 'logosgde',
  MARKET_AISLE_LOGO = 'mercados/categorias',
  PAYMENT_ICON = 'icones/payments/brands',
  DISCOVERIES = 'discoveries',
  WEBAPP = 'webapp',
  WEBAPP_CATEGORIES = 'webapp/categories',
  WEBAPP_LOOP = 'webapp/loop',
  WEBAPP_LANDING = 'webapp/landing',
  WEBAPP_GROUP = 'webapp/group-pages',
  WEBAPP_ASSETS = 'webapp/assets',
  WEBAPP_JOBS = 'webapp/jobs',
  WEBAPP_DISHES = 'webapp/dishes',
  WEBAPP_CAREERS = 'webapp/carrers',
  WEBAPP_TESTIMONIALS = 'webapp/carrers/testimonials',
  WEBAPP_CHALLENGES = 'webapp/carrers/challenges',
  WEBAPP_HOME = 'webapp/home',
}

export enum Sizes {
  THUMBNAIL = 't_thumbnail',
  LOW = 't_low',
  MEDIUM = 't_medium',
  HIGH = 't_high',
  COVER = '',
  BANNER = 't_banner',
  AUTO = 'f_auto',
}

export enum Quality {
  HIGH = 'q_100',
}

export const IMAGE_COVER_THUMB_REGEX = /\.(jpe?g|png)/gi;

export const DEFAULT_MERCHANT_COVER = `/images/merchant/cover.png`;

export const getImageURL = (folder: Folders, fileName: string, size: Sizes, quality?: Quality) => {
  const formatedQuality = quality ? `,${quality}` : '';
  const formatedSize = size == null ? '' : size;

  const optimizations = `${formatedSize}${formatedQuality}`;

  if (size === Sizes.COVER) {
    const bannerFileName = fileName.replace(IMAGE_COVER_THUMB_REGEX, '@2x.$1');
    return `${imageServiceURL}/${optimizations}/${folder}/${bannerFileName}`;
  }

  return `${imageServiceURL}/${optimizations}/${folder}/${fileName}`;
};

export const withoutLoader = ({ src }: ImageLoaderProps) => {
  return src;
};
