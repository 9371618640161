import useEventListener from '@use-it/event-listener';
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';

import { GlobalState, createGlobalState } from './createGlobalState';
import { Storage } from './createStorage';

export type UsePersistedState<Value> = [Value | undefined, Dispatch<SetStateAction<Value | undefined>>];

export const usePersistedState = <Key extends string, Value>(
  key: Key,
  { get, set }: Storage<Key, Value>,
  initialState?: Value
): UsePersistedState<Value> => {
  const globalState = useRef<GlobalState | null>(null);
  const [state, setState] = useState<Value | undefined>(() => get(key, initialState));

  // subscribe to `storage` change events
  useEventListener('storage', (event) => {
    const { key: k, newValue } = event as unknown as { key: string; newValue: string };
    if (k === key) {
      const newState = JSON.parse(newValue) as Value;
      if (state !== newState) {
        setState(newState);
      }
    }
  });

  // only called on mount
  useEffect(() => {
    // register a listener that calls `setState` when another instance emits
    globalState.current = createGlobalState(key, (value?: Value) => setState(value), initialState);

    return () => {
      globalState.current?.deregister();
    };
  }, [initialState, key]);

  const persistentSetState = useCallback(
    (newState) => {
      const newStateValue = typeof newState === 'function' ? newState(state) : newState;

      // persist to localStorage
      set(key, newStateValue);

      setState(newStateValue);

      // inform all of the other instances in this tab
      globalState.current?.emit(newState);
    },
    [state, set, key]
  );

  return [state, persistentSetState];
};
