import { Text } from "@ifood/pomodoro-components";
import { Button, Flex } from "@whitelabel-webapp/shared/design-system";
import styled from "styled-components";
import { DefaultTheme } from "styled-components";

type Themed = { theme: DefaultTheme };
const desktop = ({ theme }: Themed) => `min-width: ${theme.screens.l.min}`;
const belowDesktop = ({ theme }: Themed) => `max-width: ${theme.screens.m.max}`;

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  padding-right: ${({ theme }) => theme.space.large};
  padding-left: ${({ theme }) => theme.space.large};

  @media (${belowDesktop}) {
    padding-right: ${({ theme }) => theme.space.regular};
    padding-left: ${({ theme }) => theme.space.regular};
  }
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: -webkit-fill-available;
  max-width: 1300px;

  --padding: ${({ theme }) => theme.space.largest};
  --gap: ${({ theme }) => theme.space.largest};

  @media (${belowDesktop}) {
    --padding: ${({ theme }) => theme.space.none};
    --gap: ${({ theme }) => theme.space.regular};
    margin-bottom: ${({ theme }) => theme.space.none};
    margin-top: ${({ theme }) => theme.space.none};
    background-color: white;
  }

  padding: 0 var(--padding);
  margin: var(--gap) auto;
  gap: var(--gap);
`;

export const Column = styled(Container)`
  gap: ${({ theme }) => theme.space.regular};
  padding: ${({ theme }) => theme.space.none};
  flex-basis: min-content;
  height: fit-content;
  flex: auto;

  @media (${desktop}) {
    max-width: 48%;
    gap: ${({ theme }) => theme.space.larger};
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardTitle = styled(Text)`
  margin-bottom: ${({ theme }) => theme.space.large};

  @media (${belowDesktop}) {
    margin-bottom: ${({ theme }) => theme.space.none};
    margin-left: ${({ theme }) => theme.space.regular};
    font-weight: 500;
    font-size: 16px;
  }
`;

export const CardTitleDesktop = styled(CardTitle)`
  @media (${belowDesktop}) {
    display: none;
  }
`;

export const WhiteCard = styled.div`
  border-radius: 6px;
  padding: ${({ theme }) => theme.space.large};
  background-color: ${({ theme }) => theme.colors.white};

  @media (${belowDesktop}) {
    padding: ${({ theme }) => theme.space.regular};
    padding-bottom: ${({ theme }) => theme.space.large};
    border-radius: ${({ theme }) => theme.space.none};
    border-bottom: solid 1px ${({ theme }) => theme.colors.grayLight};
  }
`;

export const LastWhiteCard = styled(WhiteCard)`
  @media (${belowDesktop}) {
    padding-bottom: ${({ theme }) => theme.space.regular} !important;
  }
`;

export const CheckoutButton = styled(Button)<{ blocked?: boolean }>`
  width: 100%;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizes.s};

  ${({ blocked }) =>
    blocked &&
    "color: #A6A6A6 !important; background-color: #DCDCDC !important;"}

  @media (${belowDesktop}) {
    font-size: ${({ theme }) => theme.fontSizes.s};
  }
`;

export const Divider = styled.div`
  border-bottom: solid 1px ${({ theme }) => theme.colors.grayLight};
  width: 100%;
  height: 1px;
  margin-bottom: 16px;
`;
