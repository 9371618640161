import {
  Flex,
  Heading,
  Icon as PomodoroIcon,
  TextLink as PomodoroTextLink,
} from "@whitelabel-webapp/shared/design-system";
import styled from "styled-components";

export const Wrapper = styled(Flex)`
  flex-direction: column;
  padding: 16px 32px 32px 32px;
  width: 100%;
  gap: ${({ theme }) => theme.space.regular};
  border: solid 1px ${({ theme }) => theme.colors.gray};
  background: white;
  z-index: 1;
  border-radius: 8px;
`;

export const Title = styled(Heading)`
  font-size: ${({ theme }) => theme.fontSizes.m};
`;

export const Option = styled(Flex)`
  gap: ${({ theme }) => theme.space.regular};

  cursor: pointer;
`;

export const Icon = styled(PomodoroIcon)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const TextLink = styled(PomodoroTextLink)`
  font-size: ${({ theme }) => theme.fontSizes.tsm};
  color: ${({ theme }) => theme.colors.primary};
`;
