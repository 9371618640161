import {
  Flex,
  Button as PomodoroButton,
} from "@whitelabel-webapp/shared/design-system";
import styled from "styled-components";

export const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: ${({ theme }) => theme.space.regular};
  margin: auto 0;
`;

export const Button = styled(PomodoroButton)`
  margin-top: ${({ theme }) => theme.space.larger};

  @media (min-width: 750px) {
    width: unset;
  }
`;
