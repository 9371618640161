import { useState } from 'react';

import { createStorage } from './createStorage';
import { usePersistedState } from './usePersistedState';

const getProvider = () => {
  if (typeof global !== 'undefined' && global.localStorage) {
    return global.localStorage;
  }
  // eslint-disable-next-line no-undef
  if (typeof globalThis !== 'undefined' && globalThis.localStorage) {
    // eslint-disable-next-line no-undef
    return globalThis.localStorage;
  }
  if (typeof window !== 'undefined' && window.localStorage) {
    return window.localStorage;
  }
  if (typeof localStorage !== 'undefined') {
    return localStorage;
  }
  return null;
};

export const createPersistedState = <Value, Key extends string = string>(key: Key, provider = getProvider()) => {
  if (provider) {
    const storage = createStorage<Key, Value>(provider);
    return (initialState?: Value) => usePersistedState(key, storage, initialState);
  }
  return () => useState<Value>();
};
