import { stringify } from "querystring";

import { useRouter } from "next/router";
import { useCallback } from "react";

export function useNavigationWithQuery() {
  const router = useRouter();

  const navigateWithQuery = useCallback(
    (query?: Record<string, string>) => {
      const path = location.pathname;
      if (query) {
        router.push(router.pathname, `${path}?${stringify(query)}`, {
          shallow: true,
        });
      } else {
        router.push(router.pathname, path, {
          shallow: true,
        });
      }
    },
    [router],
  );

  return navigateWithQuery;
}
