import { checkoutAboyeur } from '@whitelabel-webapp/checkout/shared/config';
import { ActionSheet, Text } from '@whitelabel-webapp/shared/design-system';
import { useEffect } from 'react';

import * as S from './styles';

type MerchantClosedProps = {
  open: boolean;
  onClose: () => void;
};

export const MerchantClosed: React.VFC<MerchantClosedProps> = ({ open, onClose }) => {
  useEffect(() => {
    if (!open) {
      return;
    }

    checkoutAboyeur.events.details.storeUnavailable();
  }, [open]);

  if (!open) return null;

  return (
    <ActionSheet title="Loja fechada no momento" open={true}>
      <ActionSheet.Body>
        <Text variant="medium" textAlign="center">
          Você pode aproveitar para ver o cardápio e pedir quando estivermos abertos.
        </Text>
      </ActionSheet.Body>
      <ActionSheet.Footer>
        <S.Button mt={10} variant="tertiary" onClick={onClose}>
          Ok, entendi
        </S.Button>
      </ActionSheet.Footer>
    </ActionSheet>
  );
};
