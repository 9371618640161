import { PaymentMethods } from "@whitelabel-webapp/checkout/shared/models";
import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";
import { useCallback, useMemo } from "react";
import { useQuery, useQueryClient } from "react-query";

import { useCheckout } from ".";

export function usePaymentMethods() {
  const { merchant } = useMerchant();
  const { order } = useCheckout();
  const queryClient = useQueryClient();

  const key = useMemo(
    () => ["fetch-payment-methods", merchant.id, order.deliveryMethod?.id],
    [merchant.id, order.deliveryMethod?.id],
  );

  const {
    isFetching,
    data: paymentMethodsResponse,
    refetch,
    status,
  } = useQuery(key, () => PaymentMethods.getPaymentMethods(merchant.id), {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  const paymentMethods = useMemo<PaymentMethods | undefined>(() => {
    if (!paymentMethodsResponse) return;
    return PaymentMethods.fromApi(paymentMethodsResponse);
  }, [paymentMethodsResponse]);

  const invalidatePaymentMethodsCache = useCallback(() => {
    return queryClient.invalidateQueries(key);
  }, [key, queryClient]);

  return {
    fetchPaymentMethods: refetch,
    paymentMethods,
    paymentMethodsStatus: isFetching || status === "idle" ? "loading" : status,
    invalidatePaymentMethodsCache,
  };
}
