import styled from "styled-components";

type WrapperProps = {
  isEmpty: boolean;
};

export const Wrapper = styled.button<WrapperProps>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.space.small};

  padding: ${({ theme }) => `${theme.space.small} ${theme.space.regular}`};
  color: ${({ theme }) => theme.colors.black};

  border-radius: 12px 12px 0px 0px;
  overflow: hidden;
  border: 0;
  box-shadow: 0 0 6px 2px #00000017;

  background-color: ${({ theme }) => theme.colors.white};
  cursor: ${({ isEmpty }) => (isEmpty ? "default" : "pointer")};

  button {
    border-radius: 8px;
  }
`;

export const Container = styled.div`
  position: relative;
  flex-direction: column;
  flex: auto;
  display: flex;
`;

export const Quantity = styled.div`
  font-weight: normal;
`;
