import { checkoutAboyeur } from "@whitelabel-webapp/checkout/shared/config";
import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";
import { Button } from "@whitelabel-webapp/shared/design-system";
import { Text } from "@whitelabel-webapp/shared/design-system";
import { DialogTitle } from "../../DialogTitle";
import { DialogContent } from "../../Content";
import { useEffect } from "react";

import * as S from "./styles";

type EmptyProps = {
  onClose: () => void;
};

export const Empty: React.VFC<EmptyProps> = ({ onClose }) => {
  const {
    merchant: { id, phone },
  } = useMerchant();

  useEffect(() => {
    checkoutAboyeur.events.payment.emptyMethods(id);
  }, [id]);

  return (
    <>
      <DialogTitle>Formas de pagamento indisponíveis</DialogTitle>
      <DialogContent>
        <S.Container>
          <Text textAlign="center" mt="large" mb="regular">
            Esta loja não possui forma de pagamento disponível no momento. Entre
            em contato:
          </Text>
          <Text fontWeight="bold">{phone}</Text>
          <Button variant="tertiary" mt="smaller" onClick={() => onClose()}>
            Ok, entendi
          </Button>
        </S.Container>
      </DialogContent>
    </>
  );
};
