import { createContext, useContext, useState } from 'react';

const OnHistoryBackContext = createContext<((fn: () => void) => void) | null>(null);

export const OnHistoryBackProvider: React.FC = (props) => {
  const [onBack, setOnBack] = useState<() => void>(() => void 0);

  function handleUpdate(fn: () => void) {
    window.removeEventListener('popstate', onBack);
    window.addEventListener('popstate', fn);

    setOnBack(() => fn);
  }

  return <OnHistoryBackContext.Provider value={handleUpdate}>{props.children}</OnHistoryBackContext.Provider>;
};

export const useOnHistoryBack = () => {
  const context = useContext(OnHistoryBackContext);

  if (typeof context === 'undefined') {
    throw new Error(`'useOnHistoryBack()' must be used within a '${OnHistoryBackProvider.displayName}'`);
  }

  return context;
};
