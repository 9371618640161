import { useMerchant } from '@whitelabel-webapp/merchant/shared/merchant-store';
import { useMemo, useState } from 'react';
import createPersistedState from 'use-persisted-state';

const CURRENT_DELIVERY_ADDITIONAL_INFO_KEY = 'current_deliveryAdditionalInfo';

export function usePersistedDeliveryAdditionalInfo(): [string | undefined, (deliveryAdditionalInfo: string) => void] {
  const { merchant } = useMerchant();

  const useLocalCurrentDeliveryAdditionalInfo = useMemo(
    () => createPersistedState<string>(`${merchant.id}.${CURRENT_DELIVERY_ADDITIONAL_INFO_KEY}`),
    [merchant]
  );

  const [localCurrentDeliveryAdditionalInfo, setLocalCurrentDeliveryAdditionalInfo] =
    useLocalCurrentDeliveryAdditionalInfo();
  const [persistedDeliveryAdditionalInfo, setPersistedDeliveryAdditionalInfo] = useState<string | undefined>(() => {
    if (!localCurrentDeliveryAdditionalInfo) {
      return undefined;
    }

    return localCurrentDeliveryAdditionalInfo;
  });

  function handleSetPersistedCampaignCode(campaignCode: string) {
    setLocalCurrentDeliveryAdditionalInfo(campaignCode);
    setPersistedDeliveryAdditionalInfo(campaignCode);
  }

  return [persistedDeliveryAdditionalInfo, handleSetPersistedCampaignCode];
}
