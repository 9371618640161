const keyHasAlreadyAnsweredUserOpinionSurvey = 'has-already-answered-user-opinion-survey';

export const hasAlreadyAnsweredUserOpinionSurvey = (): boolean => {
  const hasAlreadyAnsweredUserOpinionSurvey = localStorage.getItem(keyHasAlreadyAnsweredUserOpinionSurvey);
  return hasAlreadyAnsweredUserOpinionSurvey === 'true';
};

export const setHasAlreadyAnsweredUserOpinionSurveyFlag = () => {
  localStorage.setItem(keyHasAlreadyAnsweredUserOpinionSurvey, 'true');
};
