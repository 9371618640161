import { Button as PomodoroButton } from '@whitelabel-webapp/shared/design-system';
import { ActionSheet } from '@whitelabel-webapp/shared/design-system';
import styled from 'styled-components';

export const Footer = styled(ActionSheet.Footer)`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 465px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const Button = styled(PomodoroButton)`
  width: 100%;

  font-size: ${({ theme }) => theme.fontSizes.s};

  &:first-child {
    margin-top: ${({ theme }) => theme.space.small};
  }

  @media (min-width: 465px) {
    &:first-child {
      margin-top: ${({ theme }) => theme.space.none};
    }

    & {
      width: 45%;
      margin-top: ${({ theme }) => theme.space.none};
    }
  }
`;
