import { useContext } from 'react';

import { OrderContext, OrderContextValue, OrderProvider } from './provider';

export function useOrder(): OrderContextValue {
  const order = useContext(OrderContext);

  if (typeof order === 'undefined') {
    throw new Error(`'useOrder()' must be used within a '${OrderProvider.displayName}'`);
  }

  return order;
}
