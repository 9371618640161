import tokens from "@ifood/pomodoro-tokens";
import {
  Button,
  Flex,
  Divider as PomodoroDivider,
  Drawer as PomodoroDrawer,
} from "@whitelabel-webapp/shared/design-system";
import {
  BackButton,
  BaseDrawer,
  Text,
  TextLink,
} from "@whitelabel-webapp/shared/design-system";
import styled from "styled-components";

export const Wrapper = styled(Flex)`
  height: 100%;
`;

export const Body = styled(PomodoroDrawer.Body)`
  width: 100%;
  padding: ${({ theme }) => `${theme.space.none} ${theme.space.regular}`};
  background-color: ${(props) => props.theme.colors.white};
`;

export const Header = styled(BaseDrawer.Header)`
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => `${theme.space.large} ${theme.space.regular}`};
`;

export const ReturnButton = styled(BackButton)`
  position: unset;
  svg {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const HeaderTitle = styled(Text)`
  color: ${(props) => props.theme.colors.black};
  font-size: ${({ theme }) => theme.fontSizes.s};
  font-family: ${({ theme }) => theme.fonts.primary};
  line-height: ${({ theme }) => theme.lineHeights.m};
`;

export const HelpButton = styled(Button).attrs({ variant: "tertiary" })`
  padding: ${({ theme }) => theme.space.none};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-family: ${({ theme }) => theme.fonts.primary};
`;

type VariantType = "error" | "neutral" | "concluded" | "waitingPayment";

type StatusCardProps = {
  variant?: VariantType;
};

function getStatusCardBorderColor(theme: typeof tokens, variant?: VariantType) {
  const statusCardVariants = {
    neutral: theme.colors.grayLight,
    waitingPayment: theme.colors.attention,
  };

  return statusCardVariants[variant || "neutral"];
}

export const StatusCard = styled(Flex)<StatusCardProps>`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.space.large};
  padding: ${({ theme }) => theme.space.smaller};

  background-color: ${(props) => props.theme.colors.white};
  border: 2px solid
    ${({ theme, variant }) => getStatusCardBorderColor(theme, variant)};
  border-radius: ${({ theme }) => theme.radii.m};
`;

export const ItemNameContainer = styled(Flex)`
  align-items: center;
  justify-content: flex-start;
`;

export const ItemHeaderContainer = styled(Flex)`
  gap: ${({ theme }) => theme.space.regular};
  justify-content: space-between;
`;

export const ItemListContainer = styled(Flex)`
  flex-direction: column;
`;

export const ItemName = styled(Text)`
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-family: ${({ theme }) => theme.fonts.primary};
`;

export const ItemDetails = styled(Flex)`
  align-items: center;
`;

export const ItemPrice = styled(Flex)`
  align-items: center;

  justify-content: flex-end;

  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-family: ${({ theme }) => theme.fonts.primary};

  white-space: nowrap;
`;

export const ItemQuantity = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 12px;

  margin-right: 6px;

  color: ${({ theme }) => theme.colors.black};

  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-family: ${({ theme }) => theme.fonts.primary};

  background-color: ${(props) => props.theme.colors.grayLight};
  border: 1px solid #f2f2f2;

  border-radius: ${({ theme }) => theme.radii.s};
`;

export const SecondaryItemQuantity = styled(Flex)`
  justify-content: center;

  width: 15px;
  height: 12px;
  margin-right: 6px;

  border: 1px solid #f2f2f2;
  border-radius: ${({ theme }) => theme.radii.s};
`;

export const SecondaryItemQuantityNumber = styled(Text)`
  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.colors.grayDarker};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-family: ${({ theme }) => theme.fonts.primary};
`;

export const OrderDetailsText = styled(Text)`
  color: ${({ theme }) => theme.colors.grayDarkest};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-family: ${({ theme }) => theme.fonts.primary};
`;

export const OrderDivider = styled(PomodoroDivider)`
  width: 100%;
`;

export const OrderPaymentContainer = styled(Flex)`
  flex-direction: column;
`;

export const OrderPaymentPrice = styled(Flex)`
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.space.smallest};

  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-family: ${({ theme }) => theme.fonts.primary};
`;

export const OrderPaymentTotalPrice = styled(Flex)`
  align-items: center;

  justify-content: space-between;

  margin-top: ${({ theme }) => theme.space.regular};

  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizes.tsm};
  font-family: ${({ theme }) => theme.fonts.primary};
`;

export const OrderPaymentText = styled(Flex)`
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.space.smaller};

  color: ${({ theme }) => theme.colors.grayDarkest};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-family: ${({ theme }) => theme.fonts.primary};
`;

export const AddressContainer = styled(Flex)`
  flex-direction: column;
`;

export const DeliveryAddress = styled(Flex)`
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.space.smaller};

  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-family: ${({ theme }) => theme.fonts.primary};
`;

export const DeliveryAddressText = styled(Flex)`
  flex-direction: column;

  color: ${({ theme }) => theme.colors.grayDarkest};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-family: ${({ theme }) => theme.fonts.primary};
`;

export const PaymentButton = styled(Button)`
  font-size: ${({ theme }) => theme.fontSizes.xs};
`;

export const PixFAQButton = styled(TextLink)`
  margin-top: ${(props) => props.theme.space.smaller};
  margin-bottom: ${(props) => props.theme.space.small};

  text-align: center;
`;
