import { Button, Flex } from "@whitelabel-webapp/shared/design-system";
import styled from "styled-components";
import { DefaultTheme } from "styled-components";

export const Wrapper = styled(Flex)`
  gap: ${({ theme }) => theme.space.regular};
  flex-direction: column;
`;

export const Container = styled(Flex)`
  display: flex;
  font-weight: 500;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.smallest};
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: ${({ theme }) => theme.fontSizes.s};
`;

export const PaymentTypeButton = styled(Flex)`
  width: 100%;

  gap: ${({ theme }) => theme.space.regular};
  margin: 0;
  align-items: center;
  padding: 0;

  font-family: ${({ theme }) => theme.font.family.ifood.body};
  font-size: ${({ theme }) => theme.fontSizes.s};
  text-align: left;

  background-color: ${({ theme }) => theme.colors.white};

  & svg {
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const CircleUnchecked = styled.div`
  width: 15px;
  margin-left: auto;

  aspect-ratio: 1;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
  border: solid 1px ${({ theme }) => theme.colors.gray};
`;

export const CircleCheck = styled.div`
  width: 6px;
  margin-left: auto;

  aspect-ratio: 1;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
  border: solid 6px ${({ theme }) => theme.colors.primary};
`;

export const Tag = styled(Flex)`
  position: absolute;
  top: 7px;
  right: 8px;

  align-items: center;
  justify-content: center;
  width: 42px;
  height: 12px;
  padding: 2px 4px 0px 4px;

  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  font-size: 7px;

  background-color: ${({ theme }) => theme.colors.grayDarkest};
  border-radius: ${({ theme }) => theme.radii.m};
`;

export const PaymentTypeDescripton = styled.span`
  color: ${({ theme }) => theme.colors.grayDarker};
  font-size: ${({ theme }) => theme.fontSizes.tsm};
  line-height: 12px;
  font-weight: 400;
`;

export const SwitchButton = styled(Button)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
  font-size: 16px;
  padding: 0;
`;
