import { useCheckout } from "@app/domains/checkout";
import { Item as CheckoutItem } from "@whitelabel-webapp/checkout/shared/models";
import { Item as CatalogItem } from "@whitelabel-webapp/catalog/shared/models";
import { useEffect, useRef, useState } from "react";
import { QuickAdd } from "@whitelabel-webapp/shared/design-system";
import { useRouter } from "next/router";
import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";

type QuickAddItemProps = {
  item: CatalogItem;
  variant?: "large" | "small";
  observation?: string;
  enableExtraButtons?: boolean;
};

function getItemInOrder(itemsList: CheckoutItem[], itemId: string) {
  return itemsList.find((item) => item.catalogItem.id == itemId);
}

function getCheckoutItem(
  itemsRecord: Record<string, CheckoutItem>,
  item?: CatalogItem,
) {
  return item && item.instanceId ? itemsRecord[item.instanceId] : undefined;
}

export const QuickAddItem: React.VFC<QuickAddItemProps> = ({
  item,
  variant,
  observation,
  enableExtraButtons,
}) => {
  const { order, addItem, removeItem } = useCheckout();
  const { merchant } = useMerchant();
  const router = useRouter();

  const [currentItem, setItem] = useState(item);
  const [quantity, setQuantity] = useState(undefined);
  const prevItemRef = useRef(currentItem);

  useEffect(() => {
    const itemInOrder = getItemInOrder(order.itemsList, item.id);
    if (!itemInOrder) {
      setItem(item);
      setQuantity(0);
      return;
    }
    setQuantity(itemInOrder.quantity);
    if (enableExtraButtons) {
      return setItem(
        CatalogItem.fromPartial({
          ...itemInOrder.catalogItem,
          productInfo: item.productInfo,
        }),
      );
    }
    setItem(itemInOrder.catalogItem);
  }, [order.itemsList, item]);

  useEffect(() => {
    if (!enableExtraButtons) return;
    if (!getItemInOrder(order.itemsList, item.id)) return;
    if (prevItemRef.current.productInfo !== currentItem.productInfo) {
      onQuickAddChange(quantity);
    }
    prevItemRef.current = currentItem;
  }, [currentItem]);

  useEffect(() => {
    if (!currentItem.instanceId) return;
    const timeout = setTimeout(async () => {
      await onQuickAddChange(quantity);
    }, 800);
    return () => clearTimeout(timeout);
  }, [observation]);

  const canQuickAdd = currentItem.choices.length === 0 || quantity > 0;

  const onQuickAddChange = async (newQuantity: number) => {
    if (newQuantity > 0) {
      const choices = getCheckoutItem(order.items, currentItem)?.choices ?? [];
      await addItem(currentItem, newQuantity, choices, observation);
    } else {
      if (quantity > 0) removeItem(currentItem);
    }
  };

  const openItemPage = async () => {
    router.push(`/${merchant.query}/item/${item.code}`);
  };

  const productInfo = currentItem.isWeigthVariable()
    ? currentItem.productInfo
    : undefined;

  if (quantity === undefined) return null;

  return (
    <QuickAdd
      enableExtraButtons={enableExtraButtons}
      description={currentItem.description}
      productInfo={productInfo}
      onChange={canQuickAdd ? onQuickAddChange : openItemPage}
      blocked={!canQuickAdd}
      startValue={quantity}
      variant={variant}
    ></QuickAdd>
  );
};
