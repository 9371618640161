export const ERRORS = {
  UNEXPECTED: "Algo deu errado!, tente novamente em alguns segundos.",
};

export const FAQ = {
  pix: [
    {
      title: "Não consigo pagar via Pix",
      content: {
        __html: `
          <p>
            Para usar esta forma de pagamento, você precisará ter uma chave Pix cadastrada no seu banco ou aplicativo de
            pagamento. Também é necessário que tenha dinheiro em conta, já que não é possível pagar na função crédito.
          </p>
          <p>
            Se você já tem uma chave cadastrada, confira se o código copiado está correto e escolha o app de pagamento ou
            banco em que você tenha o Pix habilitado.
          </p>
          <p>
            <strong>Após o código ser gerado, você tem 5 minutos pra fazer o pagamento.</strong> Depois disso, ele perde a
            validade e o pedido é cancelado.
          </p>
          <p>
            Para tentar de novo, você deverá refazer o pedido, escolher pagamento via Pix ou outra forma de pagamento.
          </p>
        `,
      },
    },
    {
      title: "Não encontro o código Pix",
      content: {
        __html: `
          <p>
            O código do Pix Copia e Cola sempre vai aparecer assim que você selecionar a opção &quot;Fazer pedido&quot; e
            depois é só usar o botão &quot;Copiar código do QR code&quot;.
          </p>
          <p>
            Se ainda estiver no prazo de 5 minutos do código, siga os passos a seguir para encontrá-lo:
            <ol>
              <li>Vá até &quot;Pedidos em andamento&quot;;</li>
              <li>Localize o pedido que está aguardando o pagamento e selecione &quot;Fazer pagamento&quot;;</li>
              <li>Depois copie o código e cole no app de pagamento ou banco em que você tenha o Pix habilitado.</li>
            </ol>
          </p>
          <p>
            Caso o tempo de 5 minutos para pagamento via Pix tenha acabado, o pedido é cancelado. Faça o pedido novamente,
            escolha o pagamento via Pix ou tente outra forma de pagamento.
          </p>
        `,
      },
    },
    {
      title: "Quero gerar um novo código Pix",
      content: {
        __html: `
          <p>
            Para gerar um novo código Pix, aguarde até que o tempo disponível do atual código acabe e que seu pedido seja
            cancelado automaticamente.
          </p>
          <p>
            Depois, faça o seu pedido novamente e copie o novo código ao selecionar &quot;Copiar código do QR code&quot;.
          </p>
        `,
      },
    },
    {
      title: "Não quero mais pagar via Pix",
      content: {
        __html: `
          <p>
            Tudo bem! Para mudar a forma de pagamento depois de ter selecionado &quot;Fazer pedido&quot;, aguarde até que o
            tempo disponível do atual código acabe e que seu pedido seja cancelado automaticamente.
          </p>
          <p>Depois disso, faça o pedido novamente e, na sacola, escolha outra forma de pagamento.</p>
        `,
      },
    },
  ],
};

type RatingOption = {
  label: string;
  value: string;
};

type RatingConfig = {
  title: (applicationName?: string) => string;
  image: string;
  GOOD_RATING_OPTIONS: RatingOption[];
  BAD_RATING_OPTIONS: RatingOption[];
};

export const APP_RATING: RatingConfig = {
  title: (applicationName?: string) =>
    applicationName
      ? `O que você achou de pedir pelo ${applicationName}?`
      : "O que você achou de pedir pelo nosso site?",
  image: "/icons/order-rating/phone.svg",
  GOOD_RATING_OPTIONS: [
    {
      label: "Fácil de usar",
      value: "easy",
    },
    {
      label: "Prático",
      value: "practical",
    },
    {
      label: "Rápido",
      value: "fast",
    },
    {
      label: "Bom funcionamento",
      value: "good",
    },
    {
      label: "Apresentação",
      value: "presentation",
    },
  ],
  BAD_RATING_OPTIONS: [
    {
      label: "Difícil de usar",
      value: "hard",
    },
    {
      label: "Falta de clareza",
      value: "clarity",
    },
    {
      label: "Funcionamento complicado",
      value: "complex",
    },
    {
      label: "Lentidão",
      value: "slow",
    },
    {
      label: "Travar menos",
      value: "less_stuck",
    },
  ],
};

export const MERCHANT_RATING: RatingConfig = {
  title: (applicationName?: string) =>
    applicationName
      ? `O que você achou do seu pedido ${applicationName}?`
      : "O que você achou do seu pedido?",
  image: "/icons/order-rating/chef.svg",
  GOOD_RATING_OPTIONS: [
    {
      label: "Sabor",
      value: "flavor",
    },
    {
      label: "Tempero",
      value: "spice",
    },
    {
      label: "Aparência",
      value: "look",
    },
    {
      label: "Quantidade",
      value: "quantity",
    },
    {
      label: "Embalagem",
      value: "packing",
    },
  ],
  BAD_RATING_OPTIONS: [
    {
      label: "Sabor",
      value: "flavor",
    },
    {
      label: "Tempero",
      value: "spice",
    },
    {
      label: "Aparência",
      value: "look",
    },
    {
      label: "Quantidade",
      value: "quantity",
    },
    {
      label: "Embalagem",
      value: "packing",
    },
  ],
};
