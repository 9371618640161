import {
  GarnishItem as CatalogGarnishItem,
  GarnishItemJSON as CatalogGarnishItemJSON,
} from '@whitelabel-webapp/catalog/shared/garnish-item';

export type CheckoutGarnishItem = {
  code: string;
  name: string;
  description: string;
  unitPrice: number;
  qty: number;
};

export type GarnishItemJSON = {
  id: string;
  catalogGarnishItem: CatalogGarnishItemJSON;
  quantity: number;
};
export class GarnishItem {
  static fromCatalogGarnishItem(item: CatalogGarnishItem, quantity: number): GarnishItem {
    const { id } = item;

    return new GarnishItem(id, item, quantity);
  }

  static fromJSON(rawGarnishItem: GarnishItemJSON) {
    const catalogGarnishItem = CatalogGarnishItem.fromJSON(rawGarnishItem.catalogGarnishItem);
    return new GarnishItem(rawGarnishItem.id, catalogGarnishItem, rawGarnishItem.quantity);
  }

  constructor(public id: string, public catalogGarnishItem: CatalogGarnishItem, public quantity: number) {}

  toJSON(): GarnishItemJSON {
    return {
      id: this.id,
      catalogGarnishItem: this.catalogGarnishItem.toJSON(),
      quantity: this.quantity,
    };
  }

  withQuantity(quantity: number): GarnishItem {
    this.quantity = quantity;

    return this;
  }

  toCheckout(): CheckoutGarnishItem {
    return {
      code: this.catalogGarnishItem.code,
      name: this.catalogGarnishItem.description,
      description: this.catalogGarnishItem.description,
      unitPrice: this.catalogGarnishItem.unitPrice.getValue(),
      qty: this.quantity,
    };
  }
}
