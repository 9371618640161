const getGreenCard = (paymentMethodName: string) => {
  if (paymentMethodName.includes('COOPER CARD')) {
    return '/icons/payment/greencard.svg';
  }
  if (paymentMethodName.includes('VERDECARD')) {
    return '/icons/payment/verdecard.svg';
  }

  return '/icons/payment/greencard.svg';
};

export const getBrandIconPath = (brandName: string, paymentMethodName = '') => {
  const brands = {
    ALELO: "/icons/payment/alelo.svg",
    AMEX: "/icons/payment/amex.svg",
    BANK_DRAFT: "/icons/payment/bank_draft.svg",
    BANRICOMPRAS: "/icons/payment/banricompras.svg",
    BEN_MEAL_VOUCHER: "/icons/payment/ben.svg",
    CASH: "/icons/payment/cash.svg",
    DINERS: "/icons/payment/diners.svg",
    ELO: "/icons/payment/elo.svg",
    GOODCARD: "/icons/payment/goodcard.svg",
    GREENCARD: getGreenCard(paymentMethodName),
    HIPERCARD: "/icons/payment/hipercard.svg",
    MASTERCARD: "/icons/payment/mastercard.svg",
    NUGO: "/icons/payment/nugo.svg",
    NUTRICARD: "/icons/payment/nutricard.svg",
    REFEISUL: "/icons/payment/refeisul.svg",
    SODEXO: "/icons/payment/sodexo.svg",
    TICKET: "/icons/payment/ticket.svg",
    VALE_CARD: "/icons/payment/valecard.svg",
    VEROCARD: "/icons/payment/verocard.svg",
    VISA: "/icons/payment/visa.svg",
    VISA_ELECTRON: "/icons/payment/visa.svg",
    "VISA ELECTRON": "/icons/payment/visa.svg",
    VR: "/icons/payment/vr.svg",
    PIX: "/icons/payment/pix.svg",
  };

  return brands[brandName as keyof typeof brands] || '/icons/payment/default.svg';
};

export type PaymentMethodName = keyof typeof getBrandIconPath;

export const ERRORS = {
  UNEXPECTED: 'Algo deu errado!, tente novamente em alguns segundos',
  EXPIRED_TOKEN: 'Seu acesso expirou! Faça o login novamente.',
  CHECKOUT_DISABLED: 'Pedidos indisponíveis no momento, tente novamente mais tarde',
  QUOTATION_API:
    'O método de entrega padrão não está disponível, escolha um dia e horário para receber seu pedido Seu pedido será agendado',
};

export const ERROR_CODES = {
  CHECKOUT_DISABLED: 'MKTC-2135',
};

export const CARD_TOKEN_COOKIE_KEY = 'card-token';
