import {
  WHITELABEL_COOKIES_PREFIX,
  createAuthenticatedAxiosClient,
  getAccessToken,
} from "@whitelabel-webapp/authentication/shared/utils";
import { groceriesApiBffURL } from "@whitelabel-webapp/shared/config";
import { AxiosInstance } from "axios";
import * as jwt from "jsonwebtoken";

import { Order, OrderResponse } from "./order";

export class Orders {
  static client: AxiosInstance;

  static initClient(uuid: string): void {
    Orders.client = createAuthenticatedAxiosClient(groceriesApiBffURL, uuid);
  }

  static async getOrders(uuid: string): Promise<OrderResponse[]> {
    if (!Orders.client) {
      Orders.initClient(uuid);
    }

    const { data } = await Orders.client.get(`/orders`, {
      params: {
        merchantId: uuid,
        page: 0,
        size: 10,
      },
    });

    return data;
  }

  static fromApi(rawOrders: OrderResponse[]) {
    return new Orders(rawOrders.map((order) => Order.fromApi(order)));
  }

  constructor(public list: Order[]) {}

  hasOnGoingOrders() {
    return (
      this.list.length > 0 &&
      this.list.some(
        (order) => order.isInProgress() || order.isWaitingPayment(),
      )
    );
  }

  getOrders() {
    return this.list
      .filter(
        (order) =>
          order.isInProgressOrConcluded() ||
          order.isCancelled() ||
          order.isWaitingPayment(),
      )
      .sort((a, b) => {
        const aTimestamp = a.createdAt.getTime();
        const bTimestamp = b.createdAt.getTime();

        return bTimestamp - aTimestamp;
      });
  }
}
