import { Money, MoneyJSON } from '@whitelabel-webapp/shared/models';

export type GarnishItemResponse = {
  id: string;
  code: string;
  description: string;
  details?: string;
  unitPrice: number;
  posCode: number;
  tags: [];
  logoUrl?: string;
};

export type GarnishItemJSON = {
  id: string;
  code: string;
  description: string;
  details?: string;
  unitPrice: MoneyJSON;
  logoUrl?: string;
};

export class GarnishItem {
  static fromApi(rawGarnishItem: GarnishItemResponse) {
    return new GarnishItem(
      rawGarnishItem.id,
      rawGarnishItem.code,
      rawGarnishItem.description,
      new Money(rawGarnishItem.unitPrice),
      rawGarnishItem.details,
      rawGarnishItem.logoUrl
    );
  }

  static fromJSON(rawGarnishItem: GarnishItemJSON) {
    const money = Money.fromJSON(rawGarnishItem.unitPrice);

    return new GarnishItem(
      rawGarnishItem.id,
      rawGarnishItem.code,
      rawGarnishItem.description,
      money,
      rawGarnishItem.details,
      rawGarnishItem.logoUrl
    );
  }

  constructor(
    public id: string,
    public code: string,
    public description: string,
    public unitPrice: Money,
    public details?: string,
    public logoUrl?: string
  ) {}

  toJSON(): GarnishItemJSON {
    return {
      id: this.id,
      code: this.code,
      description: this.description,
      details: this.details,
      unitPrice: this.unitPrice.toJSON(),
      logoUrl: this.logoUrl,
    };
  }
}
