import NextImage from 'next/image';

import { Order } from '../../models';
import { OrderInfo } from '../OrderInfo';
import * as S from './styles';

type CancelledSummaryProps = {
  order: Order;
};

export const CancelledSummary: React.VFC<CancelledSummaryProps> = ({ order }) => {
  return (
    <S.Wrapper>
      <S.Container>
        <S.Header>
          <NextImage height={10} width={10} src="/icons/cancelled-status.svg" />
          <S.Title>Pedido cancelado</S.Title>
        </S.Header>
      </S.Container>

      <OrderInfo order={order} isCancelled={true} />
    </S.Wrapper>
  );
};
