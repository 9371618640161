import { Flex, LinkText, Icon as PomodoroIcon, Text as PomodoroText } from '@whitelabel-webapp/shared/design-system';
import styled from 'styled-components';

export const Wrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 40px 0px 40px 0px;
  gap: ${({ theme }) => theme.space.regular};
`;

export const Title = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Text = styled(PomodoroText)`
  margin: 0 auto;

  text-align: center;
  line-height: normal;
`;

export const SupportedContacts = styled(Flex)`
  justify-content: center;
  gap: 64px;
`;

export const Contact = styled(Flex)`
  align-items: center;
`;

export const ContactIcon = styled(PomodoroIcon)`
  background: none;
  border: 0 none;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.black};
  display: inline-flex;
  height: initial;
  max-width: 100%;
  opacity: 1;
  overflow: hidden;
  position: relative;
  width: initial;

  & > svg {
    background: none;
    border: 0 none;
    display: block;
    height: initial;
    max-width: 100%;
    opacity: 1;
    width: initial;
  }
`;

export const ContactLink = styled(LinkText)`
  font-size: ${({ theme }) => theme.fontSizes.s};

  text-align: center;
`;
