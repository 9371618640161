import { GarnishItem as CheckoutGarnishItem } from '@whitelabel-webapp/checkout/shared/garnish-item';
import { Money } from '@whitelabel-webapp/shared/models';

import { Choice } from '../choice';

export class ItemWithPizzaPriceCalculator {
  constructor(
    private item: { unitPrice: Money; choices: Choice[] },
    private selectGarnishItems: Record<string, CheckoutGarnishItem[]> | undefined,
    private quantity: number
  ) {}

  public calculate() {
    const choicesPrice = [
      ...this.calculatePizzaFlavorChoicesPrices(),
      ...this.calculateGarnishPizzaChoicesPrices(),
    ].reduce((acc, value) => acc.add(value), new Money(0));

    return choicesPrice.add(this.item.unitPrice).multiply(this.quantity);
  }

  private calculatePizzaFlavorChoicesPrices() {
    const pizzaFlavorChoices = this.item.choices.filter((choice) => choice.isPizzaFlavor());

    const pizzaFlavorsChoicesValue = pizzaFlavorChoices
      .map((choice) => this.selectGarnishItems?.[choice.code] ?? [])
      .flat(1)
      .map((garnishItem) => garnishItem.catalogGarnishItem.unitPrice.multiply(garnishItem.quantity))
      .map((money) => money.getValue());

    const pizzaFlavorsChoicesMoney = Array.from(
      Array(pizzaFlavorsChoicesValue.length),
      () => new Money(Math.max(...pizzaFlavorsChoicesValue))
    );

    return pizzaFlavorsChoicesMoney;
  }

  private calculateGarnishPizzaChoicesPrices() {
    const pizzaGarnishChoices = this.item.choices.filter((choice) => !choice.isPizzaFlavor());

    const garnishPizzaChoicesMoney = pizzaGarnishChoices
      .map((choice) => this.selectGarnishItems?.[choice.code] ?? [])
      .flat(1)
      .map((garnishItem) => garnishItem.catalogGarnishItem.unitPrice.multiply(garnishItem.quantity));

    return garnishPizzaChoicesMoney;
  }
}
