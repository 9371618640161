import { useAddress } from "@whitelabel-webapp/address/shared/address-store";
import { useCatalog } from "@whitelabel-webapp/catalog/shared/catalog-store";
import { checkoutAboyeur } from "@whitelabel-webapp/checkout/shared/config";
import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";
import { DeliveryMethod } from "@whitelabel-webapp/merchant/shared/models";
import { ActionSheet, Text } from "@whitelabel-webapp/shared/design-system";

import * as S from "./styles";

type ChooseDeliveryMethodProps = {
  open: boolean;
  onTakeout: () => void;
  onClose: () => void;
};

export const ChooseDeliveryMethod: React.VFC<ChooseDeliveryMethodProps> = ({
  open,
  onTakeout,
  onClose,
}) => {
  const { merchant } = useMerchant();
  const { openAddress, setDeliveryMethod } = useAddress();

  async function handleTakeout() {
    try {
      const takeoutMethodResponse = await merchant.getTakeoutMethod();

      if (!takeoutMethodResponse) {
        return;
      }

      const takeoutMethod = DeliveryMethod.fromApi(takeoutMethodResponse);

      setDeliveryMethod(takeoutMethod);
    } catch (error: any) {
      checkoutAboyeur.events.catch.onError(error as Error);
    }

    onTakeout();
  }

  function handleDelivery() {
    onClose();
    openAddress("MERCHANT");
  }

  return (
    <ActionSheet title="Método de entrega" open={open} onClose={onClose}>
      <ActionSheet.Body>
        <Text variant="medium" textAlign="center">
          Como quer receber seu pedido?
        </Text>
      </ActionSheet.Body>
      <ActionSheet.Footer>
        <S.Footer>
          <S.Button variant="secondary" onClick={handleTakeout}>
            Retirar na loja
          </S.Button>
          <S.Button variant="primary" onClick={handleDelivery}>
            Pedir para entregar
          </S.Button>
        </S.Footer>
      </ActionSheet.Footer>
    </ActionSheet>
  );
};
