import {
  Dialog,
  Flex,
  Heading,
  Button as PomodoroButton,
  Divider as PomodoroDivider,
  Icon as PomodoroIcon,
  Text as PomodoroText,
  TextLink as PomodoroTextLink,
} from "@whitelabel-webapp/shared/design-system";
import styled from "styled-components";

export const Wrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: 8px;
`;

export const Title = styled(Heading).attrs({ as: "h2" })`
  font-size: ${({ theme }) => theme.fontSizes.m};
  font-weight: bold;
  margin: 0;
  line-height: normal;
`;

export const Container = styled(Flex)`
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

export const Item = styled(Flex)`
  gap: 16px;
  align-items: center;
  width: 100%;
  height: 48px;
`;

export const Divider = styled(PomodoroDivider)`
  color: #f2f2f2;
  height: 1px;
  width: 100%;
  margin: 0;
`;

export const ItemIcon = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  width: 48px;
  height: 100%;
`;

export const Icon = styled(PomodoroIcon)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const ItemInfo = styled(Flex)`
  flex-direction: column;
  flex-grow: 1;
`;

export const ItemTitle = styled(Heading).attrs({ as: "h3" })`
  font-size: ${({ theme }) => theme.fontSizes.tsm};
  font-weight: bold;
  margin: 0;
  line-height: normal;
`;

export const Text = styled(PomodoroText)`
  color: ${({ theme }) => theme.colors.grayDarkest};
  font-size: ${({ theme }) => theme.fontSizes.tsm};
  margin: 0;
`;

export const ItemDescription = styled(Text)`
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
`;

export const Button = styled(PomodoroButton)`
  width: 100%;
  margin-top: 16px;
`;

export const TextLink = styled(PomodoroTextLink)`
  margin-bottom: 16px;
  font-size: ${({ theme }) => theme.fontSizes.tsm};
`;

export const CustomDialog = styled(Dialog)`
  width: 500px;
  padding: ${({ theme }) => theme.space.large};
`;
