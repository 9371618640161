import { FC, MouseEventHandler, Ref, forwardRef, useEffect, useState } from 'react';

import { ItemProps } from '../types';
import * as S from './styles';

export const Item: FC<ItemProps> = forwardRef(({ children, active = false, onClick }, ref: Ref<HTMLButtonElement>) => {
  const [touch, setTouch] = useState<boolean>(false);

  useEffect(() => {
    if (touch && !active) {
      return;
    }

    setTouch(active);
  }, [active, touch]);

  const handleClick: MouseEventHandler = () => {
    setTouch(true);
    onClick?.();
  };

  return (
    <S.Button active={active} onClick={handleClick} ref={ref} type="button">
      {children}
    </S.Button>
  );
});
