import NextImage from 'next/image';

import { formatDateToString } from '@app/utils/date';

import { Order } from '../../models';
import { OrderInfo } from '../OrderInfo';
import * as S from './styles';

type ConcludedSummaryProps = {
  order: Order;
};

export const ConcludedSummary: React.VFC<ConcludedSummaryProps> = ({ order }) => {
  return (
    <S.Wrapper>
      <S.Container>
        <S.Header>
          <S.TitleContainer>
            <NextImage height={10} width={10} src="/icons/concluded-status.svg" />
            <S.Title>Pedido concluído</S.Title>
          </S.TitleContainer>

          <S.Date>{formatDateToString(order.createdAt)}</S.Date>
        </S.Header>
      </S.Container>

      <OrderInfo order={order} />
    </S.Wrapper>
  );
};
