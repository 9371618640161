import styled, { DefaultTheme } from "styled-components";

const desktop = ({ theme }: { theme: DefaultTheme }) =>
  `min-width: ${theme.screens.l.min}`;

type HeaderProps = {
  show?: boolean;
};

export const HeaderContainer = styled.div<HeaderProps>`
  position: fixed;
  top: 0px;
  display: block;
  flex-direction: column;
  width: 100dvw;
  z-index: 1;
  transition: all 200ms;
  transform: ${({ show }) => (show ? "translateY(0)" : "translateY(-100px)")};
`;

export const SearchContainer = styled.div<HeaderProps>`
  padding: 8px;
  padding-bottom: 4px;
  background-color: white;
  position: relative;
  z-index: 1;
  display: ${({ show }) => (show ? "block" : "none")};

  [role="combobox"] > :first-child {
    input {
      padding: 6px 12px;
    }
  }
`;

export const Header = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  overflow-x: auto;
  scrollbar-width: none;

  background-color: white;
  transition: all 500ms ease;
  box-shadow: 0 0 6px 2px #00000017;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.larger};

  @media (${desktop}) {
    gap: ${({ theme }) => theme.space.largest};
    margin-top: 0;
  }
`;
