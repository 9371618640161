import { snackbar } from "@ifood/pomodoro-components";
import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";
import { useCallback, useState } from "react";

export function useMerchantAvailability() {
  const { merchant, setMerchant } = useMerchant();
  const [loading, setLoading] = useState(false);

  const checkAvailability = useCallback(async () => {
    try {
      setLoading(true);

      const available = await merchant.getAvailability();
      setMerchant(merchant);
      setLoading(false);
      return available;
    } catch (error: any) {
      setLoading(false);

      if (error.isAxiosError && !error.response) {
        snackbar({
          variant: "error",
          message: "Por favor, verifique sua internet e tente outra vez.",
        });
        return;
      }

      snackbar({
        variant: "error",
        message:
          "Não conseguimos verificar se o restaurante está aberto, por favor tente novamente mais tarde.",
      });
    }

    setLoading(false);
    return;
  }, [setLoading, merchant, setMerchant]);

  return {
    loading,
    checkAvailability,
  };
}
