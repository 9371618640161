import { Heading } from "@whitelabel-webapp/shared/design-system";
import { Text } from "@whitelabel-webapp/shared/design-system";
import {
  Folders,
  Sizes,
  getImageURL,
} from "@whitelabel-webapp/shared/image-utils";
import styled from "styled-components";
import { DefaultTheme } from "styled-components";

type Themed = { theme: DefaultTheme };
const belowDesktop = ({ theme }: Themed) => `max-width: ${theme.screens.m.max}`;

const imageLoader = ({ src }: { src: string }) =>
  getImageURL(Folders.DISH, src, Sizes.LOW);

export const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.smaller};
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.space.regular};

  &:last-child {
    border: 0;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
`;

export const GarnishItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.space.smaller};

  color: ${({ theme }) => theme.colors.grayDarkest};
  font-weight: 200;

  &:last-child {
    margin-bottom: ${({ theme }) => theme.space.none};
  }
`;

export const GarnishQuantity = styled.span`
  margin-right: ${({ theme }) => theme.space.smallest};
  padding: ${({ theme }) => theme.space.smallest};

  background-color: ${({ theme }) => theme.colors.grayLight};
  border-radius: ${({ theme }) => theme.radii.s};
`;

export const GarnishName = styled(Text)`
  margin-bottom: ${({ theme }) => theme.space.none};
`;

export const ImageWrapper = styled.div`
  flex: none;
  height: 52px;
  border-radius: 12px;
  @media (${belowDesktop}) {
    border-radius: 8px;
    height: 42px;
  }

  overflow: hidden;
  aspect-ratio: 1;
  background-color: white;
  border: solid 4px white;
  filter: brightness(0.93);
  position: relative;
`;

export const Observation = styled(Text)`
  margin-bottom: ${({ theme }) => theme.space.small};

  color: ${({ theme }) => theme.colors.grayDarkest};
  font-weight: 200;
  line-break: anywhere;
`;

export const StrikethroughedText = styled(Text)`
  text-decoration-line: line-through;
`;

export const EditQuantity = styled.div`
  width: 128px;

  & > div > div {
    padding: 0;
    border: none;
    height: inherit;
  }
`;

export const RemoveItem = styled(Text)`
  cursor: pointer;
  &:hover {
    filter: brightness(0.8);
  }
`;

export const ItemName = styled(Heading).attrs({
  forwardedAs: "h5",
})`
  margin: 0;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.grayDarkest};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: ${({ theme }) => theme.fontSizes.m};
  line-height: ${({ theme }) => theme.lineHeights.m};

  @media (${belowDesktop}) {
    font-size: ${({ theme }) => theme.fontSizes.tsm};
    line-height: ${({ theme }) => theme.lineHeights.s};
  }
`;
