import { Flex } from "@ifood/pomodoro-components";
import styled from "styled-components";

const bellowDesktop = ({ theme }: { theme: any }) =>
  `max-width: ${theme.screens.l.min}`;

export const DeliverySection = styled(Flex)`
  font-family: ${({ theme }) => theme.font.family.ifood.body};
  gap: ${({ theme }) => theme.space.smallest};
  color: ${({ theme }) => theme.colors.gray};
  border: solid 1px ${({ theme }) => theme.colors.gray};
  background-color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: ${({ theme }) => theme.lineHeights.s};
  width: -webkit-fill-available;
  border-radius: 8px;
  padding: 13px;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grayLight};
  }

  @media (${bellowDesktop}) {
    border: solid 1px transparent;
    background-color: #f7f7f7;
  }
`;
