export const LABELS = {
  DOCUMENT_HEADING_TEXT: 'CPF/CNPJ na nota',
  ADD_DOCUMENT_DIALOG_BUTTON_TEXT: 'Adicionar',
  ADD_DOCUMENT_DIALOG_BUTTON_LABEL: 'Adicionar CPF/CNPJ na nota',
  SWITCH_DOCUMENT_DIALOG_BUTTON_TEXT: 'Trocar',
  SWITCH_DOCUMENT_DIALOG_BUTTON_LABEL: 'Trocar CPF/CNPJ na nota',
  INVALID_DOCUMENT_MESSAGE: 'Digite um CPF/CNPJ válido.',
  SUBMIT_DOCUMENT_BUTTON_TEXT: 'Adicionar documento',
  CLOSE_DOCUMENT_DIALOG_BUTTON_TEXT: 'Cancelar',
};
