import { GarnishItem, GarnishItemJSON, GarnishItemResponse } from '@whitelabel-webapp/catalog/shared/garnish-item';

export type ChoiceResponse = {
  code: string;
  name: string;
  min: number;
  max: number;
  order: number;
  garnishItens: GarnishItemResponse[];
};

export type ChoiceJSON = {
  order: number;
  code: string;
  name: string;
  min: number;
  max: number;
  items: GarnishItemJSON[];
};

const PIZZA_FLAVOR_CODE_REGEX = /^(SBR|SABOR)/;

export class Choice {
  static fromApi(rawChoice: ChoiceResponse) {
    return new Choice(
      rawChoice.order,
      rawChoice.code,
      rawChoice.name,
      rawChoice.min,
      rawChoice.max,
      rawChoice.garnishItens.map((item) => GarnishItem.fromApi(item))
    );
  }

  static fromJSON(rawChoice: ChoiceJSON) {
    return new Choice(
      rawChoice.order,
      rawChoice.code,
      rawChoice.name,
      rawChoice.min,
      rawChoice.max,
      rawChoice.items.map((item) => GarnishItem.fromJSON(item))
    );
  }

  constructor(
    public order: number,
    public code: string,
    public name: string,
    public min: number,
    public max: number,
    public items: GarnishItem[]
  ) {}

  toJSON(): ChoiceJSON {
    return {
      order: this.order,
      code: this.code,
      name: this.name,
      min: this.min,
      max: this.max,
      items: this.items.map((garnishItem) => garnishItem.toJSON()),
    };
  }

  isPizzaFlavor() {
    return PIZZA_FLAVOR_CODE_REGEX.test(this.code);
  }

  isMandatory() {
    return this.min >= 1;
  }

  isUniqueChoice() {
    return this.max === 1;
  }

  isMultiChoice() {
    return this.max !== 1;
  }

  isVisible() {
    if (this.max === 0) {
      return false;
    }

    return true;
  }
}
