import styled, { DefaultTheme } from "styled-components";

type Themed = { theme: DefaultTheme };
const belowDesktop = ({ theme }: Themed) => `max-width: ${theme.screens.m.max}`;

export const AddItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `0 ${theme.space.largest}`};

  @media (${belowDesktop}) {
    padding: ${({ theme }) => `0 ${theme.space.regular}`};
  }
`;
