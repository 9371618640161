import { useCheckout } from "@app/domains/checkout";
import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";
import { useMemo } from "react";

export const useOrderValidator = () => {
  const { order } = useCheckout();
  const { merchant } = useMerchant();
  const { paymentMethod, totalOrder } = order;

  const paymentValidate = useMemo<boolean>(() => {
    if (
      !paymentMethod ||
      (paymentMethod.method.name === "CASH" &&
        paymentMethod.cashChange &&
        paymentMethod.cashChange.getValue() < totalOrder.getValue())
    )
      return false;
    return true;
  }, [paymentMethod, totalOrder]);

  const isDocumentRequiredButMissing =
    merchant.isDocumentRequired && !order.document;
  const isPaymentInvalid = !paymentValidate;
  const isAddressComplementMissing =
    merchant.hasIndoorStoreType() && !order.address.complement;
  const isMissingValue =
    order.totalItems.getValue() < merchant.minimumOrderValue.getValue();

  return {
    isDocumentRequiredButMissing,
    isPaymentInvalid,
    isAddressComplementMissing,
    isMissingValue,
  };
};
