import { groceriesApiBffURL } from "@whitelabel-webapp/shared/config";
import {
  capitalizeFirstLetter,
  normalizeAndLowerCase,
} from "@whitelabel-webapp/shared/string-utils";
import { MerchantResponse } from "@whitelabel-webapp/merchant/shared/models";
import axios, { AxiosInstance } from "axios";

import { Category, CategoryResponse } from "./category";
import { Item, ItemResponse } from "./item";

export type Metadata = {
  filters: any;
  pagination: {
    items: number;
    pages: number;
  };
};

export type CatalogLink = {
  uuid: string;
  name: string;
};

export type CatalogResponse = {
  categories: CategoryResponse[];
  metadata?: Metadata;
  links?: CatalogLink[];
};

export class Catalog {
  static ssrClient: AxiosInstance;

  static initSsrClient(): void {
    Catalog.ssrClient = axios.create({
      baseURL: groceriesApiBffURL,
      headers: {
        ssr: "true",
        "X-Ifood-Requester-Service": "whitelabel-webapp",
      },
    });
  }

  static async getSSRCatalog(
    uuid: string,
    catalogGroup: string,
  ): Promise<CatalogResponse> {
    if (!Catalog.ssrClient) {
      Catalog.initSsrClient();
    }

    const linksPromise = this.getCatalogLinks(uuid, catalogGroup);
    const catalogPromise = Catalog.ssrClient.get<{
      data: { menu: CategoryResponse[] };
    }>(`/catalog/${uuid}`, {
      params: { catalog_group: catalogGroup, category_items_size: 12 },
    });

    const [catalogData, links] = await Promise.all([
      catalogPromise,
      linksPromise,
    ]);

    return {
      categories: catalogData.data.data.menu,
      links,
    } as CatalogResponse;
  }

  static async getSSRCategoryCatalog(
    uuid: string,
    catalogGroup: string,
    categoryCode: string,
    page?: number,
  ): Promise<CatalogResponse> {
    if (!Catalog.ssrClient) {
      Catalog.initSsrClient();
    }

    const linksPromise = this.getCatalogLinks(uuid, catalogGroup);
    const categoryPromise = Catalog.ssrClient.get<{
      data: { categoryMenu: CategoryResponse; metadata: Metadata };
    }>(`/v1/merchants/${uuid}/catalog-category/${categoryCode}`, {
      params: {
        catalog_group: catalogGroup,
        items_page: page ?? 1,
        items_size: 48,
      },
    });

    const [categoryData, links] = await Promise.all([
      categoryPromise,
      linksPromise,
    ]);

    return {
      categories: [categoryData.data.data.categoryMenu],
      metadata: categoryData.data.data.metadata,
      links,
    } as CatalogResponse;
  }

  static async getSSRItemCatalog(
    uuid: string,
    itemCode: string,
    catalogGroup: string,
  ): Promise<CatalogResponse> {
    if (!Catalog.ssrClient) Catalog.initSsrClient();
    const itemPromise = Item.getSSRItem(itemCode, uuid, catalogGroup);
    const linksPromise = this.getCatalogLinks(uuid, catalogGroup);
    const [{ itemResponse, category }, links] = await Promise.all([
      itemPromise,
      linksPromise,
    ]);

    const categoryData = await Catalog.ssrClient.get<{
      data: { categoryMenu: CategoryResponse; metadata: Metadata };
    }>(`/v1/merchants/${uuid}/catalog-category/${category.code}`, {
      params: { catalog_group: catalogGroup, items_page: 1, items_size: 12 },
    });

    const itens = categoryData.data.data.categoryMenu.itens ?? [];

    if (!itens.find((item) => item.id == itemResponse.id)) {
      itens.push(itemResponse);
    }

    let categoryResponse: CategoryResponse = {
      code: category.code,
      name: category.name,
      itens: itens,
      order: 1,
    };

    return {
      categories: [categoryResponse],
      links: links,
    } as CatalogResponse;
  }

  static async getSSRSearchCatalog(
    uuid: string,
    catalogGroup: string,
    term: string,
    page?: number,
  ): Promise<CatalogResponse> {
    if (!Catalog.ssrClient) {
      Catalog.initSsrClient();
    }

    const linksPromise = this.getCatalogLinks(uuid, catalogGroup);
    const searchPromise = Catalog.ssrClient.get<{
      items: { data: any[] };
    }>(`/search/catalog-items`, {
      params: {
        setup_context: "WHITELABEL",
        item_from_merchant_ids: uuid,
        channel: "IFOOD",
        page: page ?? 0,
        size: 48,
        term,
      },
    });

    const [searchResult, links] = await Promise.all([
      searchPromise,
      linksPromise,
    ]);

    const itens: ItemResponse[] = searchResult.data.items.data.map(
      (searchItem, index) => {
        return {
          order: index,
          id: searchItem.id,
          code: searchItem.code,
          details: searchItem.description,
          description: searchItem.name,
          unitPrice: searchItem.price,
          unitMinPrice: searchItem.price,
          unitOriginalPrice:
            searchItem.promotionPrice ||
            searchItem.originalPrice ||
            searchItem.price,
          logoUrl:
            searchItem.resources.length > 0
              ? searchItem.resources.at(0).fileName
              : null,
        } as ItemResponse;
      },
    );

    return {
      categories: [
        {
          order: 1,
          code: term,
          itens: itens,
          name: "Resultados da busca",
        },
      ],
      links,
    } as CatalogResponse;
  }

  static async getSSRAisleCatalog(
    merchantResponse: MerchantResponse,
    catalogGroup: string,
    slug: string,
  ): Promise<CatalogResponse> {
    if (!Catalog.ssrClient) {
      Catalog.initSsrClient();
    }

    const linksPromise = this.getCatalogLinks(
      merchantResponse.uuid,
      catalogGroup,
    );
    const aislePromise = Catalog.ssrClient.get<any[]>(
      `/aisle/catalog/${slug}`,
      {
        params: {
          merchant_id: merchantResponse.uuid,
          latitude: merchantResponse.address.latitude,
          longitude: merchantResponse.address.longitude,
        },
      },
    );

    const [aisleResult, links] = await Promise.all([
      aislePromise,
      linksPromise,
    ]);

    const itens: ItemResponse[] = aisleResult.data.map((aisleItem, index) => {
      return {
        order: index,
        id: aisleItem.id,
        code: aisleItem.id,
        details: aisleItem.description,
        description: aisleItem.name,
        unitPrice: aisleItem.price,
        unitMinPrice: aisleItem.price,
        unitOriginalPrice:
          aisleItem.promotionPrice ||
          aisleItem.originalPrice ||
          aisleItem.price,
        logoUrl:
          aisleItem.resources?.length > 0
            ? aisleItem.resources.at(0).fileName
            : null,
      } as ItemResponse;
    });

    return {
      categories: [
        {
          order: 1,
          code: slug,
          itens: itens,
          name: capitalizeFirstLetter(slug.replaceAll("-", " ")),
        },
      ],
      links,
    } as CatalogResponse;
  }

  static async getCatalogLinks(
    uuid: string,
    catalogGroup: string,
  ): Promise<CatalogLink[]> {
    if (!Catalog.ssrClient) {
      Catalog.initSsrClient();
    }
    const { data } = await Catalog.ssrClient.get<{
      data: { menu: CategoryResponse[] };
    }>(`/catalog/${uuid}`, {
      params: { catalog_group: catalogGroup, category_items_size: 0 },
    });

    return data.data.menu.map((category) => {
      return {
        uuid: category.code,
        name: category.name,
      } as CatalogLink;
    });
  }

  static fromApi(rawCatalog: CatalogResponse) {
    const links = rawCatalog.links;
    const metadata = rawCatalog.metadata;
    const categories = rawCatalog?.categories.map((category) =>
      Category.fromApi(category),
    );
    return new Catalog(categories, links, metadata);
  }

  static getAllItems(catalogResponse: CatalogResponse): Item[] {
    return catalogResponse.categories.reduce<Item[]>(
      (acc, category) =>
        acc.concat(
          category.itens.map((item) =>
            Item.fromApi(item, category.name, category.code),
          ),
        ),
      [],
    );
  }

  constructor(
    public categories: Category[],
    public links?: CatalogLink[],
    public metadata?: Metadata,
  ) {}

  isEmpty() {
    return !this.categories.some((category) => category.isVisible());
  }

  getVisibleCategories(): Category[] {
    return this.categories.filter((category) => category.isVisible());
  }

  searchItems(searchText: string): Item[] {
    if (!this.categories || !searchText) return [];

    const filteredItems: Item[] = [];
    const normalizedSearchText = normalizeAndLowerCase(searchText);

    const hasSearchText = (text: string) =>
      normalizeAndLowerCase(text).includes(normalizedSearchText);

    this.getVisibleCategories().forEach((category) => {
      if (!category.items || category.items.length <= 0) return;

      const items: Item[] = category.items.filter(
        (item) =>
          hasSearchText(item.details) || hasSearchText(item.description),
      );

      if (items.length > 0) {
        filteredItems.push(...items);
      }
    });

    return filteredItems;
  }
}
