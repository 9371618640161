type Callback<Value = string> = (value: Value) => void;

const globalState = {};

const getGlobalState = <Value>() => {
  return globalState as Record<string, { callbacks: Callback<Value>[]; value: Value }>;
};

export type GlobalState = ReturnType<typeof createGlobalState>;

export const createGlobalState = <Key extends string, Value>(
  key: Key,
  thisCallback: Callback<Value | undefined>,
  initialValue?: Value
) => {
  const globalState = getGlobalState<Value | undefined>();

  if (!globalState[key]) {
    globalState[key] = { callbacks: [], value: initialValue };
  }
  globalState[key].callbacks.push(thisCallback);
  return {
    deregister() {
      const arr = globalState[key].callbacks;
      const index = arr.indexOf(thisCallback);
      if (index > -1) {
        arr.splice(index, 1);
      }
    },
    emit(value: Value) {
      if (globalState[key].value !== value) {
        globalState[key].value = value;
        globalState[key].callbacks.forEach((callback: Callback<Value>) => {
          if (thisCallback !== callback) {
            callback(value);
          }
        });
      }
    },
  };
};

export default createGlobalState;
