import { checkoutAboyeur } from '@whitelabel-webapp/checkout/shared/config';
import { Button, DrawerWithPush, Text } from '@whitelabel-webapp/shared/design-system';
import { Money } from '@whitelabel-webapp/shared/models';
import { useEffect } from 'react';

import * as S from './styles';

export type DeliveryFeeHasChangedProps = {
  deliveryFee: Money;
  onConfirm: () => void;
  onClose: () => void;
};

export const DeliveryFeeHasChanged: React.VFC<DeliveryFeeHasChangedProps> = ({ deliveryFee, onConfirm, onClose }) => {
  useEffect(() => {
    checkoutAboyeur.events.divergentDeliveryFee.open();

    return function unmount() {
      checkoutAboyeur.events.divergentDeliveryFee.close();
    };
  }, []);

  function handleConfirm() {
    onConfirm();
    onClose();
    checkoutAboyeur.events.divergentDeliveryFee.confirm();
  }

  function handleClose() {
    onClose();
    checkoutAboyeur.events.divergentDeliveryFee.cancel();
  }

  return (
    <DrawerWithPush title="O preço da entrega mudou" open={true} onClose={onClose}>
      <S.Wrapper>
        <S.Body>
          <Text>
            A taxa de entrega do seu pedido mudou pra <b>{deliveryFee.format()}</b> por conta da falta de entregadores
            parceiros iFood na região.
          </Text>
        </S.Body>
        <S.Footer>
          <Button onClick={handleConfirm}>Continuar pedido</Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar pedido
          </Button>
        </S.Footer>
      </S.Wrapper>
    </DrawerWithPush>
  );
};
