import { Button, Flex } from "@whitelabel-webapp/shared/design-system";
import styled from "styled-components";

type ContainerCurrencyInputType = {
  children: {
    props: {
      value: string;
    };
  };
};

export const MoneyButton = styled(Button)`
  width: 100%;
  margin-bottom: 0 !important;
`;

export const ContainerMoneyButtons = styled(Flex)`
  column-gap: 20px;
  row-gap: 16px;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;

  @media screen and (max-width: 460px) {
    flex-direction: column;
  }

  & button {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
  }
`;

export const ContainerCurrencyInput = styled(Flex)<ContainerCurrencyInputType>`
  margin-top: ${({ theme }) => theme.space.regular};
  margin-bottom: ${({ theme }) => theme.space.large};

  & div > div {
    display: flex;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: center;

    border: none !important;
    box-shadow: none !important;
    & > input {
      width: 100%;
      min-width: 30px;
      border-radius: 6px;
      border: 1px solid ${({ theme }) => theme.colors.gray};
    }
  }
`;
