import { checkoutAboyeur } from "@whitelabel-webapp/checkout/shared/config";
import { Item } from "@whitelabel-webapp/checkout/shared/models";
import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";
import { Text } from "@whitelabel-webapp/shared/design-system";
import NextImage from "next/image";

import * as S from "./styles";
import { Flex } from "@ifood/pomodoro-components";
import { useIsMobile } from "@whitelabel-webapp/shared/hooks";
import { QuickAddItem } from "@app/domains/item";
import { useCheckout } from "@app/domains/checkout/context";
import { Sizes, withoutLoader } from "@whitelabel-webapp/shared/image-utils";

type ItemProps = {
  item: Item;
};

export const ListItem: React.VFC<ItemProps> = ({ item }) => {
  const { merchant } = useMerchant();
  const { removeItem } = useCheckout();
  const isMobile = useIsMobile();

  const handleRemoveItemClick = () => {
    removeItem(catalogItem);
  };

  const { catalogItem, choices } = item;
  const isPromotionalItem = catalogItem.isPromotionalItem();
  const unit = catalogItem.getFormattedUnit();

  return (
    <S.ListItem>
      <S.Container>
        <S.ImageWrapper>
          <NextImage
            loader={withoutLoader}
            src={catalogItem.getLogoUrl(Sizes.LOW)}
            alt={catalogItem.details}
            layout="fill"
            objectFit="cover"
          />
        </S.ImageWrapper>
        <S.Description>
          <Flex justifyContent="center" flexDirection="column" maxWidth="50%">
            <S.ItemName>
              {item.quantity}x {catalogItem.description}
            </S.ItemName>
            {choices.map((choice) =>
              choice.items.map((garnishItem) => (
                <S.GarnishItem key={garnishItem.id}>
                  <S.GarnishQuantity>{garnishItem.quantity}</S.GarnishQuantity>
                  <S.GarnishName>
                    {garnishItem.catalogGarnishItem.description}
                  </S.GarnishName>
                </S.GarnishItem>
              )),
            )}
            {item.observation.length ? (
              <S.Observation>Obs: {item.observation}</S.Observation>
            ) : null}
          </Flex>
          <Flex justifyContent="center" flexDirection="column" gap="smallest">
            <Flex gap="small" justifyContent="right">
              {isPromotionalItem && (
                <S.StrikethroughedText
                  variant="large"
                  color="grayDarker"
                  fontWeight="400"
                  fontSize={isMobile ? "14px" : "18px"}
                >
                  {item.calculateOriginalPrice(merchant).format()}
                </S.StrikethroughedText>
              )}
              <Text
                fontWeight="400"
                color="positive"
                variant="large"
                fontSize={isMobile ? "14px" : "18px"}
              >
                {item.calculatePrice(merchant).format()}
              </Text>
            </Flex>
            <Text
              fontWeight="400"
              color="grayDarkest"
              variant="small"
              textAlign="right"
            >
              {catalogItem.isWeigthVariable()
                ? `${catalogItem.getWeightPrice().format()} / kg`
                : `${catalogItem.unitPrice.format()} / ${unit}`}
            </Text>
          </Flex>
        </S.Description>
      </S.Container>
      <Flex alignItems="center" justifyContent="space-between">
        <S.RemoveItem
          onClick={handleRemoveItemClick}
          color="grayDarker"
          fontWeight="bold"
        >
          Remover
        </S.RemoveItem>
        <S.EditQuantity>
          <QuickAddItem item={catalogItem}></QuickAddItem>
        </S.EditQuantity>
      </Flex>
    </S.ListItem>
  );
};
