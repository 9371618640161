import { Choice as CatalogChoice } from '@whitelabel-webapp/catalog/shared/models';
import { CheckoutGarnishItem, GarnishItem, GarnishItemJSON } from '@whitelabel-webapp/checkout/shared/garnish-item';

export type CheckoutChoice = {
  code: string;
  garnishItens: CheckoutGarnishItem[];
};

export type ChoiceJSON = {
  code: string;
  items: GarnishItemJSON[];
};

export class Choice {
  static fromCatalog(choice: CatalogChoice, items: GarnishItem[]) {
    return new Choice(choice.code, items);
  }

  static fromJSON(rawChoice: ChoiceJSON) {
    const garnishItems = rawChoice.items.map((item) => GarnishItem.fromJSON(item));
    return new Choice(rawChoice.code, garnishItems);
  }

  constructor(public code: string, public items: GarnishItem[]) {}

  toJSON(): ChoiceJSON {
    return {
      code: this.code,
      items: this.items.map((item) => item.toJSON()),
    };
  }

  toCheckout(): CheckoutChoice {
    return {
      code: this.code,
      garnishItens: this.items.map((item) => item.toCheckout()),
    };
  }
}
