import { Item } from "@whitelabel-webapp/catalog/shared/models";
import { SpaceProps } from "styled-system";

import * as S from "./styles";
import { useRouter } from "next/router";
import { Flex } from "@ifood/pomodoro-components";

type HeaderProps = SpaceProps & {
  name: string;
  items?: Item[];
  route?: string;
  subtitle?: string;
  enableViewMore?: boolean;
};

export const Header: React.VFC<HeaderProps> = ({
  name,
  items,
  route,
  subtitle,
  ...props
}) => {
  const router = useRouter();

  const subtitleFormatted =
    subtitle ?? `${items?.length} ${items?.length > 1 ? "opções" : "opção"}`;
  const enableSubtitle = subtitle || items?.length > 0;

  const handleViewMore = () => router.push(route);

  return (
    <S.Container {...props}>
      <Flex maxWidth="calc(100% - 70px)" flexDirection="column">
        <S.Title>{name}</S.Title>
        {enableSubtitle && <S.Subtitle>{subtitleFormatted}</S.Subtitle>}
      </Flex>
      {route && <S.ViewMore onClick={handleViewMore}>Ver mais</S.ViewMore>}
    </S.Container>
  );
};
