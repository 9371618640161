import { Text } from "@ifood/pomodoro-components";
import {
  Flex,
  Button as PomodoroButton,
  Icon as PomodoroIcon,
  TextLink as PomodoroTextLink,
  Share,
} from "@whitelabel-webapp/shared/design-system";
import styled from "styled-components";
import { DefaultTheme } from "styled-components";

const belowDesktop = ({ theme }: { theme: DefaultTheme }) =>
  `max-width: ${theme.screens.m.max}`;

export const Wrapper = styled.div`
  flex-direction: column;
  height: 100%;
  @media (${belowDesktop}) {
    margin-top: -16px;
  }
`;

export const Container = styled(Flex)`
  flex-direction: column;
  height: 100%;
`;

export const Body = styled(Flex)`
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 0 1px;
  overflow: auto;
  gap: 32px;

  @media (${belowDesktop}) {
    padding: 0 16px;
    gap: 12px;
  }
`;

export const Icon = styled(PomodoroIcon)`
  color: ${({ theme }) => theme.colors.primary};

  border-radius: ${(props) => props.theme.radii.l};
`;

export const CatalogShare = styled(Share)`
  display: flex;
  padding: 0;
  gap: 32px;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  margin-left: auto;
  @media (${belowDesktop}) {
    margin-left: 0;
  }
`;

export const Header = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  gap: 16px;

  @media (${belowDesktop}) {
    margin-top: 12px;
    gap: 16px;
  }
`;

export const HelpButton = styled(PomodoroButton).attrs({ variant: "tertiary" })`
  padding: 0;

  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-family: ${({ theme }) => theme.fonts.primary};
`;

export const TextLink = styled(PomodoroTextLink)`
  display: flex;
  gap: 4px;

  color: ${({ theme }) => theme.colors.primary};
`;

export const OrderGroup = styled(Flex)`
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  h2::first-letter {
    text-transform: uppercase;
  }
`;

export const Grid = styled.div`
  gap: 24px;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));

  @media (${belowDesktop}) {
    gap: 16px;
  }
`;

export const BreadcrumbDesktop = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space.smaller};

  & a {
    color: ${({ theme }) => theme.colors.grayDarkest};
    font-family: ${({ theme }) => theme.font.family.ifood.body};
    font-size: ${({ theme }) => theme.fontSizes.tsm};
    line-height: ${({ theme }) => theme.fontSizes.tsm};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  & div {
    color: ${({ theme }) => theme.colors.gray};
  }

  & :last-child {
    color: ${({ theme }) => theme.colors.black};
    font-weight: 500;
  }

  @media (${belowDesktop}) {
    display: none;
  }
`;

export const BreadcrumbMobile = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  width: -webkit-fill-available;
  position: sticky;
  top: 0;
  padding: 12px 16px;
  background: white;
  z-index: 1;
  box-shadow: 0 0 6px 2px #00000017;

  svg {
    position: absolute;
    top: 12px;
    left: 16px;
    path {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  span {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
  }

  @media (${belowDesktop}) {
    display: flex;
  }
`;

export const Title = styled(Text)`
  width: 100%;
  font-weight: 700;
  align-self: self-start;
  font-size: ${({ theme }) => theme.fontSizes.xl};
  line-height: ${({ theme }) => theme.lineHeights.l};

  @media (${belowDesktop}) {
    display: none;
  }
`;
