import { motion } from "framer-motion";
import styled from "styled-components";

import { Active } from "../types";

export const Button = styled(motion.button)<Active>`
  box-sizing: border-box;

  margin: 2px;
  margin-bottom: ${(props): string => props.theme.space.smallest};

  padding: ${(props): string =>
    `${props.theme.space.smallest} ${props.theme.space.regular}`};

  color: ${(props): string =>
    props.active ? props.theme.colors.primary : props.theme.colors.grayDarker};
  font-size: ${(props): string => props.theme.fontSizes.tsm};
  font-family: ${(props): string => props.theme.fonts.primary};
  line-height: ${(props): string => props.theme.lineHeights.m};
  font-weight: ${(props): string => (props.active ? "500" : "400")};
  white-space: nowrap;
  text-align: center;
  text-overflow: ellipsis;

  background-color: ${(props): string => props.theme.colors.white};
  border: none;
  outline: none;
  cursor: pointer;

  :hover {
    color: ${(props): string =>
      props.active
        ? props.theme.colors.primary
        : props.theme.colors.grayDarkest};

    background-color: ${(props): string => props.theme.colors.white};
  }

  &:focus {
    border-radius: ${(props): string => props.theme.radii.m};
    outline: ${(props): string => props.theme.colors.focus} auto 1px;
  }
`;
