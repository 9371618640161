import {
  Item as CatalogItem,
  ItemJSON as CatalogItemJSON,
} from "@whitelabel-webapp/catalog/shared/models";
import { GarnishItem } from "@whitelabel-webapp/checkout/shared/garnish-item";
import { Merchant } from "@whitelabel-webapp/merchant/shared/models";
import cloneDeep from "lodash.clonedeep";

import { CheckoutChoice, Choice, ChoiceJSON } from "./choice";

export type PayloadItem = {
  id: string;
  code: string;
  description: string;
  unitPrice: number;
  tags: [];
  obs: string;
  qty: number;
  choices: CheckoutChoice[];
};

export type ItemJSON = {
  id: string;
  instanceId: string;
  catalogItem: CatalogItemJSON;
  quantity: number;
  choices: ChoiceJSON[];
  observation?: string;
};

export class Item {
  static fromCatalogItem(
    item: CatalogItem,
    quantity: number,
    choices: Choice[],
    observation?: string,
  ): Item {
    const { id, instanceId } = item;

    return new Item(
      id,
      cloneDeep(item),
      quantity,
      choices,
      observation,
      instanceId,
    );
  }

  static fromJSON(rawItem: ItemJSON) {
    const catalogItem = CatalogItem.fromJSON(rawItem.catalogItem);
    const choices = rawItem.choices.map((choice) => Choice.fromJSON(choice));

    return new Item(
      rawItem.id,
      catalogItem,
      rawItem.quantity,
      choices,
      rawItem.observation,
      rawItem.instanceId,
    );
  }

  constructor(
    public id: string,
    public catalogItem: CatalogItem,
    public quantity: number,
    public choices: Choice[],
    public observation: string = "",
    public instanceId: string = "",
  ) {}

  toJSON(): ItemJSON {
    return {
      id: this.id,
      instanceId: this.instanceId,
      catalogItem: this.catalogItem.toJSON(),
      choices: this.choices.map((choice) => choice.toJSON()),
      quantity: this.quantity,
      observation: this.observation,
    };
  }

  calculateOriginalPrice(merchant: Merchant) {
    const selectGarnishItems = this.choices.reduce(
      (map: Record<string, GarnishItem[]>, obj) => {
        map[obj.code] = obj.items;
        return map;
      },
      {},
    );
    return this.catalogItem.calculateOriginalPrice(
      merchant,
      this.quantity,
      selectGarnishItems,
    );
  }

  calculatePrice(merchant: Merchant) {
    const selectGarnishItems = this.choices.reduce(
      (map: Record<string, GarnishItem[]>, obj) => {
        map[obj.code] = obj.items;
        return map;
      },
      {},
    );
    return this.catalogItem.calculatePrice(
      merchant,
      this.quantity,
      selectGarnishItems,
    );
  }

  withQuantity(quantity: number): Item {
    this.quantity = quantity;
    return this;
  }

  toPayload(): PayloadItem {
    return {
      id: this.catalogItem.id,
      code: this.catalogItem.code,
      description: this.catalogItem.description,
      qty: this.quantity,
      unitPrice: this.catalogItem.unitPrice.getValue(),
      obs: this.observation,
      choices: this.choices.map((choice) => choice.toCheckout()),
      tags: [],
    };
  }
}
