import {
  Flex,
  Button as PomodoroButton,
} from "@whitelabel-webapp/shared/design-system";
import styled from "styled-components";

export const Wrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.space.regular};
`;

export const Button = styled(PomodoroButton)`
  display: flex;
  gap: ${({ theme }) => theme.space.regular};
  align-items: center;
  padding: ${({ theme }) => theme.space.regular};
  border: solid 1px ${({ theme }) => theme.colors.gray};
  background-color: ${({ theme }) => theme.colors.white};
  text-decoration: none !important;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grayLight} !important;
  }
`;

export const Brand = styled(Flex)`
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const BackButton = styled(PomodoroButton)`
  margin-top: auto;
  font-size: 16px;
  font-weight: 500;
`;
