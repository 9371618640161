import {
  PaymentMethod as PaymentMethodModel,
  PaymentMethods,
} from "@whitelabel-webapp/checkout/shared/models";
import NextImage from "next/image";
import * as S from "./styles";
import { Button, Flex, Text } from "@ifood/pomodoro-components";
import { usePaymentMethods } from "@app/domains/checkout";
import { useMemo } from "react";

export function getPaymentMethodTypeImageURL(paymentName: string) {
  const PaymentMethodTypeIconsPath = {
    Crédito: "/icons/payment-method-type/credit.svg",
    Dinheiro: "/icons/payment-method-type/cash.svg",
    "Vale-refeição": "/icons/payment-method-type/meal-voucher.svg",
    Débito: "/icons/payment-method-type/debit.svg",
  };

  type PaymentMethodTypeNames = keyof typeof PaymentMethodTypeIconsPath;

  return (
    PaymentMethodTypeIconsPath[paymentName as PaymentMethodTypeNames] || ""
  );
}

type SelectOfflinePaymentProps = {
  onBack: () => void;
  onSelectPaymentMethod: (
    value: PaymentMethodModel[],
    paymentName: string,
  ) => void;
};

export const SelectOfflinePayment: React.VFC<SelectOfflinePaymentProps> = ({
  onBack,
  onSelectPaymentMethod,
}) => {
  const { paymentMethods } = usePaymentMethods();

  const offlinePaymentsMethodGroupedByName = useMemo(() => {
    if (!paymentMethods || !paymentMethods.list.length) return {};
    const offlinePayments = paymentMethods.getOfflinePayments();
    if (!offlinePayments.length) return {};
    return PaymentMethods.generatePaymentMethodsGroupByName(offlinePayments);
  }, [paymentMethods]);

  const offlinePaymentsMethodNames = Object.keys(
    offlinePaymentsMethodGroupedByName,
  );

  return (
    <Flex
      flexDirection="column"
      gap="regular"
      height="100%"
      justifyContent="space-between"
    >
      <S.Wrapper>
        {offlinePaymentsMethodNames.map((paymentName) => {
          const paymentMethodTypeImageURL =
            getPaymentMethodTypeImageURL(paymentName);

          return (
            <S.Button
              variant="tertiary"
              key={paymentName}
              onClick={() =>
                onSelectPaymentMethod(
                  offlinePaymentsMethodGroupedByName[paymentName],
                  paymentName,
                )
              }
            >
              <S.Brand>
                {paymentMethodTypeImageURL && (
                  <NextImage
                    src={paymentMethodTypeImageURL}
                    height="20"
                    width="14"
                  />
                )}
              </S.Brand>
              <Text>{paymentName}</Text>
            </S.Button>
          );
        })}
      </S.Wrapper>
      <S.BackButton onClick={onBack}>Voltar</S.BackButton>
    </Flex>
  );
};
