import { PaymentMethod } from '@whitelabel-webapp/checkout/shared/models';

import { getBrandIconPath } from '@app/domains/checkout';

export function getBrandImageURL(paymentMethod: PaymentMethod) {
  if (!paymentMethod) {
    return '';
  }

  return getBrandIconPath(paymentMethod.brand.name, paymentMethod.method.description);
}
