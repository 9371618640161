import { useContext } from 'react';

import { CheckoutContext, CheckoutContextValue, CheckoutProvider } from '../provider';

export function useCheckout(): CheckoutContextValue {
  const checkout = useContext(CheckoutContext);

  if (typeof checkout === 'undefined') {
    throw new Error(`'useCheckout()' must be used within a '${CheckoutProvider.displayName}'`);
  }

  return checkout;
}
