import { Text } from "@whitelabel-webapp/shared/design-system";
import NextImage from "next/image";

import { getBrandIconPath } from "@app/domains/checkout";
import { Order } from "@app/domains/order";

import { PaymentMethod as PaymentMethodModel } from "../../models/paymentMethod";
import * as S from "./styles";

function getBrandImageURL(paymentMethod: PaymentMethodModel) {
  if (!paymentMethod) return "";
  return getBrandIconPath(
    paymentMethod.brand.name,
    paymentMethod.method.description,
  );
}

type PaymentMethodProps = {
  order: Order;
};

export const PaymentMethod: React.VFC<PaymentMethodProps> = ({ order }) => {
  const { paymentMethod } = order;
  const brandImageURL = getBrandImageURL(paymentMethod);

  return (
    <S.PaymentMethodContainer>
      {paymentMethod.isOnlinePayment() && "Pago pelo site"}
      {paymentMethod.isOfflinePayment() &&
        !order.isConcluded() &&
        "Pagar na entrega"}
      {paymentMethod.isOfflinePayment() &&
        order.isConcluded() &&
        "Pago na entrega"}
      <S.OrderPaymentMethod>
        {brandImageURL && (
          <NextImage
            height={16}
            width={24}
            src={brandImageURL}
            alt={paymentMethod.brand.description}
          />
        )}
        {paymentMethod.cardNumber && (
          <Text ml={5} fontWeight="500" variant="small" color="grayDarker">
            {paymentMethod.formatCardNumber()}
          </Text>
        )}
      </S.OrderPaymentMethod>
    </S.PaymentMethodContainer>
  );
};
