import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";
import { DrawerWithPushProps } from "@whitelabel-webapp/shared/design-system";
import { useIsMobile } from "@whitelabel-webapp/shared/hooks";
import { useEffect } from "react";

import { orderAboyeur } from "../../aboyeur";
import { Desktop } from "./Desktop";
import { Mobile } from "./Mobile";
import * as S from "./styles";

type UserOpinionProps = {
  onAnswerButtonClick: () => void;
  onNotAnswerButtonClick: () => void;
};

export const UserOpinion: React.VFC<DrawerWithPushProps & UserOpinionProps> = ({
  open,
  onClose,
  onAnswerButtonClick,
  onNotAnswerButtonClick,
}) => {
  const isMobile = useIsMobile();
  const { merchant } = useMerchant();
  const DialogToRender = isMobile ? Mobile : Desktop;

  useEffect(() => {
    orderAboyeur.events.userOpinionSurvey.view(merchant.id);
  }, [merchant.id]);

  return (
    <DialogToRender open={open} onClose={onClose}>
      <S.Wrapper>
        <S.OpinionDescription>
          Responda algumas perguntas sobre o nosso site e nos ajude a criar a
          melhor experiência pra você.
        </S.OpinionDescription>
        <S.Footer>
          <S.Button variant="secondary" onClick={onNotAnswerButtonClick}>
            Não desta vez
          </S.Button>
          <S.Button onClick={onAnswerButtonClick}>Quero responder</S.Button>
        </S.Footer>
      </S.Wrapper>
    </DialogToRender>
  );
};
