import styled from "styled-components";

export const Container = styled.div`
  display: block;
`;

export const LabelContainer = styled.div`
  display: flex;
  margin-bottom: ${(props) => props.theme.space.smaller};

  font-size: ${(props) => props.theme.fontSizes.s};

  font-family: ${(props) => props.theme.fonts.primary};
  line-height: ${(props) => props.theme.lineHeights.m};
`;

export const LabelCounter = styled.div`
  flex-grow: 1;

  color: ${({ theme }) => theme.colors.grayDarker};
  font-size: ${(props) => props.theme.fontSizes.xs};
  font-family: ${(props) => props.theme.fonts.primary};
  line-height: ${(props) => props.theme.lineHeights.m};
  text-align: right;
`;
