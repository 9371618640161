import { useEffect, useState } from 'react';

import * as S from './styles';

type ProgressBarProps = {
  orderStatus: string;
};

export const ProgressBar: React.VFC<ProgressBarProps> = ({ orderStatus }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const steps: Record<number, { bar1: string; bar2: string; bar3: string }> = {
    0: {
      bar1: '30%',
      bar2: '0%',
      bar3: '0%',
    },
    1: {
      bar1: '100%',
      bar2: '66%',
      bar3: '0%',
    },
    2: {
      bar1: '100%',
      bar2: '100%',
      bar3: '30%',
    },
    3: {
      bar1: '100%',
      bar2: '100%',
      bar3: '100%',
    },
  };

  useEffect(() => {
    setCurrentStep(getCurrentStep(orderStatus));
  }, [orderStatus]);

  function getCurrentStep(orderStatus: string) {
    switch (orderStatus) {
      case 'PLACED':
        return 0;
      case 'CONFIRMED':
      case 'GOING_TO_ORIGIN':
        return 1;
      case 'DISPATCHED':
      case 'COLLECTED':
      case 'GOING_TO_DESTINATION':
      case 'READY_FOR_PICKUP':
      case 'HANDSHAKE_CODE_VALUE':
        return 2;
      case 'CONCLUDED':
        return 3;
    }
    return 0;
  }

  return (
    <S.Container>
      <S.Progress>
        <S.ProgressBar width={steps[currentStep].bar1} />
      </S.Progress>

      <S.Progress>
        <S.ProgressBar width={steps[currentStep].bar2} />
      </S.Progress>

      <S.Progress>
        <S.ProgressBar width={steps[currentStep].bar3} />
      </S.Progress>
    </S.Container>
  );
};
