import { Flex, Heading } from "@ifood/pomodoro-components";
import styled, { DefaultTheme } from "styled-components";

const belowDesktop = ({ theme }: { theme: DefaultTheme }) =>
  `max-width: ${theme.screens.m.max}`;

type Props = {
  active: boolean;
};

export const CarouselWrapper = styled.div`
  width: 100%;

  @media (${belowDesktop}) {
    & > div {
      margin: 0 -${({ theme }) => theme.space.regular};
    }
  }
`;

export const SlotCard = styled.div<Props>`
  display: flex;
  width: 100%;
  flex-direction: column;
  cursor: ${({ active }) => !active && "pointer"};
  justify-content: space-between;
  padding: ${({ theme }) => theme.space.smaller};
  border-radius: ${({ theme }) => theme.space.smallest};

  --gray: ${({ theme }) => theme.colors.gray};
  --black: ${({ theme }) => theme.colors.primary};
  --borderColor: ${({ active }) => (active ? "var(--black)" : "var(--gray)")};

  border: solid 1px var(--borderColor);
  background-color: white;

  &:hover {
    filter: ${({ active }) => (active ? "brightness(1)" : "brightness(0.97)")};
  }
`;

export const ScheduleButton = styled.button`
  font-weight: 700;
  padding: 0;
  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: ${({ theme }) => theme.lineHeights.m};
  color: ${({ theme }) => theme.colors.primary};
  margin-top: ${({ theme }) => theme.space.large};
  margin-bottom: ${({ theme }) => theme.space.none};
  font-family: ${({ theme }) => theme.font.family.ifood.headings};
  background-color: transparent;
  width: fit-content;
  cursor: pointer;
  border: none;

  &:hover {
    filter: brightness(0.8);
  }

  @media (${belowDesktop}) {
    margin-top: ${({ theme }) => theme.space.small};
  }
`;

export const Title = styled(Heading)`
  margin: 0;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizes.txl};
  line-height: ${({ theme }) => theme.lineHeights.m};

  @media (${belowDesktop}) {
    font-size: ${({ theme }) => theme.fontSizes.s};
  }
`;

export const Wrapper = styled(Flex)`
  flex-direction: column;
  margin-top: ${({ theme }) => theme.space.large};
  @media (${belowDesktop}) {
    max-width: calc(100dvw - 32px);
    margin-top: ${({ theme }) => theme.space.regular};
    padding-top: ${({ theme }) => theme.space.regular};
    border-top: solid 1px ${({ theme }) => theme.colors.grayLight};
  }
`;

export const EmptySlots = styled(Flex)`
  align-items: center;
  gap: ${({ theme }) => theme.space.smaller};
  margin-top: ${({ theme }) => theme.space.large};
  padding: ${({ theme }) => theme.space.regular};
  border-radius: ${({ theme }) => theme.radii.m};
  border: solid 1px;
  border-top: solid 4px;
  border-color: ${({ theme }) => theme.colors.error};

  svg {
    color: ${({ theme }) => theme.colors.error};
  }
`;
