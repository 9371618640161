import { GarnishItem } from '@whitelabel-webapp/catalog/shared/garnish-item';

import * as S from './styles';

type ItemProps = {
  item: GarnishItem;
};

export const ChoiceItem: React.FC<ItemProps> = ({ item, children }) => {
  return (
    <S.Wrapper justifyContent="space-between">
      <S.Container>
        <S.Name htmlFor={item.id}>{item.description}</S.Name>
        {item.details ? <S.Details>{item.details}</S.Details> : null}
        {item.unitPrice && item.unitPrice.getValue() > 0 ? <S.Price>+ {item.unitPrice.format()}</S.Price> : null}
      </S.Container>
      <S.Action>
        {item.logoUrl && <S.Image logoUrl={item.logoUrl} />}
        {children}
      </S.Action>
    </S.Wrapper>
  );
};
