import { useAuthentication } from "@whitelabel-webapp/authentication/shared/authentication-store";
/* eslint-disable max-lines */
import { AuthenticationError } from "@whitelabel-webapp/authentication/shared/models";
import {
  WHITELABEL_ACCOUNT_PREFIX,
  hasPaymentSecretCookie,
  removeAccessToken,
  removeRefreshToken,
  setAuthenticationCookies,
} from "@whitelabel-webapp/authentication/shared/utils";
import { checkoutAboyeur } from "@whitelabel-webapp/checkout/shared/config";
import {
  CardTokenResponse,
  Customer,
  PaymentMethod as PaymentMethodModel,
  PaymentMethods,
} from "@whitelabel-webapp/checkout/shared/models";
import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";
import { snackbar } from "@whitelabel-webapp/shared/design-system";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useCheckout, usePaymentMethods } from "@app/domains/checkout/context";
import { ChooseBrand } from "./Status/ChooseBrand";
import { DeleteCard } from "./Status/DeleteCard";
import { SelectOfflinePayment } from "./Status/SelectOfflinePayment";
import { SelectPaymentType } from "./Status/SelectPaymentType";
import { SelectOnlinePaymentCard } from "./Status/SelectOnlinePaymentCard";
import { LoadingPaymentMethods } from "./Status/LoadingPaymentMethods";
import { ErrorPaymentMethods } from "./Status/ErrorPaymentMethods";
import { Empty } from "./Status/Empty";
import { ChooseMoneyChange } from "./Status/ChooseMoneyChange";
import { ConfirmCVV } from "./Status/ConfirmCVV";

import { Flex } from "@ifood/pomodoro-components";
import { Statuses } from "./types";
import { AddOnlinePaymentCard } from "./Status/AddOnlinePaymentCard";

export type PaymentSelectorProps = {
  initialStatus?: Statuses;
  onStatusChange?: (status: Statuses) => void;
  onClose?: () => void;
};

export const PaymentSelector: React.VFC<PaymentSelectorProps> = ({
  initialStatus = "SELECT_PAYMENT_TYPE",
  onStatusChange,
  onClose,
}) => {
  const { setPaymentMethod, setCardToken, onlinePaymentsEnabled } =
    useCheckout();
  const { customer, openIfoodAuthentication } = useAuthentication();

  const {
    paymentMethods,
    paymentMethodsStatus,
    fetchPaymentMethods,
    invalidatePaymentMethodsCache,
  } = usePaymentMethods();

  const [paymentName, setPaymentName] = useState("");
  const [status, setStatus] = useState<Statuses>(initialStatus);
  const [selectedCardToken, setSelectedCardToken] =
    useState<CardTokenResponse | null>();
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState<
    PaymentMethodModel[]
  >([]);

  const hasOfflinePayment = paymentMethods?.hasOfflinePaymentMethod();
  const hasPixPayment =
    onlinePaymentsEnabled.pix && paymentMethods?.hasPixPaymentMethod();
  const hasOnlineCardsPayment =
    onlinePaymentsEnabled.cards &&
    paymentMethods?.hasOnlineCardsPaymentMethod();

  const getInitialStatus = useCallback(() => {
    const offline = hasOfflinePayment;
    const pix = hasPixPayment;
    const online = hasOnlineCardsPayment;
    if (paymentMethodsStatus === "loading") return "LOADING_PAYMENT_METHODS";
    if (paymentMethodsStatus === "error") return "ERROR_PAYMENT_METHODS";
    if (!paymentMethods || (!offline && !pix && !online))
      return "EMPTY_PAYMENT_METHODS";
    return initialStatus;
  }, [
    hasOfflinePayment,
    hasOnlineCardsPayment,
    hasPixPayment,
    initialStatus,
    paymentMethods,
    paymentMethodsStatus,
  ]);

  useEffect(() => {
    const initialStatus = getInitialStatus();
    setStatus(initialStatus);
  }, [getInitialStatus]);

  useEffect(() => {
    if (onStatusChange) {
      onStatusChange(status);
    }
  }, [status]);

  useEffect(() => {
    return () => {
      invalidatePaymentMethodsCache();
    };
  }, [invalidatePaymentMethodsCache]);

  const STATUSES_ON_BACK_FUNCTIONS: Record<Statuses, () => void> = {
    CHOOSE_MONEY_CHANGE: () => setStatus("SELECT_PAYMENT_OFFLINE"),
    CHOOSE_BRAND: () => setStatus("SELECT_PAYMENT_OFFLINE"),
    CONFIRM_CVV: () => setStatus("SELECT_ONLINE_PAYMENT_CARD"),
    SELECT_ONLINE_PAYMENT_CARD: () => setStatus("SELECT_PAYMENT_TYPE"),
    SELECT_PAYMENT_OFFLINE: () => setStatus("SELECT_PAYMENT_TYPE"),
    ADD_ONLINE_PAYMENT_CARD: () => setStatus("SELECT_PAYMENT_TYPE"),
    EMPTY_PAYMENT_METHODS: () => setInitialStatus(),
    LOADING_PAYMENT_METHODS: () => setInitialStatus(),
    ERROR_PAYMENT_METHODS: () => setInitialStatus(),
    SELECT_PAYMENT_TYPE: () => onClose(),
    DELETE_CARD: () => onCloseDeleteCard(),
  };

  function setInitialStatus() {
    if (status === "CONFIRM_CVV") setPaymentMethod(undefined);
    setStatus(getInitialStatus());
  }

  function onCloseDeleteCard() {
    setSelectedCardToken(null);
    setStatus("SELECT_ONLINE_PAYMENT_CARD");
  }

  function handleClickCancelButton() {
    checkoutAboyeur.events.payment.deleteCardCancel();
    onCloseDeleteCard();
  }

  function setPixAsPayment() {
    const pixPayment = paymentMethods?.list.find(
      ({ method }) => method.name === "PIX",
    );
    if (pixPayment) {
      setPaymentMethod(pixPayment);
    }
  }

  function onSelectCardToken(cardToken: CardTokenResponse) {
    const payment = paymentMethods.list.find(
      ({ brand, type, method }) =>
        brand.name === cardToken.brand.name &&
        method.name === cardToken.method.name &&
        type.name === "ONLINE",
    );

    if (!payment) {
      snackbar({
        message: "Cartão não aceito por esse restaurante",
        variant: "error",
      });
      return;
    }

    setCardToken(cardToken, payment);

    if (payment.required_cvv) {
      setStatus("CONFIRM_CVV");
      return;
    }

    setInitialStatus();
  }

  function handleConfirmCVV() {
    setStatus("SELECT_PAYMENT_TYPE");
  }

  function setCookies() {
    if (!customer) return;

    const { accessToken, refreshToken } = customer.authentication;
    const { email } = customer.user;
    setAuthenticationCookies(accessToken, refreshToken, email);
    removeAccessToken(WHITELABEL_ACCOUNT_PREFIX);
    removeRefreshToken(WHITELABEL_ACCOUNT_PREFIX);
  }

  function handleBack() {
    return STATUSES_ON_BACK_FUNCTIONS[status]();
  }

  function handlePixPayment() {
    if (!customer) {
      openIfoodAuthentication("EMAIL", "payment", {
        callback: setPixAsPayment,
      });
      return;
    }

    if (!hasPaymentSecretCookie()) setCookies();
    setPixAsPayment();
  }

  async function handleOnlineCardsPayment() {
    if (!customer || !customer.isWhitelabelUser()) {
      openIfoodAuthentication("EMAIL", "payment", {
        callback: () => setStatus("SELECT_ONLINE_PAYMENT_CARD"),
      });
      return;
    }

    if (!hasPaymentSecretCookie()) setCookies();
    setStatus("SELECT_ONLINE_PAYMENT_CARD");
  }

  function handleSelectPaymentOffline(
    value: PaymentMethodModel[],
    paymentName: string,
  ) {
    if (!value.length) {
      return;
    }

    const isCashPaymentMethod = value.find(
      ({ method }) => method.name === "CASH",
    );

    if (isCashPaymentMethod) {
      setSelectedPaymentMethods(value);
      setStatus("CHOOSE_MONEY_CHANGE");
      return;
    }

    if (value.length > 1) {
      setSelectedPaymentMethods(value);
      setStatus("CHOOSE_BRAND");
      setPaymentName(paymentName);
      return;
    }

    setPaymentMethod(value[0]);
    setPaymentName("");
    setInitialStatus();
  }

  function handleChooseBrand(value: PaymentMethodModel) {
    setPaymentMethod(value);
    setInitialStatus();
  }

  function handleDeleteButtonClick(cardToken: CardTokenResponse) {
    setStatus("DELETE_CARD");
    setSelectedCardToken(cardToken);
  }

  async function handleDeleteCard() {
    try {
      checkoutAboyeur.events.payment.deleteCardClick();
      await Customer.deleteCardToken(selectedCardToken.id);
      onCloseDeleteCard();

      snackbar({
        variant: "success",
        message: "Cartão excluído com sucesso.",
      });
    } catch (error: any) {
      if (error.isAxiosError && error.response.data.code) {
        snackbar({
          variant: "error",
          message: JSON.parse(error.response.data.code).message,
        });
      } else {
        snackbar({
          variant: "error",
          message: "Falha ao excluir cartão, tente novamente mais tarde",
        });
      }
      checkoutAboyeur.events.payment.onError(error);
    }
  }

  return (
    <Flex flexDirection="column" height="100%">
      {status === "LOADING_PAYMENT_METHODS" && <LoadingPaymentMethods />}
      {status === "ERROR_PAYMENT_METHODS" && (
        <ErrorPaymentMethods onTryAgain={fetchPaymentMethods} />
      )}
      {status === "EMPTY_PAYMENT_METHODS" && (
        <Empty onClose={setInitialStatus} />
      )}
      {status === "SELECT_PAYMENT_TYPE" && (
        <SelectPaymentType
          onBack={handleBack}
          onOfflinePayment={() => setStatus("SELECT_PAYMENT_OFFLINE")}
          onPixPayment={handlePixPayment}
          onOnlineCardsPayment={handleOnlineCardsPayment}
          hasOfflinePayment={hasOfflinePayment}
          hasPixPayment={hasPixPayment}
          hasOnlineCardsPayment={hasOnlineCardsPayment}
        />
      )}
      {status === "SELECT_PAYMENT_OFFLINE" && (
        <SelectOfflinePayment
          onBack={handleBack}
          onSelectPaymentMethod={handleSelectPaymentOffline}
        />
      )}
      {status === "CHOOSE_BRAND" && (
        <ChooseBrand
          onBack={handleBack}
          paymentName={paymentName}
          paymentMethods={selectedPaymentMethods}
          onChooseBrand={handleChooseBrand}
        />
      )}
      {status === "CHOOSE_MONEY_CHANGE" && (
        <ChooseMoneyChange
          onBack={handleBack}
          selectedPaymentMethods={selectedPaymentMethods}
          onClose={setInitialStatus}
        />
      )}
      {status === "ADD_ONLINE_PAYMENT_CARD" && (
        <AddOnlinePaymentCard
          onBack={handleBack}
          onAddCard={() => setStatus("SELECT_ONLINE_PAYMENT_CARD")}
        />
      )}
      {status === "SELECT_ONLINE_PAYMENT_CARD" && (
        <SelectOnlinePaymentCard
          onBack={handleBack}
          onAddCard={() => setStatus("ADD_ONLINE_PAYMENT_CARD")}
          onEmptyList={() => setStatus("ADD_ONLINE_PAYMENT_CARD")}
          onSelectCard={onSelectCardToken}
          onDeleteButtonClick={handleDeleteButtonClick}
        />
      )}
      {status === "CONFIRM_CVV" && (
        <ConfirmCVV onBack={handleBack} onConfirm={handleConfirmCVV} />
      )}
      {status === "DELETE_CARD" && (
        <DeleteCard
          onBack={handleBack}
          onDeleteCard={handleDeleteCard}
          lastFourDigits={selectedCardToken?.last_four_digits}
          onClickCancelButton={handleClickCancelButton}
        />
      )}
    </Flex>
  );
};
